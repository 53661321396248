import { Component } from 'react'
import { OperationStart } from 'stylewhere/components'
import { TransferToZoneExtensions } from 'stylewhere/extensions'

export default class ConveyorStart extends Component {
  render() {
    return (
      <OperationStart submitPath="/transfer-to-zone/conveyor/:opCode/reading" extensions={TransferToZoneExtensions} />
    )
  }
}
