import React, { Component } from 'react'
import { DecodedItem, Inventories, Inventory, InventoryCounters, Items, TmrItem } from 'stylewhere/api'
import {
  AntennaButton,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
  InventoryDetailCounters,
} from 'stylewhere/components'
import { InventoryExtensions } from 'stylewhere/extensions'
import {
  OperationReadingProps,
  OperationReadingState,
  RemoteOperation,
  RfidReader,
  Router,
  OperationReadingProvider,
} from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { InventoryOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { filterIgnoredAndErrorItems, showToast, showToastError, isModalError } from 'stylewhere/shared/utils'

interface State extends OperationReadingState {
  inventory?: Inventory
  externalNumberRefresh: number
}

interface Props extends OperationReadingProps {
  id: string
}

export default class InventoryRead extends Component<Props, State> {
  state: State = {
    items: [],
    loading: false,
    formData: undefined,
    externalNumberRefresh: 0,
  }
  isModal = false
  antennaRef
  locationState = Router.getLocationState<OperationReadingState>(this.props)
  opCode = Router.getMatchParams(this.props).opCode
  inventoryId = Router.getMatchParams(this.props).id
  operation = RemoteOperation.getOperationConfig<InventoryOperationConfig>(this.opCode)

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode, true)

    const inventory = await Inventories.get<Inventory>(this.inventoryId!)
    this.setState({ inventory })
  }

  getDecodeRequest = () => {
    return {
      url: Inventories.batchValidateEndpoint(),
      payload: {
        operationId: this.operation.id,
      },
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  goBack = () => {
    Router.navigate('/inventory/:opCode', { opCode: this.operation.code })
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const items = await OperationReadingProvider.processDecodedItems(
      this.operation,
      itemMapFromReader,
      this.state.items,
      {},
      InventoryExtensions
    )
    this.setState({ items })
  }

  showConfirmButton() {
    const { items } = this.state
    if (items.filter(filterIgnoredAndErrorItems).length > 0)
      return <Button title={__(T.misc.send_reads)} onClick={this.onConfirm} />
    return null
  }

  onConfirm = async () => {
    const { items, externalNumberRefresh } = this.state
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      await Inventories.updateItems(this.state.inventory!.id, {
        itemIdentifierCodes: items.filter(filterIgnoredAndErrorItems).map((i) => i.identifierCode ?? ''),
      })
      showToast({ status: 'success', description: __(T.misc.items_add_inventory_success) })
      RfidReader.clear()
      this.setState({ items: [], externalNumberRefresh: externalNumberRefresh + 1 })
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [] })
  }

  render() {
    const { loading, items, inventory, externalNumberRefresh } = this.state
    return (
      <Page
        headerRight={
          <InventoryDetailCounters
            inventoryId={this.inventoryId!}
            operationId={this.operation.id}
            operationInstanceId={inventory ? inventory.id : ''}
            externalNumberRefresh={externalNumberRefresh}
            timeToRefresh={this.operation.refreshCounterTimeout}
          />
        }
        onBackPress={this.goBack}
        title={this.operation.description}
        loading={loading}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.filter(filterIgnoredAndErrorItems).length} />
            <AntennaButton
              ref={this.antennaRef}
              decodeRequest={this.getDecodeRequest()}
              onItemDecoded={this.onDecodedItemCallback}
              onClear={this.onClear}
              hideClear={items.length === 0}
            />
            <Spacer />
            <OperationReadingCounters operation={this.operation} items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList extension={InventoryExtensions} items={items} operation={this.operation} />
        </Page.Content>
      </Page>
    )
  }
}
