import { Component } from 'react'
import { T, __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Box, AntennaButton, Input, Icons } from 'stylewhere/components'

interface Props {
  onChange: (txt) => void
  onClear: () => void
  umgResetCounter: number
  umgVerified: boolean
  umgVerifying: boolean
  inputLabel?: string
  addUmgInput: boolean
}

export class CountTBoxHeader extends Component<Props> {
  render() {
    const { addUmgInput, umgResetCounter, umgVerified, umgVerifying, onChange, onClear, inputLabel } = this.props
    return (
      <Container row vcenter>
        {addUmgInput && (
          <Input
            label={inputLabel || __(T.misc.parcel)}
            onChange={(txt) => onChange(txt)}
            customIcon={<Icons.Barcode width={40} height={40} />}
            showLoading={umgVerifying}
            resetCounter={umgResetCounter}
            disabled={umgVerified}
          />
        )}
        <Box ml={addUmgInput ? 25 : 0} width={300}>
          <AntennaButton
            disabled={addUmgInput && !umgVerified}
            showPendingTags={false}
            onClear={() => onClear()}
            hideClear
          />
        </Box>
      </Container>
    )
  }
}

const Container = styled(Box)`
  background: #fff;
  padding: 15px 15px;
  border-bottom: 1px solid #f2f2f2;
`
