import { Component } from 'react'
import { Box, Button, ItemsListModal } from 'stylewhere/components'
import { TmrItem } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  items?: TmrItem[]
  onItemDeleteCallback?: (item: TmrItem) => void
}

interface State {
  visibleItemsModal: boolean
  filteredItems?: TmrItem[]
  modalTitle?: string
  modalType?: 'ERROR' | 'WARNING' | 'IGNORE'
}

export class ItemsStatusesRow extends Component<Props, State> {
  state: State = {
    visibleItemsModal: false,
  }

  openItemsModal = (type: 'ERROR' | 'WARNING' | 'IGNORE') => {
    const filteredItems = this.props.items?.filter((item) => item.__processedStatuses?.includes(type))
    this.setState({ visibleItemsModal: true, filteredItems, modalTitle: this.getModalTitle(type), modalType: type })
  }
  closeItemsModal = () => this.setState({ visibleItemsModal: false, filteredItems: undefined })

  getModalTitle = (type: 'ERROR' | 'WARNING' | 'IGNORE') => {
    switch (type) {
      case 'IGNORE':
        return __(T.fields.ignored_items)
      case 'WARNING':
        return __(T.fields.warning_items)
      case 'ERROR':
        return __(T.fields.error_items)
      default:
        return undefined
    }
  }

  onDeleteRowPress = (item: TmrItem) => {
    const { onItemDeleteCallback, items } = this.props
    const { modalType } = this.state
    const filteredItems = items?.filter((itm) => itm.__processedStatuses?.includes(modalType!))
    if (onItemDeleteCallback && (!filteredItems || filteredItems.filter((itm) => itm.id !== item.id).length === 0))
      this.setState({ visibleItemsModal: false })
    onItemDeleteCallback && onItemDeleteCallback(item)
  }

  render() {
    const { items } = this.props
    const { filteredItems, visibleItemsModal, modalTitle } = this.state

    if (!items || items.length === 0) return null

    const errorCount = items.filter((item) => item.__processedStatuses?.includes('ERROR')).length
    const warningCount = items.filter((item) => item.__processedStatuses?.includes('WARNING')).length
    const ignoreCount = items.filter((item) => item.__processedStatuses?.includes('IGNORE')).length

    if (errorCount + warningCount + ignoreCount === 0) return null

    const ignoreButtonMargin = warningCount + errorCount > 0 ? 10 : 0
    const warningButtonMargin = errorCount > 0 ? 10 : 0

    return (
      <Box row center style={{ justifyContent: 'space-around', marginBottom: 20 }}>
        {ignoreCount > 0 && (
          <Button
            title={`${__(T.fields.ignored_items).toUpperCase()} (${ignoreCount})`}
            variant="ignore"
            size="medium"
            style={{ marginRight: ignoreButtonMargin, flex: 1 }}
            onClick={() => this.openItemsModal('IGNORE')}
          />
        )}
        {warningCount > 0 && (
          <Button
            title={`${__(T.fields.warning_items).toUpperCase()} (${warningCount})`}
            variant="warning"
            size="medium"
            style={{ color: '#D6A52A', flex: 1, marginRight: warningButtonMargin }}
            onClick={() => this.openItemsModal('WARNING')}
          />
        )}
        {errorCount > 0 && (
          <Button
            title={`${__(T.fields.error_items).toUpperCase()} (${errorCount})`}
            variant="error"
            size="medium"
            style={{ color: '#EF4141', flex: 1 }}
            onClick={() => this.openItemsModal('ERROR')}
          />
        )}
        {visibleItemsModal && filteredItems && (
          <ItemsListModal
            items={filteredItems}
            visible={visibleItemsModal}
            onClose={this.closeItemsModal}
            title={modalTitle}
            onDeleteRowCallback={this.onDeleteRowPress}
          />
        )}
      </Box>
    )
  }
}
