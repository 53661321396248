import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Button } from 'stylewhere/components'
import { T, __, __UP } from 'stylewhere/i18n'

interface Props {
  onChange: (type) => void
  selected: number
}

export class ToggleTabLabel extends Component<Props> {
  render() {
    const { onChange, selected } = this.props
    return (
      <Container row vcenter>
        <Button
          style={{
            height: 51,
            boxShadow: 'unset',
            backgroundColor: selected === 1 ? '#4f4f4f' : '#FFF',
            color: selected === 1 ? '#fff' : '#4f4f4f',
          }}
          radius={100}
          size={'medium'}
          variant={'default'}
          onClick={() => onChange(1)}
        >
          {__UP(T.misc.product)}
        </Button>
        <Button
          style={{
            height: 51,
            boxShadow: 'unset',
            backgroundColor: selected === 2 ? '#4f4f4f' : '#FFF',
            color: selected === 2 ? '#fff' : '#4f4f4f',
          }}
          ml={4}
          radius={100}
          size={'medium'}
          variant={selected == 2 ? 'secondary' : 'default'}
          onClick={() => onChange(2)}
        >
          {__UP(T.misc.parcel)}
        </Button>
      </Container>
    )
  }
}

const Container = styled(Box)`
  height: 65px;
  border: 2px solid #e0e0e0;
  border-radius: 100px;
  padding: 4px;
  margin-right: 20px;
`
