import { Component } from 'react'
import { Box } from 'stylewhere/components'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props {
  active: boolean
  ml?: number
}

export class Stoplight extends Component<Props> {
  render() {
    const { active, ml } = this.props
    const title = active ? __(T.misc.ready) : __(T.misc.offline)
    return (
      <Container row style={{ marginLeft: ml || 0 }}>
        <Status active={active} />
        <StatusText>{title}</StatusText>
      </Container>
    )
  }
}

const Container = styled(Box)`
  background: #dcdcdc;
  padding: 10px 15px 10px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`

const Status = styled.div<{ active }>`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${({ active }) => (active ? '#75EBA8' : '#EB5757')};
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease;
`

const StatusText = styled.label`
  font-weight: normal;
  font-size: 16pt;
`
