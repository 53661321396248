import { Component } from 'react'
import { Router, ShippingOperationConfig, RemoteOperation, Sounds } from 'stylewhere/shared'
import { askUserConfirmation, isModalError, showToast, showToastError } from 'stylewhere/utils'
import {
  Shippings,
  ShippingParcel,
  ShippingParcelChecklist,
  Checklist,
  Items,
  TmrItem,
  AsnStatus,
  ParcelStatus,
  Asn,
  ParcelDetailResponse,
  Products,
  TmrProduct,
  DecodedItem,
} from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { RouteComponentProps } from 'react-router'
import { T, __ } from 'stylewhere/i18n'
import { Box, Icons, Input, List, Page, ParcelRow, ParcelRightHeader, Button } from 'stylewhere/components'
import { List as VirtualizedList } from 'react-virtualized'

export interface ShippingParcelStartParams {
  opCode: string
  asnId: string
}

type ParcelPagedList = { [pageIndex: number]: ShippingParcel[] }

export interface ShippingParcelStartState {
  // showForm: boolean
  search?: string
  searching?: string
  parcels?: ParcelPagedList
  parcelsDetails: {
    [parcelId: ShippingParcel['id']]: DecodedItem[]
  }
  listSelectedParcel?: ShippingParcel
  loading: boolean
  rowLoading: {
    [index: number]: boolean
  }
  rowExpanded: {
    [index: number]: boolean
  }
  error: boolean
  currentListPage: number
  totalPages: number
  totalElements: number
  neverLoaded: boolean
  asn?: Asn
}

const LIST_PAGE_SIZE = 100

export const getParcelReadingStatuses = (operationConfig: ShippingOperationConfig): ParcelStatus[] => {
  if (operationConfig.type === 'INBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_INBOUND', 'INBOUND_ERROR']
  if (operationConfig.type === 'OUTBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_OUTBOUND', 'INBOUND_ERROR']
  return []
}

export const getShipmentAddParcelStatuses = (operationConfig: ShippingOperationConfig): AsnStatus[] => {
  if (operationConfig.type === 'INBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_INBOUND', 'INBOUND_ERROR']
  if (operationConfig.type === 'OUTBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_OUTBOUND', 'INBOUND_ERROR']
  return []
}

export default class ShippingParcelStart<
  P extends RouteComponentProps<ShippingParcelStartParams>,
  S extends ShippingParcelStartState
> extends Component<P, S> {
  asnId: string
  opCode: string
  isModal: boolean
  operation: ShippingOperationConfig
  backPath: Routes = '/shipping/:opCode' // FIXME
  readingPath: Routes = '/shipping/:opCode/reading'
  selfPath: Routes = '/shipping/:opCode/asn/:asnId'
  addParcelPath: Routes = '/shipping/:opCode/asn/:asnId/add'
  selectParcelPath: Routes = '/shipping/:opCode/asn/:asnId/add/:parcelId'
  state: S = {
    loading: false,
    rowLoading: {},
    rowExpanded: {},
    parcelsDetails: {},
    currentListPage: 0,
    totalElements: 0,
    totalPages: 0,
    error: false,
    search: undefined,
    neverLoaded: true,
  } as any

  constructor(props: any) {
    super(props)
    const matchParams: ShippingParcelStartParams = Router.getMatchParams(this.props)
    this.asnId = matchParams.asnId
    this.opCode = matchParams.opCode
    this.isModal = false
    this.operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.opCode)
  }

  canShowAddParcel() {
    const { asn } = this.state
    return (
      asn &&
      asn.status &&
      getShipmentAddParcelStatuses(this.operation).includes(asn.status) &&
      this.operation.canCreateParcels
    )
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    await this.loadAsn(this.asnId)
    await this.updateList()
  }

  async loadAsn(id: string) {
    try {
      // FIXME: save asn on route state and get from there when available
      const asn = await Shippings.getAsnExtended(id)
      this.setState({ asn })
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  isParcelNotCompleted(parcel: ShippingParcel): boolean {
    return getParcelReadingStatuses(this.operation).includes(parcel.status)
  }

  onRowClick = async (parcel: ShippingParcel, index: number, listRef: VirtualizedList) => {
    if (this.isParcelNotCompleted(parcel)) {
      Router.navigate(
        this.selectParcelPath,
        { opCode: this.operation.code, asnId: this.asnId, parcelId: parcel.id },
        {
          state: {
            asn: this.state.asn,
            parcel,
          },
        }
      )
    } else {
      try {
        if (!this.state.rowExpanded[index]) {
          // now the row it's closed, i'm going to load data and expand

          this.setState({
            rowLoading: {
              ...this.state.rowLoading,
              [index]: true,
            },
          })
          // code from handheld
          const parcelDetail: ParcelDetailResponse = await Shippings.getParcelDetail(this.operation, parcel.code)
          const itemIds = parcelDetail.details.map((det) => det.parcelEntry.itemId)
          const items: TmrItem[] =
            itemIds.length > 0 ? (await Items.search<TmrItem>({ ids: itemIds, size: 100 })).content : []
          let products: ShippingParcel['products'] = {}
          if (!parcel.products && parcel.checklist) {
            if (parcel.checklist.type === 'CHECKLIST_BY_PRODUCT') {
              products = (
                await Products.search<TmrProduct>({
                  likeCodes: parcel.checklist?.checklistProducts?.map((product) => product.productCode),
                })
              ).content.reduce((acc, curr) => {
                const upc = curr.code
                acc[upc] = curr
                return acc
              }, {})
            } else if (parcel.checklist.type === 'CHECKLIST_BY_ITEM') {
              products = (
                await Products.search<TmrProduct>({
                  likeCodes: parcel.checklist?.checklistItems?.map((itm) => itm.productCode),
                })
              ).content.reduce((acc, curr) => {
                const upc = curr.code
                acc[upc] = curr
                return acc
              }, {})
            }
            parcel.products = products
          }

          this.setState(
            {
              rowLoading: {
                ...this.state.rowLoading,
                [index]: false,
              },
              rowExpanded: {
                ...this.state.rowExpanded,
                [index]: true,
              },
              parcelsDetails: {
                ...this.state.parcelsDetails,
                [parcel.id]: items.map((item) => {
                  return {
                    item,
                    epc: item.id, // FIXME: was item.epc on HH,
                    statuses: item.statuses || [],
                    readIdentifiers: [
                      {
                        code: item.id, // FIXME: was item.epc on HH,
                        type: 'UHFTag',
                        status: item.status,
                      },
                    ],
                  } as DecodedItem
                }),
              },
            },
            () => {
              // now update list
              listRef.recomputeRowHeights()
              listRef.forceUpdate()
            }
          )
        } else {
          this.setState(
            {
              rowLoading: {
                ...this.state.rowLoading,
                [index]: false,
              },
              rowExpanded: {
                ...this.state.rowExpanded,
                [index]: false,
              },
            },
            () => {
              // now update list
              listRef.recomputeRowHeights()
              listRef.forceUpdate()
            }
          )
        }
      } catch (err) {
        showToastError(err, 'Start Parcel Error', this.isModal)
      }
    }
  }

  onNewParcel = () => {
    Router.navigate(
      this.addParcelPath,
      { opCode: this.operation.code, asnId: this.asnId },
      {
        state: {
          asn: this.state.asn,
        },
      }
    )
  }

  getOnConfirmShipmentCB = (askConfirmation: boolean) => async () => {
    const { asn } = this.state
    if (!asn) {
      showToastError(__(T.misc.shipment), __(T.error.error), this.isModal)
      return
    }

    try {
      if (askConfirmation) {
        const ok = await askUserConfirmation(
          __(T.confirm.confirm_close_asn_title),
          __(T.confirm.confirm_close_asn_text)
        )

        if (!ok) return
      }

      await Shippings.confirmAsn(this.operation, asn.code, {
        transactionDate: new Date().toISOString(),
      })

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.operation_success),
        status: 'success',
      })
      Sounds.success()

      Router.navigate(this.backPath, { opCode: this.operation.code })
    } catch (error) {
      console.error(error)
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  updateList = async (search?: string) => {
    const { currentListPage, loading, search: lastSearch, searching, neverLoaded, asn } = this.state
    try {
      const skip = !asn || (loading && searching === search) || (search === lastSearch && !neverLoaded)
      if (!skip) {
        this.setState({ loading: true, error: false, searching: search })

        const parcelsResponse = await Shippings.getParcelListByAsn(
          this.operation,
          asn.code,
          LIST_PAGE_SIZE,
          currentListPage,
          search,
          this.operation.reverseParcelOrder ? 'asc' : 'desc'
        )
        const { totalPages, totalElements } = parcelsResponse
        const parcels: ShippingParcel[] = parcelsResponse.content
        const missingChecklistIds: string[] = []
        parcels.forEach((parcel) => {
          if (!parcel.checklist && parcel.checklistId) {
            missingChecklistIds.push(parcel.checklistId)
          }
        })
        const checklists: ShippingParcelChecklist[] =
          missingChecklistIds.length > 0
            ? (await Checklist.search<ShippingParcelChecklist>({ ids: missingChecklistIds, size: 100 })).content
            : []
        checklists.forEach((checklist) => {
          const relatedParcel = parcels.find((parcel) => parcel.checklistId === checklist.id)
          if (!checklist.type) {
            if (checklist.itemIds) checklist.type = 'CHECKLIST_BY_ITEM'
            else if (checklist.products) checklist.type = 'CHECKLIST_BY_PRODUCT'
          }
          if (relatedParcel) relatedParcel.checklist = checklist
        })
        checklists.forEach((checklist) => {
          if (checklist.type === 'CHECKLIST_BY_PRODUCT' && !checklist.checklistProducts && checklist.products) {
            checklist.checklistProducts = checklist.products
          }
        })
        const checklistItemInfo: string[] = []
        checklists.forEach((checklist) => {
          if (
            checklist.type === 'CHECKLIST_BY_ITEM' &&
            !checklist.checklistItems &&
            !checklist.items &&
            checklist.itemIds
          ) {
            checklistItemInfo.push(...checklist.itemIds)
          } else if (checklist.type === 'CHECKLIST_BY_ITEM' && !checklist.checklistItems && checklist.items) {
            checklist.checklistItems = checklist.items.map((item) => ({
              itemId: item.id,
              productCode: item.product!.code,
              quantity: 1,
              detected: 0,
            }))
          }
        })
        const checklistItemInfos: TmrItem[] =
          checklistItemInfo.length > 0
            ? (await Items.search<TmrItem>({ ids: checklistItemInfo, size: 100 })).content
            : []

        checklists.forEach((checklist) => {
          if (checklist.type === 'CHECKLIST_BY_ITEM' && !checklist.checklistItems && checklist.itemIds) {
            const relatedItems = checklistItemInfos.filter((cii) => checklist.itemIds?.includes(cii.id))
            checklist.checklistItems = relatedItems.map((item) => ({
              itemId: item.id,
              productCode: item.product!.code,
              quantity: 1,
              detected: 0,
            }))
          }
        })
        this.setState({
          neverLoaded: false,
          search,
          searching: undefined,
          loading: false,
          parcels: { ...this.state.parcels, [currentListPage]: parcels },
          totalPages,
          totalElements,
        })
      }
    } catch (error) {
      console.error(error)
      this.setState({ loading: false, error: true })
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  parcelRowRender = (
    parcel: ShippingParcel,
    index: number,
    listRef: VirtualizedList,
    expanded: boolean,
    details: DecodedItem[],
    loading: boolean
  ) => {
    return (
      <ParcelRow
        expanded={expanded}
        expandable={!this.isParcelNotCompleted(parcel)}
        loading={loading}
        details={details}
        parcel={parcel}
        operation={this.operation}
        onClick={() => this.onRowClick(parcel, index, listRef)}
      />
    )
  }

  render() {
    const { parcels, loading, asn, rowLoading, rowExpanded, parcelsDetails } = this.state

    const flatParcels = Object.values(parcels ?? {}).flat()

    const canShowConfirmShipment = flatParcels.length > 0

    const totals = flatParcels.reduce(
      (t, currParcel) => {
        const checklist = currParcel.checklist

        return {
          totalExpecteds:
            t.totalExpecteds +
            (checklist
              ? (checklist.checklistItems?.length ?? 0) +
                (checklist.checklistProducts?.reduce((acc, curr) => {
                  return acc + curr.quantity
                }, 0) ?? 0)
              : 0),

          totalReadings: t.totalReadings + (currParcel.parcelEntryQuantity ?? 0),
        }
      },
      { totalReadings: 0, totalExpecteds: 0 }
    )

    const totalReadings = totals.totalReadings ?? 0
    const totalExpecteds = totals.totalExpecteds ?? 0

    const counterType =
      totalExpecteds !== 0
        ? totalReadings === totalExpecteds
          ? 'valid'
          : totalReadings > totalExpecteds
          ? 'warning'
          : 'default'
        : 'default'

    // for pagination
    // const noMoreItems = currentListPage === totalPages - 1

    let askCloseConfirm = false
    if (canShowConfirmShipment) {
      askCloseConfirm = flatParcels.some((parcel) => !parcel.parcelEntryQuantity)
    }

    return (
      <Page
        headerRight={
          <ParcelRightHeader
            shipmentCode={asn?.code}
            onConfirmShpment={this.getOnConfirmShipmentCB(askCloseConfirm)}
            canShowConfirmShipment={canShowConfirmShipment}
            totalExpecteds={totalExpecteds}
            totalReadings={totalReadings}
          />
        }
        title={this.operation.description}
        onBackPress={() => Router.navigate(this.backPath, { opCode: this.opCode })}
      >
        <Box bg={'#FFF'} flex>
          <Page.Content notBoxed>
            <Box p={5} pb={0} mb={40} row justify={'space-between'}>
              <Input
                onChange={this.updateList}
                placeholder={`${__(T.misc.parcel)}`}
                image={<Icons.Barcode />}
                style={{ width: 325 }}
                // autoFocus - blur bug
                debounce={300}
              />
              {this.canShowAddParcel() && (
                <Button title={`${__(T.misc.new_parcel)}`} disabled={false} onClick={this.onNewParcel} />
              )}
            </Box>
            <List
              title={`${__(T.misc.parcels)} (${flatParcels?.length ?? 0})`}
              loading={loading}
              data={flatParcels}
              rowRender={(parcel: ShippingParcel, index: number, listRef: VirtualizedList) => {
                return this.parcelRowRender(
                  parcel,
                  index,
                  listRef,
                  rowExpanded[index] || false,
                  parcelsDetails[parcel.id] || {},
                  rowLoading[index] || false
                )
              }}
            />
          </Page.Content>
        </Box>
      </Page>
    )
  }
}
