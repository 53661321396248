import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Select, Text, Icons } from 'stylewhere/components'

interface Props {
  options: any[]
  selected: string
  onChange: (value: any) => void
  title: string
  placeholder: string
  secondaryLabel?: string
}

interface State {
  isModalOpen: boolean
}

export class SelectableHeader extends Component<Props, State> {
  state: State = {
    isModalOpen: false,
  }

  private modalRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  getSelectedOption = () => {
    const { options, selected } = this.props
    const obj = options.find((el) => el.id === selected)
    return obj?.description || '---'
  }

  handleEditClick = () => {
    this.setState({ isModalOpen: true })
  }

  handleSelectChange = (selectedItems: any) => {
    this.props.onChange(selectedItems)
    this.setState({ isModalOpen: false })
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false })
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target as Node)) {
      this.handleCloseModal()
    }
  }

  render() {
    const { options, selected, title, placeholder, secondaryLabel } = this.props
    const { isModalOpen } = this.state

    const selectConfig = secondaryLabel
      ? {
          value: 'id',
          label: 'description',
          secondaryLabel: secondaryLabel,
          concatSecondaryLabel: true,
        }
      : {
          value: 'id',
          label: 'description',
        }

    return (
      <Container>
        <HeaderInfo onClick={options.length > 1 ? this.handleEditClick : undefined}>
          <Text fontSize={16} bold>
            {title}
          </Text>
          <SelectedOptionWrapper>
            <Text fontSize={18}>{this.getSelectedOption()}</Text>
            {options.length > 1 && (
              <EditIconWrapper>
                <EditIcon>
                  <Icons.Edit />
                </EditIcon>
              </EditIconWrapper>
            )}
          </SelectedOptionWrapper>
        </HeaderInfo>
        {isModalOpen && (
          <SelectWrapper>
            <SelectContainer ref={this.modalRef}>
              <Select
                onSelect={this.handleSelectChange}
                options={options}
                config={selectConfig}
                value={selected}
                multiple={false}
                useButton={false}
                transparent={true}
                placeholder={placeholder}
                isModalOpen={isModalOpen}
              />
            </SelectContainer>
          </SelectWrapper>
        )}
      </Container>
    )
  }
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const SelectedOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`

const EditIconWrapper = styled.div`
  margin-left: 10px;
`

const EditIcon = styled.div`
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
`

const SelectWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

const SelectContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
`
