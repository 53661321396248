import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Box, Text, Image, Icons, Button } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { showToast } from 'stylewhere/utils'
import { AppStore, Router, OperationConfig, RemoteOperation } from 'stylewhere/shared'

type ButtonProps = {
  code: string
  operation: string
  routeParams?: any
  icon?: React.ReactNode
  text?: string
  displayPriority: number
  disableWorkstationCheck: boolean
}

interface Props {
  refreshCounter: number
}

export const DashboardOperationsButtons: React.FC<Props> = ({ refreshCounter }: Props) => {
  const [enabled, setEnabled] = useState<OperationConfig[]>()
  const [counter, setCounter] = useState<number>(0)

  useEffect(() => {
    if (counter < refreshCounter) {
      setEnabled(undefined)
      setCounter(refreshCounter)

      const fetch = async () => {
        await RemoteOperation.load()
        setEnabled(RemoteOperation.operations.filter((op) => op.enabled))
      }
      fetch()
    }
  }, [refreshCounter, counter, setCounter])

  useEffect(() => {
    const fetch = async () => {
      await RemoteOperation.load()
      setEnabled(RemoteOperation.operations.filter((op) => op.enabled))
    }
    fetch()
  }, [])

  if (enabled === undefined) {
    return (
      <DashView>
        {[...Array(6)].map((e, i) => (
          <FakeButton key={i} />
        ))}
      </DashView>
    )
  }

  const tagInfo: ButtonProps[] = enabled
    .filter((op) => op.type === 'TAG_INFO')
    .map((op) => ({
      code: op.code,
      operation: '/tag-info/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.TagInfo />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const transferToZone: ButtonProps[] = enabled
    .filter((op) => op.type === 'TRANSFER_TO_ZONE')
    .map((op) => ({
      code: op.code,
      operation: '/transfer-to-zone/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.TransferToZone />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const simpleItemList: ButtonProps[] = enabled
    .filter((op) => op.type === 'SIMPLE_ITEM_LIST')
    .map((op) => ({
      code: op.code,
      operation: '/simple-item-list/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.SimpleItemList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const simpleParcelList: ButtonProps[] = enabled
    .filter((op) => op.type === 'SIMPLE_PARCEL_LIST')
    .map((op) => ({
      code: op.code,
      operation: '/simple-parcel-list/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.SimpleParcelList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const shippingItemList: ButtonProps[] = enabled
    .filter((op) => op.type === 'INBOUND' || op.type === 'OUTBOUND' || op.type === 'PACKING')
    .map((op) => {
      let icon
      if (op.type === 'INBOUND') icon = <Icons.Inbound />
      else if (op.type === 'OUTBOUND') icon = <Icons.Outbound />
      else if (op.type === 'PACKING') icon = <Icons.Package style={{ height: 110 }} />
      return {
        code: op.code,
        operation: '/shipping/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
        icon: icon,
        routeParams: { opCode: op.code },
        text: `${op.description}`,
        displayPriority: op.displayPriority ?? 0,
        disableWorkstationCheck: op.disableWorkstationCheck,
      }
    })

  const sorting: ButtonProps[] = enabled
    .filter((op) => op.type === 'SORTING')
    .map((op) => ({
      code: op.code,
      operation: '/sorting/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.SimpleParcelList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const encoding: ButtonProps[] = enabled
    .filter((op) => op.type === 'ENCODING')
    .map((op) => ({
      code: op.code,
      operation: '/encoding/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.Encoding />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const inventory: ButtonProps[] = enabled
    .filter((op) => op.type === 'INVENTORY')
    .map((op) => ({
      code: op.code,
      operation: '/inventory/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.TransferToZone />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const simpleItemSessionList: ButtonProps[] = enabled
    .filter((op) => op.type === 'SIMPLE_ITEM_SESSION_LIST')
    .map((op) => ({
      code: op.code,
      operation: '/simple-item-session-list/' + (op.templatePath !== '' ? op.templatePath + '/' : '') + ':opCode',
      icon: <Icons.Inbound />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
      disableWorkstationCheck: op.disableWorkstationCheck,
    }))

  const buttons: ButtonProps[] = [
    ...tagInfo,
    ...transferToZone,
    ...simpleItemList,
    ...simpleParcelList,
    ...shippingItemList,
    ...sorting,
    ...encoding,
    ...inventory,
    ...simpleItemSessionList,
  ].sort((a, b) => {
    if (a.displayPriority < b.displayPriority) {
      return -1
    }
    if (a.displayPriority > b.displayPriority) {
      return 1
    }
    return a.code < b.code ? -1 : 1
  })

  const onClick = (route, routeParams, disableWorkstationCheck) => {
    if (!AppStore.defaultWorkstation && !disableWorkstationCheck) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      if (!AppStore.emulation) return
    }
    route && Router.navigate(route, routeParams)
  }

  const refresh = () => {
    window.location.reload()
  }

  return (
    <>
      {buttons.length === 0 && (
        <Box flex center mb={50} height={'calc(100% - 50px)'}>
          <Text fontSize={20}>{__(T.messages.no_operations_found)}</Text>
          <Button style={{ marginTop: 20 }} variant="primary" type="button" onClick={() => refresh()}>
            {__(T.misc.refresh)}
          </Button>
        </Box>
      )}
      {buttons.length > 0 && (
        <DashView>
          {buttons.map(({ operation: route, routeParams, icon, text, code, disableWorkstationCheck }) => (
            <ButtonDashboard key={code} onClick={() => onClick(route, routeParams, disableWorkstationCheck)}>
              {icon && typeof icon !== 'string' && icon}
              {icon && typeof icon === 'string' && <Icon alt={text} src={icon} />}
              {text}
            </ButtonDashboard>
          ))}
          {buttons.length % 2 === 1 && <FakeButton />}
        </DashView>
      )}
    </>
  )
}

const DashView = styled(Box)`
  margin-right: 20px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 30px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const ButtonDashboard = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  min-width: 276px;
  flex: 1;
  height: 202px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 10px;
  margin: 0 30px 30px 0px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const FakeButton = styled.div`
  min-width: 276px;
  flex: 1;
  height: 202px;
  background: #ffffff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 30px 30px 0px;
  padding: 0 20px;
`

const Icon = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`
