import { api, TmrWorkstation, responseErrorCheck, TmrBaseResource } from 'stylewhere/api'

export class Workstations extends TmrBaseResource {
  static endpoint = 'api/v1/workstations' //edge/

  static searchByPlaceId(placeIds) {
    return this.search<TmrWorkstation>({
      placeIds,
      size: 500,
    })
  }
}
