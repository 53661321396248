import { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'
import { SortingGroupRead } from 'stylewhere/api'
import { getSortingLabel } from 'stylewhere/shared/utils'

interface Props {
  currentSortingGroupRead?: SortingGroupRead
}

export class SortingGroupLabel extends Component<Props> {
  render() {
    return (
      <ProductTitle flex={1.5} justify="center" ok pl={50} pr={25}>
        {getSortingLabel(this.props.currentSortingGroupRead)}
      </ProductTitle>
    )
  }
}

const ProductTitle = styled(Box)<{ ok: boolean }>`
  font-weight: bold;
  font-size: ${({ ok }) => (ok ? 'clamp(36px, 5vw, 100px)' : '36px')};
  color: #333333;
  border-right: ${({ ok }) => (ok ? '1px' : '0')} solid #5dd691;
`
