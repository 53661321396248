/* eslint-disable @typescript-eslint/no-unused-vars */
import { DecodedItem } from 'stylewhere/api'
import { OperationConfig } from 'core/shared/RemoteOperation'
import { FormSchema, FormSchemaData, jsonToFormSchema, getFieldValue } from 'stylewhere/shared'
import { showToastError } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/i18n'

export abstract class Extensions {
  static formSchema({ formSchema }: OperationConfig, additionalData?: any, filterKey?: string[]): FormSchema {
    if (formSchema && Object.keys(formSchema).length > 0) {
      let schema = jsonToFormSchema(formSchema)
      if(filterKey && filterKey.length > 0) {
        schema = schema.filter(s => filterKey.includes(s.filterKey ?? ''))
      }
      return schema
    }
    return []
  }

  static async formDataIsValid(formData: FormSchemaData, operation: OperationConfig, schema: FormSchema) {
    let valid = true
    let v = 0
    let regExp, value, regExpTest
    while (v < schema.length && valid) {
      if (schema[v].regExp && schema[v].regExp !== '') {
        regExp = schema[v].regExp
        value = getFieldValue(formData, schema[v])
        if (value) {
          regExpTest = new RegExp(regExp)
          const validation = regExpTest.test(value + '')
          if (!validation) {
            valid = false
            showToastError(
              schema[v].regExpError ?? __(T.error.form_validation_message),
              __(T.error.form_validation),
              false
            )
          }
        }
      }
      v++
    }
    return valid
  }

  static async processItem(
    operation: OperationConfig,
    formData: FormSchemaData,
    item: DecodedItem
  ): Promise<DecodedItem | null> {
    return item
  }

  static processRow<RowType extends { [key: string]: any }>(operation: OperationConfig, row: RowType): RowType | null {
    return row
  }

  static checkItemStatus(operation: OperationConfig, item: DecodedItem): DecodedItem | undefined {
    if (item.statuses?.some((status) => operation.itemStatuses.ignore.includes(status)) || !item.item) return undefined
    if (item.statuses?.some((status) => operation.itemStatuses.ignoreWithReason.includes(status))) return undefined
    if (item.statuses?.some((status) => operation.itemStatuses.warning.includes(status))) item.item.status = 'warning'
    if (item.statuses?.some((status) => operation.itemStatuses.error.includes(status))) item.item.status = 'error'
    return item
  }

  static async beforeConfirm<O extends any[]>(
    operation: OperationConfig,
    data: FormSchemaData,
    // Possono essere una lista di items, di parcels o altro a seconda del tipo di operazione
    objects: O
  ): Promise<boolean> {
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  static async afterConfirm(operation: OperationConfig, data: FormSchemaData, confirmResult: any): Promise<void> {}
}
