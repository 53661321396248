import { Component } from 'react'
import { TmrTag } from 'stylewhere/api'
import { Box, Text, Button, Input, Page, Icons, EncodingReading, FullLoadingLayer } from 'stylewhere/components'
import { Router, OperationReadingProps, RfidReader } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { showToast, showToastError, enabledUtilities } from 'stylewhere/shared/utils'

interface State {
  tag: string
  antennaOn: boolean
  processing: boolean
  tagToWrite: string
  starting: boolean
}

export default class WriteTag extends Component<OperationReadingProps<State>, State> {
  state: State = {
    tag: '',
    antennaOn: false,
    processing: false,
    tagToWrite: '',
    starting: false,
  }

  componentDidMount = async () => {
    if (!enabledUtilities()) {
      Router.navigate('/', {})
    } else {
      this.setState({ starting: true }, this.startReader)
    }
  }

  componentWillUnmount = async () => {
    await this.stopAntenna()
  }

  startReader = async () => {
    try {
      await RfidReader.initialize()
      RfidReader.setAutomaticStop(false)
      RfidReader.setOnTagReadCallback(this.onTagRead)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      await RfidReader.start()
    } catch (error) {
      showToastError((error as any).message ?? __(T.error.rfid_reader_initialization))
    }
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true, starting: false })
  }

  onStopCallback = () => {
    this.setState({ antennaOn: false, starting: false })
  }

  onTagRead = async (tag: TmrTag) => {
    await this.stopAntenna()
    this.setState({ tag: tag.epc || '' })
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start()
    }
  }

  stopAntenna = async () => {
    if (RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.stop()
    }
  }

  onClear = () => {
    const { tag } = this.state
    RfidReader.removeTags([tag])
    this.setState({ tag: '', tagToWrite: '' })
  }

  onChangeWriteToTag = (val) => {
    this.setState({ tagToWrite: val })
  }

  onWriteToTag = () => {
    this.setState({ processing: true }, this.write)
  }

  write = async () => {
    const { tag, tagToWrite } = this.state
    try {
      const ret: any = await RfidReader.writeEpc(tag, tagToWrite)
      this.write_callback(ret.ok ?? false)
    } catch (error) {
      this.setState({ tag: '', processing: false })
      showToastError((error as any).message ?? __(T.error.write_tag))
    }
  }

  write_callback = async (success) => {
    const { tag } = this.state
    RfidReader.removeTags([tag])
    await this.startAntenna()
    this.setState({ tag: '', tagToWrite: '', processing: false })
    if (success) {
      showToast({
        sound: false,
        description: __(T.messages.write_tag),
        status: 'success',
      })
    } else {
      showToastError(__(T.error.write_tag))
    }
  }

  rightHeader = () => {
    const { antennaOn } = this.state
    return (
      <Box row>
        <Box vcenter>
          {!antennaOn ? (
            <Icons.Antenna style={{ height: 35, width: 60, marginLeft: 20 }} />
          ) : (
            <Icons.AntennaOn style={{ height: 35, marginLeft: 20 }} />
          )}
        </Box>
      </Box>
    )
  }

  goBack = () => {
    Router.navigate('/utilities', {})
  }

  render() {
    const { processing, antennaOn, tag, starting } = this.state
    return (
      <Page
        onBackPress={() => this.goBack()}
        headerRight={this.rightHeader()}
        title={__(T.misc.write_tag)}
        enableEmulation
      >
        <Page.Content notBoxed>
          {tag === '' && (
            <EncodingReading
              starting={starting}
              startAntenna={this.startAntenna}
              antennaOn={antennaOn}
              message={
                antennaOn ? __(T.messages.bring_an_item_to_antenna) : __(T.messages.connection_to_station_in_progress)
              }
            />
          )}
          {tag !== '' && (
            <Box borderRadius={15} ph={40} pl={10} pr={10} flex bg="white" center>
              <Box flex center width="100%">
                <Text center fontSize={20} style={{ marginBottom: 20 }}>
                  {__(T.misc.target_epc)}: <b>{tag}</b>
                </Text>
                <Text center fontSize={20} style={{ marginBottom: 10 }}>
                  {__(T.misc.digit_identifier_tag)}
                </Text>
                <Input
                  startFocus
                  defaultValue={''}
                  onChange={this.onChangeWriteToTag}
                  label={__(T.misc.identifier_tag)}
                  customIcon={<Icons.Serial width={40} height={40} />}
                  style={{ width: '100%', maxWidth: 400 }}
                />
                <Box row mt={20} center>
                  <Button style={{ width: 190 }} variant="default" title={__(T.misc.clear)} onClick={this.onClear} />
                  <Button
                    style={{ width: 190 }}
                    ml={20}
                    variant="primary"
                    title={__(T.misc.confirm)}
                    onClick={this.onWriteToTag}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Page.Content>
        {processing && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
      </Page>
    )
  }
}
