/* eslint-disable @typescript-eslint/no-unused-vars */
import { DecodedParcel, ShippingParcel } from 'stylewhere/api'
import { FormSchemaData, SimpleParcelListOperationConfig } from 'stylewhere/shared'
import { Extensions } from './Extensions'

export class SimpleParcelListExtensions extends Extensions {
  static processParcel(
    operation: SimpleParcelListOperationConfig,
    decodedParcel: DecodedParcel,
    parcel: ShippingParcel
  ): ShippingParcel {
    return parcel
  }

  static async beforeConfirm(
    operation: SimpleParcelListOperationConfig,
    data: FormSchemaData,
    parcels: ShippingParcel[]
  ): Promise<boolean> {
    return true
  }
}
