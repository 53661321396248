import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Button, EncodingInboundSapViewItemList } from 'stylewhere/components'
import { T, __, __UP } from 'stylewhere/i18n'
import { SimpleItemSessionList } from 'stylewhere/api'
import { getProductCode, enabledWamGroupedProduct } from 'stylewhere/shared/utils'

interface Props {
  session: any
  listId: string
}

interface State {
  loaded: boolean
  opened: boolean
  items: any[]
}

export class EncodingInboundSapViewSession extends Component<Props, State> {
  state = {
    loaded: false,
    opened: false,
    items: [],
  } as any

  detail = async () => {
    const { session } = this.props
    const { loaded, opened } = this.state
    if (opened) {
      this.setState({ opened: false })
    } else {
      if (!loaded) {
        const products = await this.getItems()
        this.setState({ opened: true, loaded: true, items: products })
      } else {
        this.setState({ opened: true })
      }
    }
  }

  getItems = async () => {
    const { session, listId } = this.props
    const params = { size: 10000, sessions: [session.id] }
    const items: any = await SimpleItemSessionList.getItemsList(listId, params)
    const products: any[] = []
    if (items && items.content && items.content.length > 0) {
      let code
      let findProduct
      for (let i = 0; i < items.content.length; i++) {
        if (items.content[i].product) {
          code = getProductCode(items.content[i])
          if (enabledWamGroupedProduct()) {
            if (!items.content[i].product.attributes) {
              items.content[i].product.attributes = {}
            }
            items.content[i].product.attributes.wam = code
          }
          findProduct = this.findProduct(products, code)
          if (findProduct) {
            findProduct.quantity++
          } else {
            products.push({
              ...items.content[i],
              quantity: 1,
            })
          }
        }
      }
    }
    return products
  }

  findProduct = (products, code) => {
    if (enabledWamGroupedProduct()) {
      return products.find((p) => p.product.attributes?.wam === code)
    }
    return products.find((prod) => prod.code === code)
  }

  render() {
    const { session } = this.props
    const { opened, items } = this.state
    return (
      <>
        <SessionRow row vcenter>
          <Box flex row vcenter>
            <Session>{session.code}</Session>
          </Box>
          <Button style={{ height: 48 }} variant={'secondary'} onClick={() => this.detail()}>
            {opened ? __(T.misc.close) : __(T.misc.detail)}
          </Button>
        </SessionRow>
        {opened && <EncodingInboundSapViewItemList itemList={items} />}
      </>
    )
  }
}

const SessionRow = styled(Box)`
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 15px 20px;
  margin-bottom: 10px;
`

const Session = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #454545;
  margin-left: 7px;
`
