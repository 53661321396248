import { Component } from 'react'
import { T, __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Box, Input, Button, Icons, ToggleField, AntennaButton } from 'stylewhere/components'

interface Props {
  onChange: (txt) => void
  onEnter: (txt) => void
  onClear: () => void
  umgResetCounter: number
  umgVerified: boolean
  umgVerifying: boolean
  detected: number
  expected?: number
  exportPdf: (type) => void
  checkCountry: boolean
  checkShowCase: boolean
  onChangeCheckCountry: () => void
  onChangeCheckShowCase: () => void
  inputLabel?: string
  enabledPrint?: boolean
}

export class CountTBoxUbicazioniHeader extends Component<Props> {
  getQuantityLabel = () => {
    const { expected } = this.props
    if (expected !== undefined) {
      return __(T.misc.accepted_quantity) + ' / ' + __(T.misc.total_quantity)
    }
    return __(T.misc.accepted_quantity)
  }

  getQuantityValue = () => {
    const { detected, expected } = this.props
    if (expected !== undefined) {
      return detected + ' / ' + expected
    }
    return detected
  }

  render() {
    const {
      onChange,
      onEnter,
      umgResetCounter,
      umgVerified,
      umgVerifying,
      onClear,
      exportPdf,
      checkCountry,
      checkShowCase,
      onChangeCheckCountry,
      onChangeCheckShowCase,
      inputLabel,
      detected,
      enabledPrint,
    } = this.props
    return (
      <Container row vcenter>
        <Input
          label={inputLabel || __(T.misc.parcel)}
          onChange={(txt) => onChange(txt)}
          onEnter={(txt) => (onEnter ? onEnter(txt) : {})}
          customIcon={<Icons.Barcode width={40} height={40} />}
          showLoading={umgVerifying}
          resetCounter={umgResetCounter}
          disabled={umgVerified}
          autoFocus
        />
        <Box flex center ml={15}>
          <Label>{this.getQuantityLabel()}</Label>
          <Text size={'26pt'} mt={'5px'}>
            {this.getQuantityValue()}
          </Text>
        </Box>
        <Box ml={25}>
          <AntennaButton iconOnly disabled={!umgVerified} showPendingTags={false} onClear={() => onClear()} hideClear />
        </Box>
        <Box flex ml={15}>
          <ToggleField
            label={__(T.misc.country_check)}
            onChange={() => onChangeCheckCountry()}
            checked={checkCountry}
          />
          <ToggleField
            label={__(T.misc.showcase_check)}
            onChange={() => onChangeCheckShowCase()}
            checked={checkShowCase}
            mt={5}
          />
        </Box>
        <Box center ml={30}>
          <BoxButton ml={15}>
            <Button
              radius={35}
              padding={'0px 20px'}
              variant={'default'}
              style={{ height: 55 }}
              onClick={() => exportPdf(1)}
              disabled={!umgVerified && detected === 0}
            >
              <Text pointer>{__(T.misc.parziali)}</Text>
            </Button>
            {enabledPrint && (
              <Button
                ml={5}
                variant={'default'}
                style={{ width: 55, height: 55 }}
                onClick={() => exportPdf(2)}
                circle
                disabled={!umgVerified && detected === 0}
              >
                <Icons.Print width={30} height={30} />
              </Button>
            )}
          </BoxButton>
        </Box>
      </Container>
    )
  }
}

const Container = styled(Box)`
  background: #fff;
  padding: 15px 15px;
  border-bottom: 1px solid #f2f2f2;
`

const BoxButton = styled(Box)`
  border: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
`
const Label = styled.label`
  font-weight: normal;
  font-size: 12pt;
  text-align: center;
  line-height: 12pt;
`

const Text = styled.label<{ pointer?: boolean; size?: string; mt?: string }>`
  font-weight: bold;
  font-size: ${({ size }) => (size ? size : '14pt')};
  line-height: ${({ size }) => (size ? size : '16pt')};
  text-align: center;
  margin-top: ${({ mt }) => (mt ? mt : '2px')};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
`
