import { Component } from 'react'
import {
  Page,
  CountTBoxHeader,
  CountTBoxCounter,
  CountTBoxItems,
  Box,
  FullLoadingLayer,
  UnknownItems,
} from 'stylewhere/components'
import {
  Router,
  RemoteOperation,
  SimpleItemListOperationConfig,
  OperationReadingProps,
  OperationReadingState,
  RfidReader,
  OperationReadingProvider,
  getDataFromSchema,
} from 'stylewhere/shared'
import { SimpleItemSessionListExtensions } from 'stylewhere/extensions'
import { DecodedItem, SimpleItemSessionList } from 'stylewhere/api'
import {
  askUserConfirmation,
  showToast,
  showToastError,
  checkItemDecodedStatuses,
  isModalError,
} from 'stylewhere/utils'
import { T, __ } from 'stylewhere/shared/i18n'

interface State extends OperationReadingState {
  sessionListId: string
  unknown: number
  itemsMission: DecodedItem[]
  umg: string
  umgResetCounter: number
  umgVerifying: boolean
  umgVerified: boolean
  sessionId: string
  detectedMission: number
  unknownMission: number
  expectedMission: number
  processing: boolean
  pendingEpcs: string[]
  viewUnexpectedItems: boolean
  unexpectedItems: DecodedItem[]
}

export default class SimpleItemSessionListReading extends Component<OperationReadingProps, State> {
  useUmgInput = false
  timer?: any
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<OperationReadingState>(this.props)
  operation = RemoteOperation.getOperationConfig<SimpleItemListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleItemSessionListExtensions.formSchema(this.operation)
  isModal = false

  state = {
    sessionListId: '',
    items: [],
    unknown: 0,
    detectedMission: 0,
    unknownMission: 0,
    expectedMission: 0,
    itemsMission: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    umg: '',
    umgResetCounter: 0,
    umgVerified: false,
    umgVerifying: false,
    sessionId: '',
    processing: false,
    pendingEpcs: [],
    unexpectedItems: [],
    viewUnexpectedItems: false,
  } as any

  async componentDidMount() {
    if (!this.locationState.formData || !this.locationState.formData.sessionListId) {
      this.goBack()
      return
    }
    this.isModal = isModalError(this.operation)
    const { formData } = this.state
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
    this.setState({
      sessionListId: this.locationState.formData.sessionListId,
      expectedMission: parseInt(formData.quantity || 0, 10),
      umg: this.resetUmg(),
      unknownMission: parseInt(formData.unknownItems || 0, 10),
      detectedMission: parseInt(formData.totalItems || 0, 10),
    })
  }

  getDecodeRequest = () => {
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    return {
      url: SimpleItemSessionList.batchValidateEndpoint(),
      payload: decodePayload,
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const pending: string[] = this.state.pendingEpcs
    const tmp: DecodedItem[] = this.state.items
    const unexpectedTmp: DecodedItem[] = this.state.unexpectedItems
    let tmpUnknown = this.state.unknown
    const epcs = Object.keys(itemMapFromReader)
    let element
    for (let e = 0; e < epcs.length; e++) {
      element = itemMapFromReader[epcs[e]]
      if (element.item) {
        const itemExists = tmp.find((t) => t.item?.id === itemMapFromReader[epcs[e]].item?.id)
        if (!itemExists) {
          if (checkItemDecodedStatuses(element.statuses || [], ['OK', 'SGTIN_FALLBACK_ITEM_CREATED'])) {
            tmp.push(element)
            pending.push(epcs[e])
          } else {
            if (!element.identifierCode) {
              element.identifierCode = epcs[e]
            }
            unexpectedTmp.push(element)
            tmpUnknown++
          }
        }
      } else {
        element.identifierCode = epcs[e]
        unexpectedTmp.push(element)
        tmpUnknown++
      }
    }
    this.setState({ items: tmp, unknown: tmpUnknown, pendingEpcs: pending, unexpectedItems: unexpectedTmp })
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [] })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-item-session-list/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  changeUmg = (txt) => {
    if (txt !== this.state.umg && !this.state.umgVerifying) {
      if (RfidReader.isReading()) {
        RfidReader.stop()
      }
      this.setState(
        {
          umg: txt,
          umgVerified: false,
          sessionId: '',
          items: [],
          unknown: 0,
        },
        this.umgInputStartTimer
      )
    }
  }

  umgInputStartTimer = () => {
    const { umg } = this.state
    this.umgInputStopTimer()
    if (umg !== '') {
      this.timer = setTimeout(() => {
        this.setState(
          {
            umgVerifying: true,
          },
          this.verifyUmg
        )
      }, 500)
    } else {
      this.setState({ umgVerifying: false, umgVerified: false })
    }
  }

  umgInputStopTimer = () => {
    if (!this.timer) return
    clearTimeout(this.timer)
    this.timer = undefined
  }

  resetUmg = () => {
    const { formData } = this.state
    if (this.useUmgInput) {
      return ''
    }
    const current = Date.now()
    if (formData.ddt) {
      return formData.ddt + '_' + current
    }
    return '' + current
  }

  verifyUmg = async () => {
    const { umg } = this.state
    //create session
    try {
      const result = await SimpleItemSessionList.create_session(
        this.locationState.formData.sessionListId,
        umg,
        '' //description
      )
      if (result && result.id) {
        if (!RfidReader.isReading()) {
          RfidReader.start()
        }
        this.setState({
          umgVerifying: false,
          umgVerified: true,
          sessionId: result.id,
        })
      } else {
        showToastError(__(T.error.item_session_list_create_session), __(T.error.error), this.isModal)
        this.setState({
          umgVerifying: false,
        })
      }
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
      this.setState({
        umgVerifying: false,
      })
    }
  }

  _confirm = async () => {
    if (RfidReader.isReading()) {
      RfidReader.stop()
    }

    const { umg } = this.state
    if (!this.useUmgInput) {
      try {
        const result = await SimpleItemSessionList.create_session(this.locationState.formData.sessionListId, umg, '')
        if (result && result.id) {
          this.setState(
            {
              sessionId: result.id,
              processing: true,
            },
            this._doConfirm
          )
        } else {
          showToastError(__(T.error.item_session_list_create_session), __(T.error.error), this.isModal)
        }
      } catch (error) {
        this.setState({ processing: false })
        showToastError(error, __(T.error.error), this.isModal)
      }
    } else {
      this.setState({ processing: true }, this._doConfirm)
    }
  }

  _doConfirm = async () => {
    const { items, itemsMission, umgResetCounter, detectedMission, unknown, unknownMission, sessionId } = this.state
    const itemIds: string[] = []
    for (let i = 0; i < items.length; i++) {
      itemIds.push(items[i].item.id)
    }

    try {
      const result = await SimpleItemSessionList.confirm_session(sessionId, itemIds, { unknownItems: unknown })
      if (result && result.id) {
        let qtSconosciuta = unknownMission + unknown
        if (result.sessionList && result.sessionList.attributes && result.sessionList.attributes.unknownItems) {
          qtSconosciuta = parseInt(result.sessionList.attributes.unknownItems + '', 10)
        }

        let qtTotale = items.length + detectedMission
        if (result.sessionList && result.sessionList.attributes && result.sessionList.attributes.totalItems) {
          qtTotale = parseInt(result.sessionList.attributes.totalItems, 10)
        }

        this.setState({
          items: [],
          pendingEpcs: [],
          unexpectedItems: [],
          unknown: 0,
          detectedMission: qtTotale,
          itemsMission: itemsMission.concat(items),
          umg: this.resetUmg(),
          umgVerified: false,
          umgVerifying: false,
          unknownMission: qtSconosciuta,
          umgResetCounter: umgResetCounter + 1,
          processing: false,
        })
      } else {
        this.setState({ processing: false })
        showToastError(__(T.error.item_session_list_confirm), __(T.error.error), this.isModal)
      }
    } catch (error) {
      this.setState({ processing: false })
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  _clear = () => {
    const { umgResetCounter, pendingEpcs } = this.state
    if (RfidReader.isReading()) {
      RfidReader.stop()
    }
    if (pendingEpcs.length > 0) {
      RfidReader.removeTags(pendingEpcs)
    }
    this.setState({
      items: [],
      unexpectedItems: [],
      pendingEpcs: [],
      unknown: 0,
      umg: '',
      umgVerified: false,
      umgVerifying: false,
      umgResetCounter: umgResetCounter + 1,
    })
  }

  _confirmMission = async () => {
    if (RfidReader.isReading()) {
      RfidReader.stop()
    }
    this.setState({ processing: true }, this._doConfirmMission)
  }

  _doConfirmMission = async () => {
    const ok = await askUserConfirmation(__(T.misc.confirm_mission), __(T.misc.confirm_mission_text))
    if (ok) {
      try {
        const result = await SimpleItemSessionList.confirm_list(
          this.operation.id,
          this.locationState.formData.sessionListId
        )
        if (result && result.id) {
          this.setState({ processing: false })
          showToast({
            title: __(T.misc.success),
            description: __(T.messages.item_session_list_confirm),
            status: 'success',
          })
          this.goBack()
        } else {
          this.setState({ processing: false })
          showToastError(__(T.error.item_session_list_confirm), __(T.error.error), this.isModal)
        }
      } catch (error) {
        this.setState({ processing: false })
        showToastError(error, __(T.error.error), this.isModal)
      }
    }
  }

  canDelete = () => {
    const sessionRework = this.operation.options.sessionRework || 'no'
    const sessionUpdatePolicy = this.operation.options.sessionUpdatePolicy || 'error'
    return (
      (sessionUpdatePolicy === 'override' || sessionUpdatePolicy === 'keepPreviousVersions') && sessionRework == 'yes'
    )
    return true
  }

  _clearMission = async () => {
    const { sessionListId } = this.state
    if (this.canDelete()) {
      const ok = await askUserConfirmation(__(T.custom.deleteList), __(T.custom.deleteListMessage))
      if (ok) {
        try {
          await SimpleItemSessionList.delete_list(sessionListId)
          this.setState({
            itemsMission: [],
            unknownMission: 0,
          })
          RfidReader.clear()
        } catch (error) {
          showToastError(error, __(T.error.error), this.isModal)
        }
      }
    } else {
      this.setState({
        itemsMission: [],
        unknownMission: 0,
      })
    }
  }

  _viewUnexpectedItems = () => {
    this.setState({ viewUnexpectedItems: true })
  }

  _closeUnexpectedItems = () => {
    this.setState({ viewUnexpectedItems: false })
  }

  render() {
    const {
      items,
      formData,
      loading,
      unknown,
      unknownMission,
      itemsMission,
      umgResetCounter,
      umgVerified,
      umgVerifying,
      expectedMission,
      processing,
      viewUnexpectedItems,
      unexpectedItems,
      detectedMission,
    } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        forceDetails="top"
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        enableEmulation
      >
        <Page.Content notBoxed style={{ margin: 0 }}>
          <CountTBoxHeader
            onChange={this.changeUmg}
            umgResetCounter={umgResetCounter}
            umgVerified={umgVerified}
            umgVerifying={umgVerifying}
            onClear={this.onClear}
            inputLabel={__(T.custom.countTBoxParcel)}
            addUmgInput={this.useUmgInput}
          />
          <Box row bg="#FFF">
            <Box flex>
              <CountTBoxCounter
                ok={items.length}
                unknown={unknown}
                onConfirm={this._confirm}
                onClear={this._clear}
                useUnknown={true}
                unknownClick={this._viewUnexpectedItems}
              />
            </Box>
            <Box flex>
              <CountTBoxCounter
                title={__(T.custom.countTBoxDDT)}
                ok={detectedMission}
                expected={expectedMission}
                onConfirm={this._confirmMission}
                onClear={this._clearMission}
                unknown={unknownMission}
                useUnknown={true}
              />
            </Box>
          </Box>
          <Box row flex bg="#FFF">
            <CountTBoxItems style={{ borderRight: '1px solid #f2f2f2' }} items={items} />
            <CountTBoxItems items={itemsMission} />
          </Box>
        </Page.Content>
        <UnknownItems
          onClose={() => this._closeUnexpectedItems()}
          visible={viewUnexpectedItems}
          unexpectedItems={unexpectedItems}
        />
        {processing && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
      </Page>
    )
  }
}
