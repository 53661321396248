import { Component } from 'react'
import Hotkeys from 'react-hot-keys'
import styled from '@emotion/styled'
import { MotionStyle } from 'framer-motion'
import { AppStore, RfidReader, Router } from 'stylewhere/shared'
import { __CAPITALIZE } from 'stylewhere/utils'
import { Box, EmulationModal, Header, HeaderAction, HeaderDetail, PageFormDetail } from 'stylewhere/components'

// eslint-disable-next-line
export type PageHeader = { details?: HeaderDetail; actions?: HeaderAction[] }

interface Props {
  title: string
  onBackPress?: (() => void) | false
  loading?: boolean
  header?: PageHeader | null
  headerRight?: JSX.Element
  enableEmulation?: boolean
  forceDetails?: 'top' | 'right' | false
  contentWrapperColumn?: boolean
}

interface State {
  showEmulationModal: boolean
}

export class Page extends Component<Props, State> {
  static Title = styled(Box)`
    font-weight: bold;
    font-size: 30px;
  `

  static TopBar = ({ children }: { children: React.ReactNode }) => {
    return (
      <Box mb={40} pv={10} row>
        {children}
      </Box>
    )
  }

  static Sidebar = ({
    style,
    width = 360,
    children,
    pl,
    pb,
    height,
    hideBar,
  }: {
    style?: MotionStyle
    width?: string | number
    children: React.ReactNode
    pl?: number
    pb?: number
    height?: string | number
    hideBar?: boolean
  }) => {
    return (
      <Box hideBar={hideBar} style={{ overflowY: 'auto', ...style }} width={width} bgGrey>
        <Box flex pt={15} pb={pb === undefined ? 15 : pb} pl={pl === undefined ? 15 : pl} height={height}>
          {children}
        </Box>
      </Box>
    )
  }

  static Content = ({
    children,
    style,
    notBoxed = false,
    overflowY,
  }: {
    style?: MotionStyle
    children: React.ReactNode
    notBoxed?: boolean
    overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
  }) => {
    return (
      <ContentInner overflowY={overflowY} flex style={style} notBoxed={notBoxed}>
        {children}
      </ContentInner>
    )
  }

  state: State = {
    showEmulationModal: false,
  }

  getDataFromHost = () => {
    let host = window.location.host
    let data: string[] = []
    if (host.indexOf('localhost') === -1) {
      host = host.replace('https://', '')
      host = host.replace('station.', '')
      const splitData = host.split('.')
      host = splitData[0]
      host = host.replace('-stw', '')
      data = host.split('-')
    }
    return {
      client:
        data.length > 0 && data[0] !== 'stylewhere'
          ? __CAPITALIZE(data[0])
          : undefined,
      envinroment: data.length > 1 ? data[1].toUpperCase() : undefined,
    } 
  }

  componentDidMount() {
    const data = this.getDataFromHost()
    let title = 'Stylewhere'
    if (data.client) title += ' ' + data.client
    title += ` | RFID Station - ${this.props.title}`
    if (title !== document.title) {
      if (data.envinroment) title = '[' + data.envinroment + '] ' + title      
      document.title = title
    }
  }

  onEmulationConfirm = (input?: string) => {
    this.setState({ showEmulationModal: false })
    if (!input) return
    const codes: string[] = String(input).replace(/ /g, '').split(',') ?? []
    RfidReader.emulateTagAuto(
      codes.map((code) => (AppStore.emulationIdentifierType === 'UHF' ? { epc: code } : { uid: code }))
    )
    //RfidReader.emulateTagAuto(
    //codes.map((code) => (AppStore.emulationIdentifierType === 'UHF' ? { epc: code } : { uid: code }))
    //)
  }

  onKeyDown = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ showEmulationModal: !this.state.showEmulationModal })
  }

  getFormSchemaLength = () => {
    const { header } = this.props
    if (header && header.details && header.details.formSchema)
      return header?.details.formSchema.filter((field) => field.type !== 'hidden').length
    return 0
  }

  render() {
    const {
      title,
      onBackPress,
      header,
      headerRight,
      children,
      enableEmulation,
      contentWrapperColumn,
      forceDetails = false,
    } = this.props
    const { showEmulationModal } = this.state
    let detailsPosition
    if (header?.details && header.details.formSchema.length > 0) {
      if (forceDetails) {
        detailsPosition = forceDetails
      } else {
        detailsPosition = this.getFormSchemaLength() <= 2 ? 'top' : 'right'
      }
    }
    return (
      <>
        <Hotkeys
          keyName="command+e,ctrl+e,ctrl+m,command+m,alt+m"
          onKeyDown={this.onKeyDown}
          disabled={!enableEmulation || !AppStore.getEmulation()}
        >
          <PageContainer>
            {header !== null && (
              <Header
                title={title}
                actions={header?.actions}
                details={detailsPosition === 'top' ? header?.details : undefined}
                onBackPress={onBackPress ?? (() => Router.navigate('/'))}
              >
                {headerRight}
              </Header>
            )}
            <ContentWrapper flex row={!contentWrapperColumn}>
              {children}
              {header?.details && detailsPosition === 'right' && (
                <LeftHeaderContainer>
                  <PageFormDetail
                    changeButtonStyle={{ margin: 10, marginBottom: 15, marginLeft: 0 }}
                    detailStyle={{ overflowWrap: 'break-word' }}
                    initData={header.details.data}
                    formSchema={header.details.formSchema}
                    setFormData={header.details.setFormData}
                    resetFormData={header.details.resetFormData}
                    operationId={header.details.operationId}
                  />
                </LeftHeaderContainer>
              )}
            </ContentWrapper>
          </PageContainer>

          {enableEmulation && showEmulationModal && (
            <EmulationModal
              title="Emulation Modal"
              onClose={() => {
                this.setState({ showEmulationModal: false })
              }}
              onConfirm={this.onEmulationConfirm}
            />
          )}
        </Hotkeys>
      </>
    )
  }
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
`

const ContentWrapper = styled(Box)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.background1};
`

const ContentInner = styled(Box)<{
  notBoxed?: boolean
  overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
}>`
  overflow-y: ${({ overflowY = 'auto' }) => overflowY};
  scrollbar-width: none;
  margin: ${({ theme }) => theme.padding};
  margin-left: 15px;
  box-shadow: ${({ notBoxed }) => (notBoxed ? 'none' : `0 2px 10px rgba(0, 0, 0, 0.05)`)};
  border-radius: ${({ notBoxed }) => (notBoxed ? 'none' : `15px`)};
  background-color: ${({ notBoxed, theme }) => (notBoxed ? 'inherit' : theme.background2)};
`

const LeftHeaderContainer = styled(Box)`
  background: #eaeaea;
  border-radius: 15px;
  margin: 15px;
  margin-left: 0px;
  padding-left: 15px;
  max-width: 20vw;
  min-width: 20vw;
  height: fit-content;
  overflow-y: auto;
  max-height: calc(100vh - 130px);
`
