import {
  TmrBaseResource,
  ResourcePage,
  api,
  responseErrorCheck,
  InventoryItem,
  Inventory,
  InventoryCounters,
} from 'stylewhere/api'

export class Inventories extends TmrBaseResource {
  static endpoint = `/api/v1` // /inventory

  /**
   * Search inventories
   *
   * @param {Object} searchParams
   * @returns {Array}
   */
  static search<T = any>(searchParams): Promise<ResourcePage<T>> {
    return api.get<ResourcePage<T>>(`${this.endpoint}/inventories`, searchParams).then(responseErrorCheck)
  }

  /**
   * Get one Object based on the search params (eg. id: 435)
   *
   * @param {string} resourceType entity identifier
   * @returns {Object}
   */
  static async get<T>(id: string, requestConfig = undefined || {}) {
    const result = await api.get<T>(`${this.endpoint}/inventories/${id}`, requestConfig)
    return responseErrorCheck(result)
  }

  /**
   * Get Items for Inventory
   *
   * @param {Object} id Inventory ID
   * @returns {Array}
   */
  static getItems(id, searchParams: any = {}): Promise<ResourcePage<InventoryItem>> {
    searchParams.inventoryId = id
    return api
      .get<ResourcePage<InventoryItem>>(`${this.endpoint}/inventoryEntries`, searchParams)
      .then(responseErrorCheck)
  }

  static getAggregatedItems(id, searchParams: any = {}): Promise<ResourcePage<InventoryItem>> {
    searchParams.inventoryId = id
    return api
      .get<ResourcePage<InventoryItem>>(`${this.endpoint}/inventoryEntryAggregates`, searchParams)
      .then(responseErrorCheck)
  }

  /**
   * Update Inventory Items
   *
   * @param {Object} id Inventory ID
   * @param {Array} items
   * @returns {Number}
   */
  static updateItems(id: string, items: { itemIdentifierCodes: string[] }) {
    return api.post(`${this.endpoint}/operations/inventory/${id}/insertTags`, items).then(responseErrorCheck)
  }

  static getCounters(id): Promise<InventoryCounters> {
    return api
      .get<InventoryCounters>(`${this.endpoint}/operations/inventory/${id}/getCounters`)
      .then(responseErrorCheck)
  }

  static create(inventory: Partial<Inventory>): Promise<Inventory> {
    return api.post<Inventory>(`${this.endpoint}/operations/inventory/create`, inventory).then(responseErrorCheck)
  }

  static close(inventory: Inventory): Promise<void> {
    return api.post<void>(`${this.endpoint}/operations/inventory/${inventory.id}/close`).then(responseErrorCheck)
  }

  static delete(inventory: Partial<Inventory>): Promise<boolean> {
    return api.delete<boolean>(`${this.endpoint}/operations/inventory/${inventory.id}/delete`).then((data) => data.ok)
  }

  static batchValidateEndpoint() {
    return `${this.endpoint}/operations/inventory/batchValidate`
  }
}
