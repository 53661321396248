import { Box as ChakraBox } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ShippingParcel } from 'stylewhere/api/types'
import { Box, Button, GroupedListRow, Icons, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'

export function GroupedParcelListItem({
  parcel,
  removeParcel,
  overrideReadCounter,
}: {
  parcel: ShippingParcel
  removeParcel?: (parcel: ShippingParcel) => void
  overrideReadCounter?: number
}) {
  return (
    <GroupedListRow pv={16} pl={8} pr={16} style={{ height: 120 }}>
      <Box p={8} center>
        <Icons.Package width={60} />
      </Box>
      <Box pl={8} flex row style={{ overflow: 'auto', alignItems: 'center' }}>
        <Code>{parcel.code || 'n/a'}</Code>
        <Qty>
          {overrideReadCounter !== undefined
            ? overrideReadCounter
            : parcel.parcelEntryQuantity !== null
            ? parcel.parcelEntryQuantity ?? 0
            : 0}
        </Qty>
      </Box>
      <Box row center>
        {!parcel.__detected && <ToRead>{__(T.simple_parcel_list.to_read)}</ToRead>}
        {parcel.__detected && parcel.__unexpected && <Unexpected>{__(T.simple_parcel_list.unexpected)}</Unexpected>}
        {parcel.__detected && !parcel.__unexpected && parcel.__forced && <Ok>{__(T.simple_parcel_list.forced)}</Ok>}
        {parcel.__detected && !parcel.__unexpected && !parcel.__forced && <Ok>{__(T.simple_parcel_list.ok)}</Ok>}
        <Spacer />
        {parcel.__detected && (
          <Box
            style={{
              alignSelf: 'flex-start',
              marginTop: 8,
            }}
          >
            <Button
              onClick={removeParcel ? () => removeParcel(parcel) : undefined}
              variant="secondary"
              size="small"
              circle
              padding={'0px'}
            >
              <Icons.Delete width={16} height={16} />
            </Button>
          </Box>
        )}
      </Box>
    </GroupedListRow>
  )
}

const Code = styled.div`
  font-weight: bold;
  font-size: 28px;
  white-space: nowrap;
`

const Status = styled(ChakraBox)`
  font-weight: bold;
  font-size: 24px;
  border-radius: 8px;
  padding: 0px 20px;
  height: 65px;
  line-height: 65px;
`

const Unexpected = styled(Status)`
  background: #ff8474;
`

const ToRead = styled(Status)`
  background: #f0f0f0;
`

const Ok = styled(Status)`
  background: #75eba8;
`

const Qty = styled.div`
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0px 10px;
  border-radius: 8px;
  background: #f0f0f0;
`
