import {
  FormSchema,
  FormSchemaData,
  jsonToFormSchema,
  OperationConfig,
  TransferToZoneOperationConfig,
} from 'stylewhere/shared'
import { __, T } from 'stylewhere/i18n'
import { askUserConfirmation } from 'stylewhere/shared/utils'
import { Extensions } from './Extensions'

export class TransferToZoneExtensions extends Extensions {
  static async beforeConfirm<O extends any[]>(
    operation: OperationConfig,
    data: FormSchemaData,
    // Possono essere una lista di items, di parcels o altro a seconda del tipo di operazione
    objects: O
  ): Promise<boolean> {
    const warningItems = objects.filter((itm) => itm.item?.status === 'warning').length
    if (warningItems > 0) {
      const result = await askUserConfirmation(
        __(T.messages.warning_items_found),
        __(T.messages.are_you_sure_you_want_to_confirm)
      )
      if (!result) throw new Error(undefined)
    }
    return super.beforeConfirm(operation, data, objects)
  }

  static formSchema(operation: TransferToZoneOperationConfig): FormSchema {
    if (operation.formSchema && Object.keys(operation.formSchema).length > 0)
      return jsonToFormSchema(operation.formSchema as any)

    return [
      {
        type: 'autocomplete',
        name: 'destinationZoneId',
        label: __(T.misc.destination),
        endpoint: 'api/v1/zones?type=STOCK&placeId={placeId}',
        required: true,
        confirmValuePath: 'id',
      },
    ]
  }
}
