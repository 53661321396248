import { Component } from 'react'
import { T, __UP, __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Box, Text } from 'stylewhere/components'
import { ErrorLogs } from 'stylewhere/api'
import { getCustomTranslation } from 'stylewhere/shared/utils'

interface Props {
  log: ErrorLogs
  onlyMessage?: boolean
  disabledStatus?: boolean
}

export class ErrorLog extends Component<Props> {
  getBackground = () => {
    const { log } = this.props
    if (log.background) {
      return log.background
    } else if (log.status === 'success' || log.status === 'Box Closed' || log.status === 'TRACE') {
      return '#75EBA8'
    } else if (log.status === 'error' || log.status === 'ERROR') {
      return '#F27D7D'
    } else if (log.status === 'Barcode read' || log.status === 'INFO') {
      return '#75EBE4'
    } else if (log.status === 'WARNING') {
      return '#FFEFC6'
    }
    return '#DFDFDF'
  }

  render() {
    const { log, onlyMessage, disabledStatus } = this.props
    return (
      <Container row vcenter>
        <Text fontSize={12} bold>
          {log.date}
        </Text>
        {!onlyMessage && !disabledStatus && (
          <Status bg={this.getBackground()}>{log.statusLabel || getCustomTranslation(log.status)}</Status>
        )}
        {!onlyMessage && (
          <Text style={{ flex: 1, marginLeft: 10 }} fontSize={12} bold>
            {log.message}
          </Text>
        )}
        {onlyMessage && (
          <Text style={{ flex: 1 }} fontSize={12} bold>
            <Status bg={this.getBackground()}>{log.message}</Status>
          </Text>
        )}
      </Container>
    )
  }
}

const Container = styled(Box)`
  background: #fff;
  padding: 10px 10px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
`

const Status = styled.div<{ bg: string }>`
  background: ${({ bg }) => bg || '#F27D7D;'};
  margin-left: 10px;
  font-weight: bold;
  border-radius: 5px;
  padding: 0px 5px;
  font-size: 14px;
  float: left;
`
