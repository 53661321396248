import { AppStore, keycloak } from 'stylewhere/shared'
import { api, TmrUser, responseErrorCheck } from 'stylewhere/api'
import { showToast } from 'core/shared/utils'
import { __, T } from 'core/shared/i18n'

export class Auth {
  static async login() {
    try {
      return await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
    } catch (error) {
      const description = error instanceof Error ? error.message : __(T.error.authentication_failed)
      showToast({ status: 'error', description })
      return undefined
    }
  }

  static async logout(): Promise<void> {
    await AppStore.logout()
  }

  static async loggedUser(): Promise<TmrUser | undefined> {
    const res = await api.get<TmrUser>(`api/v1/users/loggedUser`)
    return responseErrorCheck<TmrUser>(res)
  }

  static setHeaderAccessToken(token: string) {
    return api.setHeaders({
      // set headers to 'api' directly otherwise wont work
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token || undefined}`,
    })
  }
}
