import UIfx from 'uifx'
import { sounds } from 'stylewhere/assets'

const play = (file: any) => {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    const sound = new UIfx(file.default, {
      volume: 1,
    })
    sound.play()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export default {
  confirmation: () => play(sounds.confirmation),
  error: () => play(sounds.error),
  fail: () => play(sounds.fail),
  next: () => play(sounds.next),
  scan: () => play(sounds.scan),
  success: () => play(sounds.success),
  tap: () => play(sounds.tap),
}
