import styled from '@emotion/styled'
import { Component } from 'react'
import { ConfigurationDetail } from 'stylewhere/api'
import { Box, Icons, Text, Input } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { animations } from 'stylewhere/assets'
import Lottie from 'react-lottie'

interface Props {
  message?: string
  antennaOn?: boolean
  identifier?: ConfigurationDetail
  onInputSubmit?: (value: string) => void
  startAntenna?: () => void
  starting?: boolean
}

export class EncodingReading extends Component<Props> {
  identifierTypes = {
    UHF_TAG: { icon: <Icons.Rfid width={40} height={40} />, text: 'TAG UHF' },
    NFC_TAG: { icon: <Icons.Nfc width={40} height={40} />, text: 'TAG NFC' },
    SIMPLE_ITEM_IDENTIFIER: { icon: <Icons.Serial width={40} height={40} />, text: 'IDENTIFIER' },
  }

  render() {
    const { antennaOn, message, identifier, onInputSubmit, startAntenna, starting } = this.props
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animations.Onde,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }
    const idfType = identifier ? this.identifierTypes[identifier.identifierType] : ''
    return (
      <Box borderRadius={15} ph={40} pl={10} pr={10} flex bg="white" center>
        <Box flex center width="100%">
          {!antennaOn ? (
            <Icons.Antenna style={{ height: 35, width: 60, marginBottom: 20 }} />
          ) : (
            <Icons.AntennaOn style={{ height: 40, marginBottom: 20 }} />
          )}
          <Text center fontSize={20}>
            {message ?? __(T.messages.bring_an_item_to_antenna)}
          </Text>
          {identifier && onInputSubmit && (
            <Box mt={10} width={'100%'} center>
              <Text center fontSize={20} style={{ marginBottom: 15 }}>
                {__(T.misc.or)}
              </Text>
              <Input
                disabled={(identifier._status ?? 'TO_BE_READ') !== 'TO_BE_READ'}
                startFocus
                defaultValue={identifier.code}
                onEnter={this.props.onInputSubmit}
                label={`${!!identifier.role ? identifier.role : idfType?.text}`}
                customIcon={idfType ? idfType.icon : <Icons.Serial width={40} height={40} />}
                style={{ width: '100%', maxWidth: 400 }}
              />
            </Box>
          )}
        </Box>
        <Box bg="#F2F2F2" borderRadius={5} pl={15} pr={15} pt={10} pb={10} width="100%" mb={10} mt={10} row>
          <ButtonControl
            row
            center
            reading={antennaOn}
            onClick={!antennaOn && startAntenna ? startAntenna : () => {}}
            disabled={antennaOn}
          >
            {starting && <Icons.Loader style={{ width: 28, height: 28 }} />}
            {antennaOn && !starting && <Icons.AntennaOn style={{ height: 38, width: 50 }} />}
            {!antennaOn && !starting && <Icons.Play style={{ width: 28, height: 28, marginLeft: 10 }} />}
          </ButtonControl>
          {antennaOn ? (
            <Lottie isClickToPauseDisabled style={{ minWidth: 200, height: 70 }} options={defaultOptions} />
          ) : (
            <Box flex center>
              <Line />
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

const Line = styled.div`
  height: 3px;
  max-height: 3px;
  background-color: #2ef583;
  width: 100%;
  border-radius: 3px;
`

const ButtonControl = styled(Box)<{ reading?: boolean; disabled?: boolean }>`
  border-radius: 35px;
  height: 70px;
  margin-right: 10px;
  cursor: pointer;
  width: 70px;
  font-weight: 900;
  font-size: 22px;
  color: ${({ reading }) => (reading ? '#FFF' : '#000')};
  background-color: ${({ reading }) => (reading ? '#DDD' : '#ceff00')};
  opacity: ${({ disabled }) => (disabled ? '0.5 !important' : '1')};
`
