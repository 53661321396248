import { Component } from 'react'
import { __UP } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'
import { Box, Icons } from 'stylewhere/components'

interface Props {
  title: string
  subtitle?: string
  type: number
  status: number
  minHeight?: number
  iconWidth?: number
  iconHeight?: number
  layout?: any
  enabledIconStatus?: boolean
  enabledSpinner?: boolean
}

export class ImbustatriceTagRow extends Component<Props> {
  iconWidth = 50
  iconHeight = 50

  getIconWidth = () => {
    const { iconWidth } = this.props
    return iconWidth || this.iconWidth
  }

  getIconHeight = () => {
    const { iconHeight } = this.props
    return iconHeight || this.iconHeight
  }

  getBackground = () => {
    const { status } = this.props
    if (status === 2) {
      return '#EB5757'
    } else if (status === 1) {
      return '#75EBA8'
    }
    return '#FFFFFF'
  }

  getTextColor = () => {
    const { status } = this.props
    if (status === 2) {
      return '#FFF'
    } else if (status === 1) {
      return '#454545'
    }
    return '#454545'
  }

  getIcon = () => {
    const { type, status, enabledSpinner } = this.props
    if (status === 0 && enabledSpinner) return <Spinner thickness="5px" speed="0.65s" color="#454545" />
    if (type === 1) {
      return status === 2 ? (
        <Icons.RfidWhite width={this.getIconWidth()} height={this.getIconHeight()} />
      ) : (
        <Icons.Rfid width={this.getIconWidth()} height={this.getIconHeight()} />
      )
    }
    return status === 2 ? (
      <Icons.EncodingWhite width={this.getIconWidth() + 10} height={this.getIconHeight() + 10} />
    ) : (
      <Icons.Encoding width={this.getIconWidth() + 10} height={this.getIconHeight() + 10} />
    )
  }

  getIconStatus = () => {
    const { status } = this.props
    if (status === 1) {
      return <Icons.CheckFilled width={this.getIconWidth()} height={this.getIconHeight()} />
    } else if (status === 2) {
      return <Icons.AssociationErrorWhite width={this.getIconWidth()} height={this.getIconHeight()} />
    }
    return null
  }

  render() {
    const { title, subtitle, minHeight, layout, enabledIconStatus } = this.props
    const textColor = this.getTextColor()
    return (
      <Container
        mb={layout && layout.mb ? layout.mb : '15px'}
        mr={layout && layout.mr ? layout.mr : '15px'}
        padding={layout && layout.padding ? layout.padding : '15px 15px'}
        row
        vcenter
        minheight={minHeight}
        bg={this.getBackground()}
      >
        <Box mr={layout && layout.mrIcon ? layout.mrIcon : 10} width={this.getIconWidth() + 10} center>
          {this.getIcon()}
        </Box>
        <Box flex>
          <Title
            size={layout && layout.size ? layout.size : '16pt'}
            lineHeight={layout && layout.lineHeight ? layout.lineHeight : '18pt'}
            color={textColor}
          >
            {title}
          </Title>
          {subtitle && subtitle !== '' && (
            <SubTitle size={layout && layout.sizeSub ? layout.sizeSub : '12pt'} color={textColor}>
              {subtitle}
            </SubTitle>
          )}
        </Box>
        {enabledIconStatus && (
          <Box ml={10} width={this.getIconWidth()} center>
            {this.getIconStatus()}
          </Box>
        )}
      </Container>
    )
  }
}

const Container = styled(Box)<{ bg: string; mb?: string; mr?: string; padding?: string }>`
  background: ${({ bg }) => bg || '#fff;'};
  padding: ${({ padding }) => padding || '15px 15px;'};
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: ${({ mb }) => mb || '15px;'};
  border-radius: 10px;
  margin-right: ${({ mr }) => mr || '15px;'};
`

const Title = styled.label<{ color: string; size: string; lineHeight: string }>`
  font-weight: bold;
  font-size: ${({ size }) => size || '16pt;'};
  line-height: ${({ size }) => size || '18pt;'};
  color: ${({ color }) => color || '#454545;'};
`

const SubTitle = styled.label<{ color: string; size: string }>`
  font-weight: normal;
  font-size: ${({ size }) => size || '16pt;'};
  color: ${({ color }) => color || '#454545;'};
`
