import { ShippingParcel } from 'stylewhere/api'
import { GroupedItemRow, List } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { SimpleParcelListOperationConfig } from 'stylewhere/shared'
import { GroupedParcelListItem } from './GroupedParcelListItem'

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  operation: SimpleParcelListOperationConfig
  parcels: ShippingParcel[]
  loading?: boolean
  removeParcel?: (parcel: ShippingParcel) => void
  refresh?: () => void
}

export function GroupedParcelList({ parcels, removeParcel, loading = false }: Props) {
  const rowRender = (parcel: ShippingParcel) => {
    return <GroupedParcelListItem parcel={parcel} removeParcel={removeParcel} />
  }

  if (loading) {
    return (
      <>
        <GroupedItemRow.Skeleton />
        <GroupedItemRow.Skeleton />
      </>
    )
  }

  const rows = parcels.sort((parcel: ShippingParcel) => {
    if (parcel.__unexpected) return -1
    if (parcel.__detected) return 1
    return 0
  })

  return (
    <List
      data={rows}
      emptyMessage={<span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>}
      rowRender={rowRender}
    />
  )
}
