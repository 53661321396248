import { Box, Button, Container, Page } from 'stylewhere/components'
import { __, T } from 'stylewhere/i18n'
import { Router } from 'stylewhere/shared'

export default function ConnectionError() {
  return (
    <Page title={__(T.error.connection)} onBackPress={false}>
      <Container>
        <Box center width="100%">
          <Box mb={40} width={500} style={{ textAlign: 'center' }}>
            {__(T.error.connection_details)}
          </Box>
          <Button onClick={() => Router.reloadApp('/')}>{__(T.misc.retry)}</Button>
        </Box>
      </Container>
    </Page>
  )
}
