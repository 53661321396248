import {
  api,
  TmrBaseResource,
  CreateSessionItemListRequest,
  CreateSessionItemListResponse,
  CreateSessionItemListSessionRequest,
  CreateSessionItemListSessionResponse,
  ConfirmSessionItemListSessionRequest,
  ConfirmSessionItemListRequest,
  responseErrorCheck,
} from 'stylewhere/api'

export class SimpleItemSessionList extends TmrBaseResource {
  static configSap = { timeout: 210000 }
  static endpoint = 'api/v1/operations/simpleItemSessionList'
  static endpointList = 'api/v1/simpleItemSessionLists'

  static batchValidateEndpoint() {
    return `${this.endpoint}/batchValidate`
  }

  static async create_list(operationId: string, code: string, description: string, placeId: string, attributes?: any) {
    const decodePayload: CreateSessionItemListRequest = {
      operationId: operationId,
      code: code,
      description: description,
      placeId: placeId,
      attributes: attributes || {},
    }
    const res = await api.post<CreateSessionItemListResponse>(`${this.endpoint}`, decodePayload, this.configSap)
    return responseErrorCheck(res)
  }

  static async create_session(sessionListId: string, code: string, description: string, attributes?: any) {
    const decodePayload: CreateSessionItemListSessionRequest = {
      sessionListId: sessionListId,
      code: code,
      description: description,
      attributes: attributes || {},
    }
    const res = await api.post<CreateSessionItemListSessionResponse>(
      `${this.endpoint}/session`,
      decodePayload,
      this.configSap
    )
    return responseErrorCheck(res)
  }

  static async confirm_session(sessionId: string, items: string[], attributes?: any, async?: boolean) {
    const decodePayload: ConfirmSessionItemListSessionRequest = {
      sessionId: sessionId,
      itemIds: items,
      attributes: attributes || {},
    }
    const res = await api.post<CreateSessionItemListSessionResponse>(
      `${this.endpoint}/session/confirm` + (async ? '/asyncExecution' : ''),
      decodePayload,
      this.configSap
    )
    return responseErrorCheck(res)
  }

  static async confirm_list(operationId: string, listId: string[], attributes?: any, async?: boolean) {
    const decodePayload: ConfirmSessionItemListRequest = {
      operationId: operationId,
      listId: listId,
      attributes: attributes || {},
    }
    const res = await api.post<CreateSessionItemListSessionResponse>(
      `${this.endpoint}/confirm` + (async ? '/asyncExecution' : ''),
      decodePayload,
      this.configSap
    )
    return responseErrorCheck(res)
  }

  static async delete_session(entityId: string) {
    const decodePayload = {
      entityId: entityId,
    }
    const res = await api.delete<CreateSessionItemListSessionResponse>(
      `${this.endpoint}/session`,
      {},
      { data: decodePayload }
    )
    return responseErrorCheck(res)
  }

  static async delete_list(entityId: string) {
    const decodePayload = {
      entityId: entityId,
    }
    const res = await api.delete<CreateSessionItemListSessionResponse>(`${this.endpoint}`, {}, { data: decodePayload })
    return responseErrorCheck(res)
  }

  static async executeAction(listId: string, operationId: string, code: string) {
    const decodePayload = {
      operationId: operationId,
      actionCode: 'pdfMissionPrint',
      attributes: {
        printTemplateCode: code,
      },
    }
    const res = await api.post(`${this.endpoint}/${listId}/executeAction `, decodePayload, {
      responseType: 'blob',
      timeout: 210000,
    })
    return responseErrorCheck(res)
  }

  static async updateContent(sessionId: string, operation: string, itemIds: string[], attributes?) {
    const decodePayload = {
      sessionId: sessionId,
      updateOperation: operation,
      itemIds: itemIds,
      attributes: attributes || {},
    }
    const res = await api.post(`${this.endpoint}/session/updateContent`, decodePayload, this.configSap)
    return responseErrorCheck(res)
  }

  static async getItemsList(id: string, params = {}) {
    let items: any[] = []
    try {
      items = await api.get<any>(`${this.endpointList}/${id}/items`, params).then(responseErrorCheck)
    } catch (e) {}
    return items
  }

  static async decodeAndCreate(listId: string, operationId: string, code: string, tags: string, workstationId: string) {
    const decodePayload = {
      operationId: operationId,
      actionCode: 'DECODE_AND_CREATE',
      attributes: {
        encodingCode: code,
        tags: tags,
        workstationId: workstationId,
      },
    }
    const res = await api.post(`${this.endpoint}/${listId}/executeAction `, decodePayload)
    return responseErrorCheck(res)
  }
}
