import { Component } from 'react'
import { OperationStart, FullLoadingLayer } from 'stylewhere/components'
import { Router, AppStore, FormSchemaData, OperationConfig, FormSchema, RemoteOperation } from 'stylewhere/shared'
import { ShippingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { ShippingExtensions } from 'stylewhere/extensions'
import { getFieldWithAutocomplete, showToastError, isModalError } from 'stylewhere/utils'
import { Shippings } from 'stylewhere/api'
import { RouteComponentProps } from 'react-router'
import { T, __ } from 'stylewhere/shared/i18n'

interface Params {
  opCode: string
}

interface State {
  loading: boolean
  formData?: FormSchemaData
  operationCode?: string
  response?: any
}

export default class ShippingStart extends Component<RouteComponentProps<Params>, State> {
  isModal = false
  matchParams = Router.getMatchParams(this.props)
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.matchParams.opCode)

  state = {
    loading: false,
    formData: undefined,
    operationCode: '',
    response: { checklist: {}, products: {} },
  }

  componentDidMount() {
    this.isModal = isModalError(this.operation)
  }

  onSubmit = async (formData: FormSchemaData, operation: OperationConfig, schema: FormSchema) => {
    const field = getFieldWithAutocomplete(schema)
    if (field && formData[field.name] && AppStore.defaultWorkstation) {
      this.setState({ loading: true })
      try {
        const result = await Shippings.startChecklist(operation, formData[field.name], field.name)
        if (result && result.checklist && result.products) {
          this.setState(
            { loading: false, formData: formData, operationCode: operation.code, response: result },
            this.submitForm
          )
        } else {
          this.setState({ loading: false })
          showToastError(__(T.error.start_checklist), __(T.error.error), this.isModal)
        }
      } catch (error) {
        this.setState({ loading: false })
        showToastError(error, __(T.error.error), this.isModal)
      }
    }
  }

  submitForm = () => {
    const { formData, operationCode, response } = this.state
    Router.navigate(
      '/shipping/cartonization/:opCode/reading',
      { opCode: operationCode },
      { state: { formData: formData, checklist: response.checklist, products: response.products } }
    )
  }

  render() {
    const { loading, formData } = this.state
    return (
      <>
        <OperationStart
          submitPath={'/shipping/cartonization/:opCode/reading'}
          extensions={ShippingExtensions}
          onSubmit={this.onSubmit}
          formDataUpdated={formData}
        />
        {loading && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
      </>
    )
  }
}
