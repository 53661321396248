import { Component } from 'react'
import { T, __ } from 'stylewhere/i18n'
import { Box, Input, Icons, ToggleField } from 'stylewhere/components'

interface Props {
  onChange: (txt) => void
  onEnter: (txt) => void
  umgResetCounter: number
  umgVerified: boolean
  umgVerifying: boolean
  checkCountry: boolean
  checkShowCase: boolean
  onChangeCheckCountry: () => void
  onChangeCheckShowCase: () => void
  inputLabel?: string
}

export class EncodingInboundSapHeader extends Component<Props> {
  render() {
    const {
      onChange,
      onEnter,
      umgResetCounter,
      umgVerified,
      umgVerifying,
      checkCountry,
      checkShowCase,
      onChangeCheckCountry,
      onChangeCheckShowCase,
      inputLabel,
    } = this.props
    return (
      <Box vcenter mb={15}>
        <Box flex pr={2}>
          <Input
            label={inputLabel || __(T.misc.parcel)}
            onChange={(txt) => onChange(txt)}
            onEnter={(txt) => (onEnter ? onEnter(txt) : {})}
            customIcon={<Icons.Barcode width={40} height={40} />}
            showLoading={umgVerifying}
            resetCounter={umgResetCounter}
            disabled={umgVerified}
            forceClear
            autoFocus
          />
        </Box>
        <Box center row mt={10}>
          <ToggleField
            label={__(T.misc.country_check)}
            onChange={() => onChangeCheckCountry()}
            checked={checkCountry}
            mr={10}
          />
          <ToggleField
            label={__(T.misc.showcase_check)}
            onChange={() => onChangeCheckShowCase()}
            checked={checkShowCase}
          />
        </Box>
      </Box>
    )
  }
}
