import { api, TmrBaseResource, responseErrorCheck } from 'stylewhere/api'

export class TransferToZone extends TmrBaseResource {
  static endpoint = 'api/v1/operations/transfersToZone' //tracking/

  static batchValidateEndpoint() {
    return `${this.endpoint}/batchValidate`
  }

  static confirm(data, async = false, requestConfig = undefined) {
    return api.post(`${this.endpoint}${async ? '/asyncExecution' : ''}`, data, requestConfig).then(responseErrorCheck)
  }

  static async getById(id: string, requestConfig?) {
    const result = await api.get(`${this.endpoint}/${id}`, requestConfig)
    return responseErrorCheck(result)
  }
}
