import { api, TmrBaseResource, responseErrorCheck } from 'stylewhere/api'

export class ApiTrm3 extends TmrBaseResource {
  static endpoint = 'valentino/integration/v1/tmr3/' //edge

  static async getQcStatus(epc: string) {
    let settings: any = {}
    const params = {}
    try {
      settings = await api.get<any>(`${this.endpoint}qcStatus/${epc}`, params).then(responseErrorCheck)
    } catch (e) {}
    return settings
  }
}
