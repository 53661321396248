import styled from '@emotion/styled'
import { Box, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  detected: number
  expected?: number
  unexpected?: number
  error?: boolean
  onPress?: () => void
  onPressSmallCounter?: () => void
  title?: string
  layout?: any
  margin?: string
  smallCounter?: { detected: number; expected: number }
  smallCounterLabel?: string
  showExpected?: boolean
}

export function TagCounter({
  unexpected,
  error,
  detected,
  expected,
  onPress,
  title,
  layout,
  margin,
  smallCounter,
  onPressSmallCounter,
  smallCounterLabel,
  showExpected = true,
}: Props) {
  let fontSize: string | undefined
  const countersLength = expected ? String(expected).length + String(detected).length : String(detected).length
  if (countersLength > 5) fontSize = '80px'
  if (countersLength > 7) fontSize = '70px'
  return (
    <>
      <Container
        detected={detected}
        error={error}
        unexpected={unexpected}
        expected={expected}
        center
        flex={1}
        layout={layout}
        style={{ margin: margin || 0 }}
      >
        <Box flex center mt={15}>
          <SubTitle
            fontSize={layout && layout.sizeText ? layout.sizeText : '22px'}
            lineHeight={layout && layout.lineHeightText ? layout.lineHeightText : '26px'}
            color={layout && layout.textColor ? layout.textColor : '#454545'}
          >
            {title || __(T.misc.total_reads)}
          </SubTitle>
          <Box onClick={onPress ? onPress : () => {}}>
            <Counter
              fontSize={layout && layout.size ? layout.size : fontSize}
              lineHeight={layout && layout.interline ? layout.interline : '124px'}
            >
              {detected}
              {!!expected && showExpected && `/${expected}`}
            </Counter>
          </Box>
        </Box>
        {smallCounter && (
          <Box center mb={15} mt={15} onClick={onPressSmallCounter ? onPressSmallCounter : () => {}}>
            {smallCounterLabel && <span style={{ marginBottom: 5 }}>{smallCounterLabel}</span>}
            <SmallCounter>
              {smallCounter.detected} / {smallCounter.expected}
            </SmallCounter>
          </Box>
        )}
      </Container>
      {(!layout || (layout && !layout.disableSpacer)) && (
        <Spacer
          width={layout && layout.spacerWidth ? layout.spacerWidth : 20}
          height={layout && layout.spacerHeight ? layout.spacerHeight : 20}
        />
      )}
    </>
  )
}

const Counter = styled.div<{ fontSize?: string; lineHeight?: string }>`
  font-size: ${({ fontSize }) => {
    return fontSize ?? '100px'
  }};
  font-weight: bold;
  line-height: ${({ lineHeight }) => {
    return lineHeight ?? '124px'
  }};
`

const SmallCounter = styled.div`
  background-color: #eaeaea;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 26px;
`

const Container = styled(Box)<{
  error?: boolean
  detected: number
  expected?: number
  unexpected?: number
  layout?: any
}>`
  min-height: ${({ layout }) => {
    return layout && layout.minHeight ? layout.minHeight : '220px'
  }};
  ${({ layout }) =>
    layout &&
    layout.height !== undefined &&
    `height: ${typeof layout.height === 'number' ? `${layout.height}px` : layout.height};`}
  ${({ layout }) =>
    layout &&
    layout.height !== undefined &&
    `max-height: ${typeof layout.height === 'number' ? `${layout.height}px` : layout.height};`}
  ${({ layout }) =>
    layout &&
    layout.height !== undefined &&
    `min-height: ${typeof layout.height === 'number' ? `${layout.height}px` : layout.height};`}
  min-width: ${({ layout }) => {
    return layout && layout.minWidth ? layout.minWidth : 'unset'
  }};
  margin-top: ${({ layout }) => {
    return layout && layout.mTop ? layout.mTop : '0px'
  }};
  padding: ${({ layout }) => {
    return layout && layout.padding ? layout.padding : '0px'
  }};
  border-radius: ${({ layout }) => {
    return layout && layout.radius ? layout.radius : '15px'
  }};
  background-color: ${({ error, detected, expected, unexpected, layout }) => {
    if (layout && layout.bgColor) return layout.bgColor
    if (error) return '#FF8474'
    if (expected) {
      if (detected > expected || !!unexpected) {
        return '#F2B57D'
      }
      if (detected < expected) {
        return '#FFFFFF'
      }
      return '#75EBA8'
    }

    return '#FFFFFF'
  }};
`

const SubTitle = styled.div<{ fontSize?: string; lineHeight?: string; color?: string }>`
  font-weight: 400;
  text-align: center;
  height: 28px;
  width: 100%;
  text-align: center;
  color: ${({ color }) => {
    return color ?? '#454545'
  }};
  font-size: ${({ fontSize }) => {
    return fontSize ?? '22px'
  }};
  line-height: ${({ lineHeight }) => {
    return lineHeight ?? '26px'
  }};
`
