import { Component } from 'react'
import { Box, Button, Icons, Input, List, Page, ShippingRow, ShippingByAsnStartInput } from 'stylewhere/components'
import { Router, ShippingOperationConfig, FormSchemaData } from 'stylewhere/shared'
import { Asn, AsnStatus, Shippings } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { showToastError } from 'stylewhere/shared/utils'

export interface Props {
  operation: ShippingOperationConfig
  onSelectAsnCode: (asn: Asn) => void
  onCreateAsn: (formData: FormSchemaData) => void
  onClickReadParcel: () => void
}

interface State {
  shipments?: Asn[]
  search?: string
  loading: boolean
  createAsn: boolean
}

export const getShippingOperationalStatuses = (operationConfig: ShippingOperationConfig): AsnStatus[] => {
  if (operationConfig.type === 'INBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_INBOUND', 'INBOUND_ERROR']
  if (operationConfig.type === 'OUTBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_OUTBOUND', 'INBOUND_ERROR']
  return []
}

const formSchema = [
  {
    icon: 'Barcode',
    name: 'shipmentCode',
    type: 'text',
    label: {
      en: 'Shipment Code',
      it: 'Codice Spedizione',
    },
    required: true,
    autoFocus: true,
    submitOnEnter: true,
  },
]

export class ShippingByAsnStartListAndInput extends Component<Props> {
  state: State = {
    shipments: undefined,
    search: undefined,
    loading: true,
    createAsn: false,
  }

  isModal = false

  operation: ShippingOperationConfig

  isWithInput: boolean

  constructor(props: Props) {
    super(props)
    this.operation = props.operation
    this.isWithInput = props.operation?.startAsnScreen === 'asnListAndInputCode'
  }

  onRowClick = (asn: Asn) => this.props.onSelectAsnCode(asn)

  updateShipments = async (search?: string) => {
    const { operation } = this.props
    this.setState({ loading: true })

    let shipments: Asn[] = []
    try {
      shipments = (
        await Shippings.getAsnList(
          operation,
          100, // FIXME - impostato fisso per il momento
          0, // FIXME
          search,
          getShippingOperationalStatuses(operation),
          operation.reverseAsnOrder ? 'asc' : 'desc'
        )
      ).content
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }

    this.setState({ shipments, search, loading: false })
  }

  async componentDidMount() {
    await this.updateShipments()
  }

  shipmentRender = (shipping: Asn) => {
    return <ShippingRow shipping={shipping} onClick={() => this.onRowClick(shipping)} />
  }

  managerCreateAsn = () => {
    this.setState({ createAsn: !this.state.createAsn })
  }

  render() {
    const { shipments, loading, createAsn } = this.state
    const { operation, onCreateAsn } = this.props
    const operationType = operation.type.toLowerCase()
    return (
      <Page
        title={`${operation.description ?? operationType}`}
        onBackPress={() => {
          createAsn ? this.managerCreateAsn() : Router.navigate('/')
        }}
      >
        {!createAsn ? (
          <Page.Content notBoxed>
            <Box p={5} pb={0} mb={40} row justify={'space-between'}>
              <Input
                onChange={this.updateShipments}
                placeholder={`${__(T.misc.shipment)}`}
                image={<Icons.Barcode />}
                style={{ width: 325 }}
                autoFocus
                debounce={300}
              />
              {!this.isWithInput && <Box />}
              {this.isWithInput && <Button title={`${__(T.misc.new_shipment)}`} onClick={this.managerCreateAsn} />}
            </Box>
            <List
              title={`${__(T.titles.shipments)} (${shipments?.length ?? 0})`}
              loading={loading}
              data={shipments}
              rowRender={this.shipmentRender}
            />
            {this.operation.startWithRfidSearch && (
              <Box p={5} pb={0} mt={40} row justify={'flex-end'}>
                <Button title={__(T.misc.read_parcel)} onClick={this.props.onClickReadParcel} />
              </Box>
            )}
          </Page.Content>
        ) : (
          <ShippingByAsnStartInput operation={this.operation} onCreateAsn={onCreateAsn} />
        )}
      </Page>
    )
  }
}
