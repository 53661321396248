import { api, responseErrorCheck, Template, TmrBaseResource } from 'stylewhere/api'

export class Templates extends TmrBaseResource {
  static endpoint = 'api/v1/templates'

  static async getByCode(templateCode: string) {
    const res = await api.get<Template>(`${this.endpoint}/code/${templateCode}`)
    return responseErrorCheck(res)
  }
}
