import { Component } from 'react'
import { T, __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'
import { Page, Box, Icons, Text, Button, FormSchemaFormField } from 'stylewhere/components'
import { Auth, TmrPlace, AttributeMappings, TmrSimpleAttributesMap } from 'stylewhere/api'
import { AppStore } from 'stylewhere/shared'
import { get as _get, set as _set } from 'lodash'
import { checkSelfUserAttribute } from 'stylewhere/utils'

interface Props {
  onConfirmUserInfo: (places: TmrPlace[], attributes: any) => void
}

interface State {
  placesSelected: TmrPlace[]
  attributes: any
  userAttributes: any[]
  policy?: string
}

export class DashboardUserNoPlace extends Component<Props, State> {
  state: State = {
    placesSelected: [],
    attributes: {},
    userAttributes: [],
    policy: undefined,
  }

  componentDidMount() {
    this._init()
  }

  _init = async () => {
    const policySetting = AppStore.getUserSelfInitializationPolicy()
    const attrs: any[] = await this.getUserAttributes(policySetting)

    const attrsValue = {}
    for (let a = 0; a < attrs.length; a++) {
      attrsValue[attrs[a].code] = ''
    }
    this.setState({
      policy: policySetting,
      userAttributes: attrs,
      attributes: attrsValue,
    })
  }

  getUserAttributes = async (policySetting) => {
    const attrs: any[] = []
    if (policySetting === 'all' || policySetting === 'attributesOnly') {
      try {
        const userAttribute = await AttributeMappings.search<TmrSimpleAttributesMap>({ entity: 'user', size: 20 })
        if (userAttribute && userAttribute.content) {
          for (let r = 0; r < userAttribute.content.length; r++) {
            if (checkSelfUserAttribute(userAttribute.content[r].code)) {
              attrs.push({
                code: userAttribute.content[r].code,
                label: userAttribute.content[r].code,
              })
            }
          }
        }
      } catch (e) {}
    }
    return attrs
  }

  selectPlace = (pl?: TmrPlace[]) => {
    this.setState({ placesSelected: pl || [] })
  }

  changeAttribute = (value, field) => {
    const attrs = this.state.attributes
    _set(attrs, field, value)
    this.setState({ attributes: attrs })
  }

  onConfirm = () => {
    const { placesSelected, attributes } = this.state
    this.props.onConfirmUserInfo(placesSelected, attributes)
  }

  getUser = () => {
    const user = AppStore.loggedUser
    if (!user) return '---'
    else if (user.name && user.surname) return user.name + ' ' + user.surname
    else return user.username
  }

  checkAttributes = () => {
    const { attributes } = this.state
    let check = true
    const keys = Object.keys(attributes)
    let k = 0
    while (k < keys.length && check) {
      check = attributes[keys[k]] !== ''
      k++
    }
    return check
  }

  isDisabled = () => {
    const { placesSelected, policy } = this.state
    if (policy) {
      if (policy === 'all') {
        return placesSelected.length === 0 || !this.checkAttributes()
      } else if (policy === 'placesOnly') {
        return placesSelected.length === 0
      } else if (policy === 'attributesOnly') {
        return !this.checkAttributes()
      }
    }
    return true
  }

  onLogout = async () => {
    await Auth.logout()
  }

  render() {
    const { placesSelected, attributes, userAttributes, policy } = this.state
    return (
      <Page title="Dashboard" header={null}>
        <DashView>
          <Box flex center>
            <Icons.RfidStation width={380} height={100} />
            <Box mt={30} mb={30} flex center width="60%" bg={'#FFF'} pr={20} pl={20} pt={30} pb={30} borderRadius={10}>
              <Text style={{ textAlign: 'center' }} fontSize={20}>
                {__(T.misc.select_place, {
                  user: this.getUser(),
                })}
              </Text>
              <Box width="90%" mt={20} mb={30}>
                {policy && (
                  <>
                    {(policy === 'placesOnly' || policy === 'all') && (
                      <Box mb={policy === 'all' ? 15 : 0}>
                        <FormSchemaFormField
                          field={{
                            name: 'place',
                            type: 'autocomplete',
                            label: __(T.misc.place),
                            endpoint: 'api/v1/places',
                            required: true,
                            multiple: true,
                          }}
                          index={0}
                          value={placesSelected.length > 0 ? placesSelected : undefined}
                          defaultValue={undefined}
                          onChange={(v) => this.selectPlace(v)}
                        />
                      </Box>
                    )}
                    {userAttributes.map((attr, index) => {
                      return (
                        <Box key={'attrs_' + index} mt={index > 0 ? 15 : 0}>
                          <FormSchemaFormField
                            field={{
                              name: attr.code,
                              type: 'text',
                              label: attr.label,
                              required: true,
                            }}
                            index={index + 1}
                            value={attributes[attr.code]}
                            defaultValue={undefined}
                            onChange={(v) => this.changeAttribute(v, attr.code)}
                          />
                        </Box>
                      )
                    })}
                  </>
                )}
                {!policy && (
                  <Box flex center vcenter>
                    <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
                  </Box>
                )}
              </Box>
              <Button disabled={this.isDisabled()} variant="primary" type="button" onClick={() => this.onConfirm()}>
                {__(T.misc.confirm_continue)}
              </Button>
            </Box>
            <Button variant="secondary" type="button" onClick={() => this.onLogout()}>
              {__(T.misc.logout)}
            </Button>
          </Box>
        </DashView>
      </Page>
    )
  }
}

const DashView = styled(Box)`
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 15px 0px;
`
