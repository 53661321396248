import { Component } from 'react'
import {
  Page,
  CountTBoxUbicazioniHeader,
  CountTBoxCounter,
  CountTBoxItems,
  Box,
  UmgModal,
  FullLoadingLayer,
  UnknownItems,
  Stoplight,
} from 'stylewhere/components'
import {
  Router,
  RemoteOperation,
  SessionItemSessionListOperationConfig,
  OperationReadingProps,
  OperationReadingState,
  RfidReader,
  OperationReadingProvider,
  getDataFromSchema,
  AppStore,
} from 'stylewhere/shared'
import { config } from 'stylewhere/config'
import { SimpleItemSessionListExtensions } from 'stylewhere/extensions'
import { DecodedItem, SimpleItemSessionList, SimpleItemSessions, Places, TmrPlace, ApiTrm3 } from 'stylewhere/api'
import {
  askUserConfirmation,
  showToast,
  showToastError,
  checkQcStatus,
  isQcStatusProduct,
  checkItemDecodedStatuses,
  showSessionWarning,
  blockList,
  RETRY_SSE_TIME,
  hasAsyncConfirm,
  getSseEndpoint,
} from 'stylewhere/utils'
import { T, __, __UP } from 'stylewhere/i18n'

interface State extends OperationReadingState {
  sessionListId: string
  unknown: number
  unexpectedItems: DecodedItem[]
  itemsMission: DecodedItem[]
  cqCount: any
  cqPercentage: any
  cqCountMission: any
  cqPercentageMission: any
  detected: number
  expected: number
  umg: string
  umgResetCounter: number
  umgVerifying: boolean
  umgVerified: boolean
  hasCqCounterPartial: number
  alreadyWorkedUmg: string[]
  checkCountry: boolean
  checkShowCase: boolean
  sessionId: string
  unknownMission: number
  totalCqTotalCounter: number
  umgModalVisible: boolean
  reloadUmgsCounter: number
  processing: boolean
  pendingEpcs: string[]
  viewUnexpectedItems: boolean
  disabledUmgConfirm: boolean
  lastAsyncID: string
  lastAsyncType: string
  sseStatus: boolean
  retrySseCount: number
}

export default class CountTBoxUbicazioniReading extends Component<OperationReadingProps, State> {
  qcStatusEpcs: any[] = []
  cqCounter = 0
  attachTimerUmg = false
  timer?: any
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<OperationReadingState>(this.props)
  operation = RemoteOperation.getOperationConfig<SessionItemSessionListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleItemSessionListExtensions.formSchema(this.operation)
  sse: any

  state = {
    sessionListId: '',
    items: [],
    unknown: 0,
    itemsMission: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    umg: '',
    umgResetCounter: 0,
    umgVerified: false,
    umgVerifying: false,
    cqCount: 0,
    cqPercentage: 0,
    cqCountMission: 0,
    cqPercentageMission: 0,
    detected: 0,
    hasCqCounterPartial: 0,
    alreadyWorkedUmg: [],
    checkCountry: true,
    checkShowCase: true,
    sessionId: '',
    totalCqTotalCounter: 0,
    umgModalVisible: false,
    unknownMission: 0,
    reloadUmgsCounter: 0,
    processing: false,
    pendingEpcs: [],
    unexpectedItems: [],
    viewUnexpectedItems: false,
    disabledUmgConfirm: false,
    lastAsyncID: '',
    lastAsyncType: '',
    sseStatus: false,
    retrySseCount: 0,
  } as any

  async componentDidMount() {
    if (!this.locationState.formData || !this.locationState.formData.sessionListId) {
      this.goBack()
      return
    }

    const { formData } = this.state
    const supplier = await this.getPlaces(formData.supplier || '')
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
    this.setState(
      {
        sessionListId: this.locationState.formData.sessionListId,
        expected: parseInt(formData.quantity || 0, 10),
        detected: parseInt(formData.acceptedQuantity || 0, 10),
        unknownMission: parseInt(formData.unknownItems || 0, 10),
        cqCountMission: this.getCqCountMission(formData.totalQcItems || 0),
        cqPercentageMission: this.getCqPercentageMission(formData.totalQcItems || 0, formData.totalItems || 0),
        sseStatus: !hasAsyncConfirm(this.operation),
        formData: {
          ...formData,
          supplier: supplier,
        },
      },
      this.attachSse
    )
  }

  hasPdfActions = () => {
    const actions = this.operation.actions || []
    const actionExists = actions.findIndex((t) => t.actionCode === 'pdfMissionPrint')
    return actionExists >= 0
  }

  componentWillUnmount = () => {
    this.destroySse()
  }

  attachSse = async () => {
    if (!hasAsyncConfirm(this.operation) || !AppStore.loggedUser) {
      return
    }
    const sseUrl = getSseEndpoint(config)
    this.sse = new EventSource(sseUrl, { withCredentials: false })
    this.sse.onerror = () => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }
    this.sse.onopen = (event) => {
      this.setState({ sseStatus: true, retrySseCount: 0 })
    }
    this.sse.onmessage = (event) => {
      this.sseEventMessage(event)
    }
  }

  retrySse = () => {
    this.destroySse()
    if (this.state.retrySseCount === 1) {
      this.attachSse()
    } else {
      setTimeout(() => {
        this.attachSse()
      }, RETRY_SSE_TIME)
    }
  }

  destroySse = () => {
    if (this.sse) {
      this.sse.close()
      this.sse = undefined
    }
  }

  sseEventMessage = async (event) => {
    const { lastAsyncID, lastAsyncType } = this.state
    const eventData = event.data ? JSON.parse(event.data) : undefined
    if (eventData && eventData.data) {
      if (eventData.data.asyncExecutionId === lastAsyncID) {
        if (eventData.data.executionStatus === 'OK') {
          if (lastAsyncType === 'session') {
            const res = await SimpleItemSessions.getById(eventData.data.details.operationInstanceId)
            if (res) {
              this._callbackConfirmSession(res)
            } else {
              showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
              this.setState({ processing: false })
            }
          } else {
            this._callbackConfirmList({ id: eventData.data.asyncExecutionId })
          }
        } else {
          showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
          this.setState({ processing: false })
        }
      }
    }
  }

  canDelete = () => {
    const sessionRework = this.operation.options.sessionRework || 'no'
    const sessionUpdatePolicy = this.operation.options.sessionUpdatePolicy || 'error'
    return (
      (sessionUpdatePolicy === 'override' || sessionUpdatePolicy === 'keepPreviousVersions') && sessionRework == 'yes'
    )
  }

  getPlaces = async (supplier) => {
    if (supplier && supplier != '') {
      const result = await Places.search<TmrPlace>({ equalCodes: supplier, type: 'SUPPLIER' }) //SUPPLIER_
      if (result && result.content && result.content.length === 1) {
        return result.content[0].description || supplier
      }
    }
    return supplier
  }

  getDecodeRequest = () => {
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    return {
      url: SimpleItemSessionList.batchValidateEndpoint(),
      payload: decodePayload,
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const { pendingEpcs } = this.state
    const tmp: DecodedItem[] = this.state.items
    const unexpectedTmp: DecodedItem[] = this.state.unexpectedItems
    let tmpUnknown = this.state.unknown
    const epcs = Object.keys(itemMapFromReader)
    let element
    for (let e = 0; e < epcs.length; e++) {
      element = itemMapFromReader[epcs[e]]
      if (element.item) {
        const itemExists = tmp.find((t) => t.item?.id === itemMapFromReader[epcs[e]].item?.id)
        if (!itemExists) {
          const hasQcStatus = checkQcStatus() ? element.item.attributes && element.item.attributes.qcStatus : true
          if (!hasQcStatus) {
            this.qcStatusEpcs.push({ epc: epcs[e], processing: false })
            if (!element.item.attributes) element.item.attributes = {}
            element.item.attributes.qcStatus = 'LOADING'
          }

          if (!element.identifierCode) {
            element.identifierCode = epcs[e]
          }

          if (checkItemDecodedStatuses(element.statuses || [], ['OK', 'SGTIN_FALLBACK_ITEM_CREATED'])) {
            if (isQcStatusProduct(element.item)) {
              this.cqCounter++
            }
            pendingEpcs.push(epcs[e])
            tmp.push(element)
          } else {
            pendingEpcs.push(epcs[e])
            unexpectedTmp.push(element)
            tmpUnknown++
          }
        }
      } else {
        pendingEpcs.push(epcs[e])
        element.identifierCode = epcs[e]
        unexpectedTmp.push(element)
        tmpUnknown++
      }
    }
    this.setState(
      {
        items: tmp,
        disabledUmgConfirm: this.hasQcStatusItemToCall(),
        unexpectedItems: unexpectedTmp,
        unknown: tmpUnknown,
        cqCount: this.getCqCount(this.cqCounter),
        cqPercentage: this.getCqPercentage(this.cqCounter, tmp.length, tmpUnknown),
        hasCqCounterPartial: this.cqCounter,
        pendingEpcs: pendingEpcs,
      },
      this.verifyGetQcStatus
    )
  }

  hasQcStatusItemToCall = () => {
    return this.qcStatusEpcs.filter((t) => !t.processing).length > 0
  }

  verifyGetQcStatus = () => {
    if (this.qcStatusEpcs.length > 0) {
      const filters = this.qcStatusEpcs.filter((t) => !t.processing)
      if (filters.length > 0) {
        filters.map((el) => {
          el.processing = true
          this.callQcStatusItem(el)
        })
      }
    }
  }

  callQcStatusItem = (element) => {
    this.getQcStatusItem(element)
  }

  getQcStatusItem = async (element) => {
    const { unknown } = this.state
    const tmp = this.state.items
    try {
      const result = await ApiTrm3.getQcStatus(element.epc)
      if (result && result.epc && result.qcStatus) {
        const itemIndex = tmp.findIndex((el) => el.identifierCode === element.epc)
        if (
          itemIndex >= 0 &&
          tmp[itemIndex].item &&
          tmp[itemIndex].item.attributes &&
          tmp[itemIndex].item.attributes.qcStatus &&
          tmp[itemIndex].item.attributes.qcStatus === 'LOADING'
        ) {
          tmp[itemIndex].item.attributes.qcStatus = result.qcStatus
          if (isQcStatusProduct(tmp[itemIndex].item)) {
            this.cqCounter++
          }
          this.setState({
            items: tmp,
            disabledUmgConfirm: this.hasQcStatusItemToCall(),
            cqCount: this.getCqCount(this.cqCounter),
            cqPercentage: this.getCqPercentage(this.cqCounter, tmp.length, unknown),
            hasCqCounterPartial: this.cqCounter,
          })
        }
      } else {
        this.setUnkwnowQcStatus(element)
      }
    } catch (e) {
      this.setUnkwnowQcStatus(element)
    }
  }

  setUnkwnowQcStatus = (element) => {
    const tmp = this.state.items
    const itemIndex = tmp.findIndex((el) => el.identifierCode === element.epc)
    if (
      itemIndex >= 0 &&
      tmp[itemIndex].item &&
      tmp[itemIndex].item.attributes &&
      tmp[itemIndex].item.attributes.qcStatus &&
      tmp[itemIndex].item.attributes.qcStatus === 'LOADING'
    ) {
      tmp[itemIndex].item.attributes.qcStatus = undefined
      this.setState({
        items: tmp,
        disabledUmgConfirm: this.hasQcStatusItemToCall(),
      })
    }
  }

  getCqCount = (cqCouterPartial) => {
    return cqCouterPartial
  }

  getCqPercentage = (cqCouterPartial, ok, unknown) => {
    return cqCouterPartial > 0 ? this.formatNumber((cqCouterPartial / (ok + unknown)) * 100) : 0
  }

  goBack = () => {
    if (this.formSchema.length) {
      if (this.operation.templatePath && this.operation.templatePath === 'countTBoxUbicazioni')
        Router.navigate('/simple-item-session-list/countTBoxUbicazioni/:opCode', {
          opCode: this.operation.code,
        })
      else Router.navigate('/simple-item-session-list/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  changeUmg = (txt) => {
    if (txt !== this.state.umg && !this.state.umgVerifying) {
      if (RfidReader.isReading()) {
        RfidReader.stop()
      }
      this.cqCounter = 0
      this.setState(
        {
          umg: txt,
          umgVerified: false,
          sessionId: '',
          items: [],
          unknown: 0,
          cqCount: 0,
          cqPercentage: 0,
        },
        this.umgInputStartTimer
      )
    }
  }

  umgInputStartTimer = () => {
    const { umg } = this.state
    this.umgInputStopTimer()
    if (umg !== '') {
      if (this.attachTimerUmg) {
        this.timer = setTimeout(() => {
          this.doVerifyUmg()
        }, 500)
      }
    } else {
      this.setState({ umgVerifying: false, umgVerified: false })
    }
  }

  doVerifyUmg = () => {
    if (this.state.umg === '') {
      return
    }
    this.setState(
      {
        umgVerifying: true,
      },
      this.verifyUmg
    )
  }

  umgInputStopTimer = () => {
    if (!this.timer) return
    clearTimeout(this.timer)
    this.timer = undefined
  }

  verifyUmg = async () => {
    const { umg, checkCountry, checkShowCase, reloadUmgsCounter } = this.state
    //create session
    try {
      const result = await SimpleItemSessionList.create_session(
        this.locationState.formData.sessionListId,
        umg,
        '', //description
        { checkCountry: checkCountry, checkShowcases: checkShowCase }
      )
      if (result && result.id) {
        if (!RfidReader.isReading()) {
          RfidReader.start()
        }
        this.setState({
          umgVerifying: false,
          umgVerified: true,
          sessionId: result.id,
          reloadUmgsCounter: reloadUmgsCounter + 1,
        })
      } else {
        showToastError(__(T.error.item_session_list_create_session), __(T.error.error), true)
        this.setState({
          umgVerifying: false,
        })
      }
    } catch (error) {
      this.setState({
        umg: '',
        umgResetCounter: this.state.umgResetCounter + 1,
        umgVerified: false,
        umgVerifying: false,
      })
      showToastError(error, __(T.error.error), true)
    }
  }

  _confirm = async () => {
    if (RfidReader.isReading()) {
      RfidReader.stop()
    }

    const { formData, hasCqCounterPartial, umg, alreadyWorkedUmg } = this.state
    // gestire invio già eseguito per umg
    const showSessionWarningIfNoQc =
      this.operation.options.showSessionWarningIfNoQc && this.operation.options.showSessionWarningIfNoQc === 'true'
    if (showSessionWarning(formData.stock_type, hasCqCounterPartial, showSessionWarningIfNoQc)) {
      const ok = await askUserConfirmation(
        __(T.misc.add_partial),
        alreadyWorkedUmg[umg] ? __(T.misc.add_partial_already_text) : __(T.misc.add_partial_text)
      )
      if (ok) {
        this.setState({ processing: true }, this._addPartialToFinal)
      }
    } else {
      this.setState({ processing: true }, this._addPartialToFinal)
    }
  }

  formatNumber = (number, fixed = 2) => {
    return parseFloat(number).toFixed(fixed)
  }

  getCqCountMission = (cqTotalCounter) => {
    return cqTotalCounter
  }

  getCqPercentageMission = (cqTotalCounter, quantity) => {
    return cqTotalCounter > 0 ? this.formatNumber((cqTotalCounter / quantity) * 100) : 0
  }

  getPartialCqStatus = (items) => {
    let cqCount = 0
    for (let i = 0; i < items.length; i++) {
      if (isQcStatusProduct(items[i].item)) {
        cqCount++
      }
    }
    return cqCount
  }

  _addPartialToFinal = async () => {
    const { items, sessionId, unknown } = this.state
    const asyncJobs = hasAsyncConfirm(this.operation)
    const itemIds: string[] = []
    for (let i = 0; i < items.length; i++) {
      itemIds.push(items[i].item.id)
    }
    try {
      const result: any = await SimpleItemSessionList.confirm_session(
        sessionId,
        itemIds,
        { unknownItems: unknown },
        asyncJobs
      )
      if (asyncJobs) {
        if (result && result.asyncExecutionId) {
          this.setState({ lastAsyncID: result.asyncExecutionId, lastAsyncType: 'session' })
        } else {
          showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
          this.setState({ processing: false })
        }
      } else {
        this._callbackConfirmSession(result)
      }
    } catch (error) {
      showToastError(error, __(T.error.error), true)
      this.setState({ processing: false })
    }
  }

  _callbackConfirmSession = async (result) => {
    const {
      umg,
      alreadyWorkedUmg,
      detected,
      expected,
      items,
      umgResetCounter,
      totalCqTotalCounter,
      itemsMission,
      unknownMission,
      reloadUmgsCounter,
      unknown,
    } = this.state
    const umgSended = alreadyWorkedUmg
    umgSended[umg] = true

    if (result && result.id) {
      let qtAccettata = detected + items.length
      if (result.externalSystemResponse && result.externalSystemResponse.QtAccettata)
        qtAccettata = parseInt(result.externalSystemResponse.QtAccettata + '', 10)

      let qtSconosciuta = unknownMission + unknown
      if (result.sessionList && result.sessionList.attributes && result.sessionList.attributes.unknownItems) {
        qtSconosciuta = parseInt(result.sessionList.attributes.unknownItems + '', 10)
        if (result.externalSystemResponse && result.externalSystemResponse.QtScartata) {
          qtSconosciuta += parseInt(result.externalSystemResponse.QtScartata + '', 10)
        }
      }

      let qtTotale = items.length + expected
      if (result.sessionList && result.sessionList.attributes && result.sessionList.attributes.totalItems) {
        qtTotale = parseInt(result.sessionList.attributes.totalItems, 10)
      }

      let cqTotalCounter = this.getPartialCqStatus(items) + totalCqTotalCounter
      if (result.sessionList && result.sessionList.attributes && result.sessionList.attributes.totalQcItems) {
        cqTotalCounter = parseInt(result.sessionList.attributes.totalQcItems, 10)
      }

      this.cqCounter = 0
      this.setState({
        items: [],
        unexpectedItems: [],
        pendingEpcs: [],
        unknown: 0,
        cqCount: 0,
        cqPercentage: 0,
        umg: '',
        umgResetCounter: umgResetCounter + 1,
        umgVerified: false,
        umgVerifying: false,
        hasCqCounterPartial: 0,
        itemsMission: itemsMission.concat(items),
        cqCountMission: this.getCqCountMission(cqTotalCounter),
        cqPercentageMission: this.getCqPercentageMission(cqTotalCounter, qtTotale),
        alreadyWorkedUmg: umgSended,
        detected: qtAccettata,
        totalCqTotalCounter: cqTotalCounter,
        unknownMission: qtSconosciuta,
        reloadUmgsCounter: reloadUmgsCounter + 1,
        processing: false,
      })
    } else {
      showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
      this.setState({ processing: false })
    }
  }

  _clear = () => {
    const { pendingEpcs } = this.state
    if (RfidReader.isReading()) {
      RfidReader.stop()
    }
    if (pendingEpcs.length > 0) {
      RfidReader.removeTags(pendingEpcs)
    }
    this.qcStatusEpcs = []
    this.cqCounter = 0
    this.setState({
      items: [],
      unexpectedItems: [],
      pendingEpcs: [],
      unknown: 0,
      cqCount: 0,
      cqPercentage: 0,
      hasCqCounterPartial: 0,
    })
  }

  _confirmMission = async () => {
    if (RfidReader.isReading()) {
      RfidReader.stop()
    }
    this.setState({ processing: true }, this._doConfirmMission)
  }

  _doConfirmMission = async () => {
    const { cqCountMission, formData } = this.state
    const blockListIfNoQc = this.operation.options.blockListIfNoQc && this.operation.options.blockListIfNoQc === 'true'
    const asyncJobs = hasAsyncConfirm(this.operation)
    if (blockList(formData.stock_type, cqCountMission, blockListIfNoQc)) {
      showToastError(__(T.misc.error_cq_mission), __(T.error.error), true)
      this.setState({ processing: false })
    } else {
      const ok = await askUserConfirmation(__(T.misc.confirm_mission), __(T.misc.confirm_mission_text))
      if (ok) {
        try {
          const result: any = await SimpleItemSessionList.confirm_list(
            this.operation.id,
            this.locationState.formData.sessionListId,
            {},
            asyncJobs
          )
          if (asyncJobs) {
            if (result && result.asyncExecutionId) {
              this.setState({ lastAsyncID: result.asyncExecutionId, lastAsyncType: 'list' })
            } else {
              showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
              this.setState({ processing: false })
            }
          } else {
            this._callbackConfirmList(result)
          }
        } catch (error) {
          showToastError(error, __(T.error.error), true)
          this.setState({ processing: false })
        }
      } else {
        this.setState({ processing: false })
      }
    }
  }

  _callbackConfirmList = async (result) => {
    if (result && result.id) {
      if (this.hasPdfActions()) {
        await this._printMission()
      }
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.item_session_list_confirm),
        status: 'success',
      })
      this.setState({ processing: false })
      this.goBack()
    } else {
      showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
      this.setState({ processing: false })
    }
  }

  _clearMission = async () => {
    const { reloadUmgsCounter, sessionListId } = this.state
    if (this.canDelete()) {
      const ok = await askUserConfirmation(__(T.custom.deleteList), __(T.custom.deleteListMessage))
      if (ok) {
        try {
          await SimpleItemSessionList.delete_list(sessionListId)
          this.setState({
            detected: 0,
            //expected: 0,
            unknownMission: 0,
            itemsMission: [],
            cqCountMission: 0,
            cqPercentageMission: 0,
            totalCqTotalCounter: 0,
            reloadUmgsCounter: reloadUmgsCounter + 1,
          })
          RfidReader.clear()
        } catch (error) {
          showToastError(error, __(T.error.error), true)
        }
      }
    } else {
      this.setState({
        unknownMission: 0,
        itemsMission: [],
        cqCountMission: 0,
        cqPercentageMission: 0,
        totalCqTotalCounter: 0,
        reloadUmgsCounter: reloadUmgsCounter + 1,
      })
    }
  }

  _export = async (type) => {
    if (type == 1) {
      this.setState({ umgModalVisible: true })
    } else {
      this._printMission()
    }
  }

  _printMission = async () => {
    const { formData, sessionListId } = this.state
    try {
      const rest = await SimpleItemSessionList.executeAction(sessionListId, this.operation.id, 'printMission')
      const file = new Blob([rest as string], { type: 'application/pdf' })
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file)

      const link = document.createElement('a')
      link.href = fileURL
      link.download = (formData.mission || sessionListId) + '.pdf'
      link.click()
    } catch (error) {
      showToastError(error, __(T.error.error), true)
    }
  }

  _exportUmg = (umg) => {
    this.closeUmgModal()
  }

  _removeUmg = async (umg) => {
    this.closeUmgModal()
    const ok = await askUserConfirmation(__(T.custom.deleteSession), __(T.custom.deleteSessionMessage))
    if (ok) {
      try {
        await SimpleItemSessionList.delete_session(umg)
        this.setState({
          reloadUmgsCounter: this.state.reloadUmgsCounter + 1,
        })
        RfidReader.clear()
      } catch (error) {
        showToastError(error, __(T.error.error), true)
      }
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [] })
  }

  _onChangeCheckCountry = () => {
    this.setState({ checkCountry: !this.state.checkCountry })
  }

  _onChangeCheckShowCase = () => {
    this.setState({ checkShowCase: !this.state.checkShowCase })
  }

  closeUmgModal = () => {
    this.setState({ umgModalVisible: false })
  }

  _viewUnexpectedItems = () => {
    this.setState({ viewUnexpectedItems: true })
  }

  _closeUnexpectedItems = () => {
    this.setState({ viewUnexpectedItems: false })
  }

  render() {
    const {
      sessionListId,
      items,
      formData,
      loading,
      unknown,
      itemsMission,
      cqCount,
      cqPercentage,
      cqCountMission,
      cqPercentageMission,
      detected,
      expected,
      umgResetCounter,
      umgVerified,
      umgVerifying,
      checkCountry,
      checkShowCase,
      unknownMission,
      umgModalVisible,
      reloadUmgsCounter,
      processing,
      viewUnexpectedItems,
      unexpectedItems,
      disabledUmgConfirm,
      sseStatus,
    } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        forceDetails="top"
        headerRight={hasAsyncConfirm(this.operation) ? <Stoplight active={sseStatus} /> : undefined}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        enableEmulation
      >
        <Page.Content notBoxed style={{ margin: 0 }}>
          <CountTBoxUbicazioniHeader
            onChange={this.changeUmg}
            onEnter={this.doVerifyUmg}
            umgResetCounter={umgResetCounter}
            umgVerified={umgVerified}
            umgVerifying={umgVerifying}
            detected={detected}
            expected={expected}
            exportPdf={this._export}
            onClear={this.onClear}
            checkCountry={checkCountry}
            onChangeCheckCountry={this._onChangeCheckCountry}
            checkShowCase={checkShowCase}
            onChangeCheckShowCase={this._onChangeCheckShowCase}
            inputLabel={__(T.custom.countTBoxParcel)}
            enabledPrint={this.hasPdfActions()}
          />
          <Box row bg="#FFF">
            <Box flex>
              <CountTBoxCounter
                title={__(T.custom.countTBoxParcel)}
                ok={items.length}
                unknown={unknown}
                isCQ
                cqCount={cqCount}
                cqPercentage={cqPercentage}
                onConfirm={this._confirm}
                onClear={this._clear}
                useUnknown={true}
                unknownClick={this._viewUnexpectedItems}
                disabledConfirm={disabledUmgConfirm}
              />
            </Box>
            <Box flex>
              <CountTBoxCounter
                title={__(T.custom.countTBoxDDT)}
                ok={detected}
                unknown={unknownMission}
                isCQ
                cqCount={cqCountMission}
                cqPercentage={cqPercentageMission}
                onConfirm={this._confirmMission}
                onClear={this._clearMission}
                useUnknown={true}
                unknownTitle={__UP(__(T.error.errors))}
              />
            </Box>
          </Box>
          <Box row flex bg="#FFF">
            <CountTBoxItems style={{ borderRight: '1px solid #f2f2f2' }} items={items} />
            <CountTBoxItems items={itemsMission} />
          </Box>
        </Page.Content>
        {sessionListId !== '' && (
          <UmgModal
            //exportPdf={this._exportUmg}
            onClose={() => this.closeUmgModal()}
            visible={umgModalVisible}
            reloadUmgsCounter={reloadUmgsCounter}
            sessionListId={sessionListId}
            remove={this.canDelete() ? this._removeUmg : undefined}
          />
        )}
        <UnknownItems
          onClose={() => this._closeUnexpectedItems()}
          visible={viewUnexpectedItems}
          unexpectedItems={unexpectedItems}
        />
        {(processing || !sseStatus) && (
          <FullLoadingLayer
            message={!sseStatus ? __(T.messages.connection_in_progress) : __(T.messages.operation_in_progress)}
          />
        )}
      </Page>
    )
  }
}
