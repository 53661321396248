import { Component } from 'react'
import styled from '@emotion/styled'
import { Page, Box, Icons } from 'stylewhere/components'
import { Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/i18n'
import { enabledUtilities } from 'stylewhere/utils'

export default class Utilities extends Component {
  componentDidMount() {
    if (!enabledUtilities()) {
      Router.navigate('/', {})
    }
  }

  getButtons = () => {
    return [
      {
        code: 'write-tag',
        operation: '/utilities/write-tag',
        icon: <Icons.TagBlack style={{ width: 110, height: 100, marginBottom: 10 }} />,
        text: __(T.misc.write_tag),
        displayPriority: -1,
        disableWorkstationCheck: true,
      },
    ]
  }

  onClick = (route, routeParams) => {
    route && Router.navigate(route, routeParams)
  }
  render() {
    const buttons = this.getButtons()
    return (
      <Page title={'Utilities'}>
        <Page.Content notBoxed>
          <Box borderRadius={0} ph={0} pl={0} pr={0} flex row flexWrap width={'calc(100% + 10px)'}>
            {buttons.map(({ operation: route, icon, text, code }) => (
              <ButtonDashboard key={code} onClick={() => this.onClick(route, {})}>
                {icon && typeof icon !== 'string' && icon}
                {text}
              </ButtonDashboard>
            ))}
          </Box>
        </Page.Content>
      </Page>
    )
  }
}

const ButtonDashboard = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  min-width: 276px;
  max-height: 300px;
  flex: 1;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 10px;
  margin: 0 15px 15px 0px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`
