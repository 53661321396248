import { Component } from 'react'
import { OperationStart, FullLoadingLayer } from 'stylewhere/components'
import { SimpleItemSessionListExtensions } from 'stylewhere/extensions'
import { Router, FormSchemaData, FormSchema, OperationConfig, AppStore } from 'stylewhere/shared'
import { SimpleItemSessionList } from 'stylewhere/api'
import {
  showToast,
  showToastError,
  getFormDataDescription,
  getFieldWithAutocomplete,
  checkAutocompleteResult,
} from 'stylewhere/utils'
import { T, __ } from 'stylewhere/shared/i18n'
import { get as _get } from 'lodash'

interface State {
  loading: boolean
  lastFocusOnField: string
  lastResult: any
  formData?: FormSchemaData
  operationCode?: string
  detected: number
}

export default class EncodingInboundSapStart extends Component<State> {
  state = {
    lastFocusOnField: '',
    loading: false,
    lastResult: undefined,
    formData: undefined,
    operationCode: '',
    detected: 0,
  }

  onSubmit = async (formData: FormSchemaData, operation: OperationConfig, schema: FormSchema) => {
    const { lastFocusOnField, lastResult } = this.state
    let sendToReading = true
    const field = getFieldWithAutocomplete(schema)
    if (lastFocusOnField === '' && !lastResult) {
      if (field && formData[field.name] && AppStore.defaultWorkstation) {
        const description = getFormDataDescription(formData)
        this.setState({ loading: true })
        try {
          const result = await SimpleItemSessionList.create_list(
            operation.id,
            formData[field.name],
            description,
            AppStore.defaultWorkstation.placeId
          )
          if (result && result.id) {
            let detected = 0
            if (result.externalSystemResponse) {
              const wam = result.externalSystemResponse.Wam ? result.externalSystemResponse.Wam.item || [] : []
              for (let v = 0; v < wam.length; v++) {
                detected += parseInt(wam[v].Menge ? wam[v].Menge : 0, 10)
              }
            }
            const data = checkAutocompleteResult(field, result, schema, formData)
            if (data.error) {
              this.setState({ loading: false })
              showToast({
                title: __(T.error.error),
                description: __(T.error.item_session_list_create),
                status: 'error',
              })
            } else {
              if (field.focusOnField) {
                this.setState({
                  lastFocusOnField: field.focusOnField,
                  loading: false,
                  lastResult: result,
                  formData: data.formData,
                  operationCode: operation.code,
                  detected: detected,
                })
                document.getElementById(field.focusOnField + '')?.removeAttribute('disabled')
                document.getElementById(field.focusOnField + '')?.focus()
              } else if (field.submitOnEnter) {
                this.setState(
                  { loading: false, formData: data.formData, operationCode: operation.code, detected: detected },
                  this.submitForm
                )
              } else {
                this.setState({ loading: false, lastResult: result })
              }
            }
          } else {
            this.setState({ loading: false })
            showToast({
              title: __(T.error.error),
              description: __(T.error.item_session_list_create),
              status: 'error',
            })
          }
        } catch (error) {
          this.setState({ loading: false })
          showToastError(error)
        }
      }
    } else {
      if (lastFocusOnField !== '') {
        const tmp = schema.find((element) => {
          return element.name === lastFocusOnField
        })
        if (tmp && tmp.focusOnField) {
          sendToReading = false
          this.setState({ lastFocusOnField: tmp.focusOnField })
          document.getElementById(tmp.focusOnField + '')?.removeAttribute('disabled')
          document.getElementById(tmp.focusOnField + '')?.focus()
        }
      }
      if (sendToReading) {
        const data = checkAutocompleteResult(field, lastResult, schema, formData)
        if (data.error) {
          showToast({
            title: __(T.error.error),
            description: __(T.error.item_session_list_create),
            status: 'error',
          })
        } else {
          this.setState({ loading: false, formData: data.formData, operationCode: operation.code }, this.submitForm)
        }
      }
    }
  }

  submitForm = () => {
    const { formData, operationCode, detected } = this.state
    Router.navigate(
      '/simple-item-session-list/encodingInboundSap/:opCode/encode',
      { opCode: operationCode },
      { state: { formData: formData, detected: detected } }
    )
  }

  render() {
    const { loading, formData } = this.state
    return (
      <>
        <OperationStart
          submitPath={'/simple-item-session-list/encodingInboundSap/:opCode/encode'}
          extensions={SimpleItemSessionListExtensions}
          onSubmit={this.onSubmit}
          formDataUpdated={formData}
          formRowField
        />
        {loading && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
      </>
    )
  }
}
