import { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'
import { DecodedItemSorting, SortingGroupRead } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { SortingOperationConfig } from 'stylewhere/shared'
import { getEpc, getSerial } from 'stylewhere/utils'

interface Props {
  operation: SortingOperationConfig
  currentItem: DecodedItemSorting
  currentSortingGroupRead?: SortingGroupRead
}

export class SortingProductInfo extends Component<Props> {
  getFirstValue = () => {
    const { currentItem, currentSortingGroupRead, operation } = this.props
    if (operation.enablePicking) {
      return currentSortingGroupRead &&
        currentSortingGroupRead.attributes &&
        currentSortingGroupRead.attributes.customerName &&
        currentSortingGroupRead.attributes.customerName !== ''
        ? currentSortingGroupRead.attributes.customerName
        : 'n/a'
    }
    return getEpc(currentItem.item)
  }

  getThirdValue = () => {
    const { currentItem, currentSortingGroupRead, operation } = this.props
    if (operation.enablePicking) {
      return currentSortingGroupRead &&
        currentSortingGroupRead.attributes &&
        currentSortingGroupRead.attributes.list &&
        currentSortingGroupRead.attributes.list !== ''
        ? currentSortingGroupRead.attributes.list
        : 'n/a'
    }
    return getSerial(currentItem?.item)
  }

  getFourthValue = () => {
    const { currentItem, currentSortingGroupRead, operation } = this.props
    if (operation.enablePicking) {
      return currentSortingGroupRead &&
        currentSortingGroupRead.attributes &&
        currentSortingGroupRead.attributes.listPosition &&
        currentSortingGroupRead.attributes.listPosition !== ''
        ? currentSortingGroupRead.attributes.listPosition
        : 'n/a'
    }
    return currentItem?.item?.product.attributes?.dimensionsystem?.value ?? 'n/a'
  }

  render() {
    const { currentItem, operation } = this.props
    return (
      <Box flex>
        <ProductInfo flex ph={25} justify="center">
          <ProductInfoLabel>{operation.enablePicking ? __(T.misc.customer) : 'EPC'}</ProductInfoLabel>
          <ProductInfoValue>{this.getFirstValue()}</ProductInfoValue>
        </ProductInfo>
        {currentItem && currentItem.item && (
          <>
            <ProductInfo flex ph={25} justify="center">
              <ProductInfoLabel>{__(T.misc.code)}</ProductInfoLabel>
              <ProductInfoValue>{currentItem?.item?.product.code ?? 'n/a'}</ProductInfoValue>
            </ProductInfo>
            <ProductInfo flex ph={25} justify="center">
              <ProductInfoLabel>{operation.enablePicking ? __(T.misc.list) : __(T.misc.serial)}</ProductInfoLabel>
              <ProductInfoValue>{this.getThirdValue()}</ProductInfoValue>
            </ProductInfo>
            <ProductInfo flex ph={25} justify="center">
              <ProductInfoLabel>
                {operation.enablePicking ? __(T.misc.list_position) : __(T.fields.custom_class)}
              </ProductInfoLabel>
              <ProductInfoValue>{this.getFourthValue()}</ProductInfoValue>
            </ProductInfo>
          </>
        )}
      </Box>
    )
  }
}

const ProductInfo = styled(Box)`
  border-bottom: 1px solid #5dd691;
  &:last-child {
    border: 0;
  }
  overflow: hidden;
`
const ProductInfoLabel = styled(Box)`
  font-size: 14px;
  line-height: 18px;
`
const ProductInfoValue = styled(Box)`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
`
