import { Component } from 'react'
import { Box, FormSchemaForm, Page, Icons } from 'stylewhere/components'
import { ShippingOperationConfig, FormSchema, FormSchemaData } from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { isModalError } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/i18n'

export interface Props {
  operation: ShippingOperationConfig
  onCreateAsn: (formData: FormSchemaData) => void
}

interface State {
  schema?: FormSchema
  isModal: boolean
}

const formSchema = [
  {
    icon: 'Barcode',
    name: 'shipmentCode',
    type: 'text',
    label: {
      en: 'Shipment Code',
      it: 'Codice Spedizione',
    },
    required: true,
    autoFocus: true,
    submitOnEnter: true,
  },
]

export class ShippingByAsnStartInput extends Component<Props, State> {
  state: State = {
    isModal: false,
  }

  componentDidMount() {
    const { operation } = this.props

    let schema = ShippingExtensions.formSchema(operation, undefined, ['asn'])
    if (schema.length === 0) {
      schema = ShippingExtensions.formSchema({ formSchema } as ShippingOperationConfig)
    }
    this.setState({
      schema,
      isModal: isModalError(operation),
    })
  }

  render() {
    const { operation, onCreateAsn } = this.props
    const { schema } = this.state
    return (
      <Page.Content notBoxed>
        <Box flex>
          <Box flex center>
            {schema ? (
              <FormSchemaForm
                schema={schema}
                submitText={__(T.misc.next)}
                onSubmit={(form: any) => {
                  onCreateAsn(form)
                }}
                formContext="start"
                operation={operation}
              />
            ) : (
              <Icons.Loader1 width={100} />
            )}
          </Box>
        </Box>
      </Page.Content>
    )
  }
}
