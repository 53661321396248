import { api, TmrBaseResource, responseErrorCheck } from 'stylewhere/api'

export class Users extends TmrBaseResource {
  static endpoint = 'api/v1/users' //edge/

  static async initUserInfo(userPlaceIds: string[], attributes: any, policy: string) {
    let result = true
    let params = {}
    if (policy === 'all') {
      params = {
        userPlaceIds: userPlaceIds,
        attributes: attributes,
      }
    } else if (policy === 'placesOnly') {
      params = {
        userPlaceIds: userPlaceIds,
      }
    } else if (policy === 'attributesOnly') {
      params = {
        attributes: attributes,
      }
    }

    try {
      await api.put(`${this.endpoint}/self/initUserInfo`, params).then(responseErrorCheck)
    } catch (e) {
      result = false
    }
    return result
  }
}
