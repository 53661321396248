import { Component } from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  spinner?: any
  message?: string
}

export class FullLoadingLayer extends Component<Props> {
  render() {
    const { spinner, message } = this.props
    return (
      <Over>
        <Spinner
          thickness={spinner && spinner.thickness ? spinner.thickness : '5px'}
          speed={spinner && spinner.speed ? spinner.speed : '0.65s'}
          color={spinner && spinner.color ? spinner.color : '#fff'}
          size={spinner && spinner.size ? spinner.size : 'xl'}
        />
        <Message>{message || __(T.misc.loading_data)}</Message>
      </Over>
    )
  }
}

const Over = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Message = styled.label`
  font-weight: normal;
  font-size: 14pt;
  margin-top: 20px;
  color: #fff;
`
