import { Component } from 'react'
import { Asn } from 'stylewhere/api'
import { List, RowProps, StatusChip } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { getDate } from 'stylewhere/shared/utils'

interface Props extends RowProps {
  shipping: Asn
  onClick: () => void
}

export class ShippingRow extends Component<Props> {
  render() {
    const { shipping, onClick, ...rest } = this.props
    return (
      <List.Row onClick={onClick} {...rest}>
        <List.Cell p={5} label={__(T.misc.shipment)} value={shipping.code} />
        <List.Cell p={5} flex={0.5} label={__(T.misc.status)}>
          <StatusChip status={shipping.status || "?"} />
        </List.Cell>
        <List.Cell p={5} flex={1.5} label={__(T.misc.destination)} value={shipping.destinationPlace?.description || "--"} />
        <List.Cell p={5} label={__(T.misc.shipping_date)} value={shipping.shippingDate ? getDate(new Date(shipping.shippingDate).getTime()) : "--"} />
        <List.Cell p={5} label={__(T.misc.parcels)} value={`${shipping.receivedParcels}/${shipping.expectedParcels}`} />
      </List.Row>
    )
  }
}
