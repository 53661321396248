import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Image, OperationReadingList, SmallTagCounter } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { getProductCode, sortGroupeditemList, sortGroupeditemListStatus } from 'stylewhere/shared/utils'
import { AppStore, OperationConfig, OperationReadingProvider } from 'stylewhere/shared'
import { DecodedItem, GroupedItemsByProduct, Products, ShippingParcel, TmrProduct } from 'stylewhere/api'
import { ShippingExtensions } from 'stylewhere/extensions'

interface Props {
  items: GroupedItemsByProduct[]
  operation: OperationConfig
  parcel: ShippingParcel
  viewHeader: boolean
}

export class ParcelRowItemList extends Component<Props> {
  getLabelHeader = (): string => {
    return `${__(T.misc.style)} / ${__(T.misc.material)} / ${__(T.misc.color)} / ${__(T.misc.size)}`
  }

  getLabel = (product): string => {
    const labelArr: string[] = []
    if (product) {
      ;['style', 'material', 'color', 'size'].forEach((key) => {
        let value = '---'
        if (product[key]) {
          value =
            product[key]?.description && product[key].description != null
              ? product[key]?.description
              : product[key]?.value
              ? product[key]?.value
              : '---'
        }
        labelArr.push(value)
      })
    }
    const label = labelArr.join(' / ')
    return label !== '--- / --- / --- / ---' ? label  : '---'
  }

  getCode = (decodedItem) => {
    return decodedItem.identifierCode || undefined
  }

  getIdentifiers = (decodedItem) => {
    const identifiers = decodedItem.item && decodedItem.item.identifiers ? decodedItem.item.identifiers : []
    return identifiers.filter((idf) => idf.code !== decodedItem.identifierCode)
  }

  render() {
    const { items, operation, parcel, viewHeader } = this.props

    const orderedRows = operation?.newReadingsFirst
      ? [...items].reverse()
      : items.sort(sortGroupeditemList).sort(sortGroupeditemListStatus)

    const showImages = AppStore.getShowProductImage()
    const hasChecklist = operation && 'hasChecklist' in operation && operation.hasChecklist

    return (
      <Box flex mb={15}>
        {viewHeader && (
          <IntestazioneRow row ml={0} mr={0}>
            <Intestazione width={160}>
              {
                ''
                // showImages ? __(T.misc.image) : ""
              }
            </Intestazione>
            <Intestazione width={250}>{__(T.misc.code)}</Intestazione>
            <Intestazione width={560}>{this.getLabelHeader()}</Intestazione>
            <Intestazione width={240} noBorder>
              {__(T.misc.only_quantity)}
            </Intestazione>
          </IntestazioneRow>
        )}
        {orderedRows.map((item, index) => {
          const read = item.detected ?? 0 // + (unexpected ?? 0)
          const product = item?.item ? item?.item.product : item.product // item?.item || {}
          // const showDescription = product.code || product.description
          const identifiers = this.getIdentifiers(item)
          const code = this.getCode(item)
          // const showIdentifier = code || identifiers.length > 0

          return (
            <ItemRow key={'item-list-' + index} row ml={0} mr={0} last={index === orderedRows.length - 1}>
              <ItemColumn width={160}>
                {product && showImages && (
                  <Box center pt={8} pb={8} pr={0} pl={10}>
                    <Image width={60} height={60} src={Products.getImageUrl(product)} />
                  </Box>
                )}
              </ItemColumn>
              <ItemColumn width={250}>{getProductCode(product)}</ItemColumn>
              <ItemColumn width={560}>{this.getLabel(product)}</ItemColumn>
              {hasChecklist && (
                <ItemColumn width={240} noBorder>
                  <SmallTagCounter
                    detected={read}
                    expected={item.expected ?? 0}
                    unexpected={item.unexpected ?? 0}
                    alignSelf={'start'}
                  />
                </ItemColumn>
              )}
              {!hasChecklist && (
                <ItemColumn width={240} noBorder>
                  <SmallTagCounter
                    textAlign={'left'}
                    detected={read}
                    expected={item.expected ?? 0}
                    unexpected={item.unexpected ?? 0}
                    alignSelf={'start'}
                  />
                </ItemColumn>
              )}
            </ItemRow>
          )
        })}
      </Box>
    )
  }
}

const IntestazioneRow = styled(Box)`
  border-radius: 10px 10px 0px 0px;
  background-color: #f6f6f6;
  overflow: hidden;
`

const Intestazione = styled(Box)<{ noBorder?: boolean }>`
  height: 65px;
  padding: 0px 15px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  justify-content: center;
  border-right: ${(props) => (props.noBorder ? '0px' : '1px solid #F1F1F1')};
`

const ItemRow = styled(Box)<{ last?: boolean }>`
  border: 1px solid #f1f1f1;
  ${(props) => (props.last ? 'border-radius: 0px 0px 10px 10px' : '')}
`

const ItemColumn = styled(Box)<{ noBorder?: boolean }>`
  height: 65px;
  padding: 0px 15px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  justify-content: center;
  border-right: ${(props) => (props.noBorder ? '0px' : '1px solid #F1F1F1')};
`
