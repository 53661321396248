import { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'

interface Props {
  text: string
  counter: number
  expectedCounter?: number
  counterPrefix?: string
  bg?: string
  secondaryText?: string
  secondaryCount?: number | string
  multiline?: boolean
  ml?: number
  callback?: () => void
}

export class StatusLabelCounter extends Component<Props> {
  render() {
    const {
      text,
      counter,
      callback,
      expectedCounter,
      counterPrefix,
      bg,
      secondaryCount,
      secondaryText,
      multiline,
      ml,
    } = this.props
    return (
      <Counter bg={bg} multiline={multiline} ml={ml}>
        <CounterDiv cursor={callback ? 'pointer' : 'default'} onClick={callback ? callback : undefined}>
          <CounterBox multiline={multiline} mt={0}>
            <Label multiline={multiline}>{text}</Label>
            <Count multiline={multiline}>
              {counter + (expectedCounter ? ' / ' + expectedCounter : '')}
              {counterPrefix || ''}
            </Count>
          </CounterBox>
          {secondaryText && (
            <CounterBox multiline={multiline} mt={10}>
              <Label small>{secondaryText}</Label>
              <Count small>{secondaryCount}</Count>
            </CounterBox>
          )}
        </CounterDiv>
      </Counter>
    )
  }
}

const Counter = styled(Box)<{ bg; multiline; ml }>`
  background-color: ${({ bg }) => bg || '#FFF'};
  color: black;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-left: ${({ ml }) => (ml ? ml + 'px' : '0px')};
  margin-top: ${({ multiline }) => (multiline ? '10px' : '0px')};
`

const CounterDiv = styled.div<{ cursor }>`
  padding: 15px;
  cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`

const CounterBox = styled(Box)<{ multiline; mt? }>`
  display: flex;
  flex-direction: ${({ multiline }) => (multiline ? 'row' : 'column')};
  margin-top: ${({ mt }) => (mt ? mt + 'px' : '10px')};
`

const Label = styled.span<{ multiline?; small? }>`
  font-weight: normal;
  font-size: ${({ multiline, small }) => (multiline ? '14pt' : small ? '12pt' : '12pt')};
  text-align: center;
  line-height: 14pt;
`

const Count = styled.span<{ multiline?; small? }>`
  font-weight: bold;
  font-size: ${({ multiline, small }) => (multiline ? '14pt' : small ? '12pt' : '40pt')};
  text-align: center;
  line-height: ${({ multiline, small }) => (multiline ? '14pt' : small ? '14pt' : '40pt')};
  margin-top: ${({ multiline, small }) => (multiline || small ? '0px' : '10px')};
  margin-left: ${({ multiline, small }) => (multiline || small ? '5px' : '0px')};
`
