import { api, ResourcePage, responseErrorCheck, ShippingParcel, TmrBaseResource } from 'stylewhere/api'
import { SimpleParcelListOperationConfig } from 'stylewhere/shared'

export class SimpleParcelList extends TmrBaseResource {
  static endpoint = 'api/v1/operations/simpleParcelList' //logistics/

  static getParcelListByAsn(
    operation: SimpleParcelListOperationConfig,
    asnCode: string,
    pageSize: number,
    pageIndex: number,
    search?: string,
    order: 'asc' | 'desc' = 'desc',
    dontSendOperationId = false
  ) {
    return api
      .get<ResourcePage<ShippingParcel>>(`${this.endpoint}/parcelList`, {
        operationId: dontSendOperationId ? undefined : operation.id,
        size: pageSize,
        page: pageIndex,
        ascCode: asnCode, // FIXME typo in backend
        search,
        sort: `creationDate,${order}`,
      })
      .then(responseErrorCheck)
  }
}
