import { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { Sortings, Sorting } from 'stylewhere/api'
import { OperationList, SortingRow } from 'stylewhere/components'
import { SortingExtensions } from 'stylewhere/extensions'
import { Routes } from 'stylewhere/pages'
import { RemoteOperation, Router, SortingOperationConfig } from 'stylewhere/shared'

interface State {
  sortings?: Sorting[]
}

export default class SortingList extends Component<RouteComponentProps<{ opCode: string }>, State> {
  state: State = {
    sortings: undefined,
  }
  opCode = Router.getMatchParams(this.props).opCode
  submitPath: Routes = '/sorting/:opCode/start/:code'
  operation = RemoteOperation.getOperationConfig<SortingOperationConfig>(this.opCode)

  //Customization cucinelli
  //constructor(props: any) {
  //   super(props)
  //   if (this.operation.code === 'ventilation-mydress') {
  //     Router.navigate('/sorting/:opCode/start', { opCode: this.opCode })
  //   }
  // }

  async componentDidMount() {
    await this.updateSortings()
  }

  onRowClick = (sorting: Sorting) => {
    //Router.navigate('/sorting/:opCode/start/:code', { opCode: this.opCode, code: sorting.code })
    Router.navigate(
      '/sorting/:opCode/reading',
      { opCode: this.opCode },
      {
        state: {
          formData: {
            sortingId: sorting.id,
            originZoneId: this.operation.enableOriginZone && sorting.originZoneId ? sorting.originZoneId : undefined,
          },
        },
      }
    )
  }

  updateSortings = async (search?: string) => {
    let sortings: Sorting[] = []
    try {
      sortings = (await Sortings.search<Sorting>({ operationIds: this.operation.id, statuses: ['OPEN'], search }))
        .content
    } catch (error) {}

    this.setState({ sortings })
  }

  sortingRender = (sorting: Sorting) => {
    return <SortingRow sorting={sorting} onClick={() => this.onRowClick(sorting)} />
  }

  render() {
    const { sortings } = this.state
    return (
      <OperationList
        onChangeSearch={this.updateSortings}
        data={sortings}
        customRowRender={this.sortingRender}
        operation={this.operation}
        submitPath={this.submitPath}
        extensions={SortingExtensions}
        hideNewButton={!this.operation.showCreateButton}
      />
    )
  }
}
