import { Component } from 'react'
import { T, __UP } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Box, StatusLabelCounter, Button, Icons } from 'stylewhere/components'

interface Props {
  title?: string
  ok: number
  expected?: number
  unknownTitle?: string
  unknown?: number
  useUnknown?: boolean
  cqCount?: number | string
  cqPercentage?: number
  onConfirm: () => void
  onClear?: () => void
  isCQ?: boolean
  disabledConfirm?: boolean
  unknownClick?: () => void
}

interface State {
  starting: boolean
}

export class CountTBoxCounter extends Component<Props, State> {
  state: State = {
    starting: false,
  }

  confirm = () => {
    const { ok, onConfirm } = this.props
    if (onConfirm && ok > 0) {
      onConfirm()
    }
  }

  clear = () => {
    const { ok, onClear } = this.props
    if (onClear && ok > 0) {
      onClear()
    }
  }

  render() {
    const {
      title,
      ok,
      expected,
      unknown,
      unknownTitle,
      isCQ,
      cqCount,
      cqPercentage,
      useUnknown,
      onClear,
      disabledConfirm,
      unknownClick,
    } = this.props
    const activable = ok > 0 && !disabledConfirm
    const cqPerc = cqPercentage || 0
    const unknownCounter = unknown || 0
    return (
      <Container row vcenter>
        <Box flex row width="100%">
          <Box width="155px">
            <Title>{title || ''}</Title>
            {isCQ && (
              <StatusLabelCounter
                text="%CQ:"
                counter={cqPerc}
                counterPrefix="%"
                secondaryText="N:"
                secondaryCount={cqCount || 0}
                multiline
                bg={cqPerc > 0 ? '#74F1A9' : '#F2994A'}
              />
            )}
          </Box>
          <Box row flex>
            <StatusLabelCounter ml={15} text="OK" counter={ok} expectedCounter={expected} />
            {useUnknown && (
              <StatusLabelCounter
                ml={15}
                text={unknownTitle || __UP(T.misc.unknown)}
                counter={unknownCounter}
                bg="#FBBF66"
                callback={unknownCounter > 0 ? unknownClick : undefined}
              />
            )}
            <BoxButton ml={15}>
              <Button
                disabled={!activable}
                variant={activable ? 'primary' : 'default'}
                style={{ width: 54, height: 54 }}
                onClick={() => this.confirm()}
                circle
              >
                <Icons.Confirm width={24} height={24} />
              </Button>
              {onClear && (
                <Button
                  disabled={!activable}
                  variant={'default'}
                  style={{ width: 54, height: 54, marginTop: 5 }}
                  onClick={() => this.clear()}
                  circle
                >
                  <Icons.Clear width={24} height={24} />
                </Button>
              )}
            </BoxButton>
          </Box>
        </Box>
      </Container>
    )
  }
}

const Container = styled(Box)`
  background: #fff;
  padding: 15px 15px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Title = styled.label`
  font-weight: bold;
  font-size: 18pt;
  min-height: 36px;
`

const BoxButton = styled(Box)`
  border: 1px solid #e0e0e0;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`
