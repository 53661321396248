import { Component } from 'react'
import { Box, GroupedItemList } from 'stylewhere/components'
import { DecodedItem } from 'stylewhere/api'

interface Props {
  items: DecodedItem[]
  style?: any
}

export class CountTBoxItems extends Component<Props> {
  render() {
    const { items, style } = this.props
    return (
      <Box flex style={style} pl={15} pr={15} pt={10} pb={10}>
        <GroupedItemList rows={items} disabledStartText={items.length === 0} mb={5} />
      </Box>
    )
  }
}
