import { Component } from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'
import { T, __ } from 'stylewhere/i18n'
import { Box, Modal, Button, Icons } from 'stylewhere/components'
import { SimpleItemSessions } from 'stylewhere/api'
import { format } from 'date-fns'

interface Props {
  onClose: () => void
  visible: boolean
  exportPdf?: (umg) => void
  reloadUmgsCounter: number
  sessionListId: string
  remove?: (umg) => void
}

interface State {
  umgs: any
  loading: boolean
  reloadUmgsCounter: number
}

export class UmgModal extends Component<Props, State> {
  updating = false
  state = {
    umgs: [],
    loading: true,
    reloadUmgsCounter: 0,
  } as any

  componentDidMount() {
    this.setState({ reloadUmgsCounter: this.props.reloadUmgsCounter }, this.getUmgs)
  }

  shouldComponentUpdate = (nextProps) => {
    const { reloadUmgsCounter } = this.state
    if (nextProps.reloadUmgsCounter !== reloadUmgsCounter && !this.updating) {
      this.updating = true
      this.setState({ reloadUmgsCounter: this.props.reloadUmgsCounter, loading: true }, this.getUmgs)
    }
    return true
  }

  getUmgs = async () => {
    const { sessionListId } = this.props
    try {
      const umgs = await SimpleItemSessions.search({
        listIds: [sessionListId],
        statuses: ['OPEN', 'CONFIRMED'],
        size: 10000,
      })
      this.setState({ loading: false, umgs: umgs && umgs.content ? umgs.content : [] })
      this.updating = false
    } catch (error) {
      this.setState({ loading: false })
      this.updating = false
    }
  }

  render() {
    const { onClose, visible, exportPdf, remove } = this.props
    const { loading, umgs } = this.state
    return (
      <Modal size="6xl" onClose={onClose} visible={visible}>
        <Box vcenter row mb={20}>
          <Modal.Title>{__(T.misc.detail)}</Modal.Title>
          {!loading && umgs.length > 0 && (
            <BoxRight>
              <BoxRightLabel mr={'10px'} weight={'700'}>
                {__(T.custom.countTBoxDDT)}
              </BoxRightLabel>
              <BoxRightLabel>{umgs[0].sessionList.code}</BoxRightLabel>
              {umgs[0].sessionList.description && umgs[0].sessionList.description !== '' && (
                <BoxRightLabel>: {umgs[0].sessionList.description}</BoxRightLabel>
              )}
            </BoxRight>
          )}
        </Box>
        {!loading && (
          <>
            <Box flex row>
              <TableHead>{__(T.report_tbox.umg)}</TableHead>
              <TableHead>{__(T.report_tbox.ddt_number)}</TableHead>
              <TableHead>{__(T.report_tbox.creation_date)}</TableHead>
              <TableHead>{__(T.report_tbox.capi_mdc)}</TableHead>
              <TableHead>{__(T.report_tbox.qt_totale)}</TableHead>
              <TableHead>{__(T.report_tbox.qt_accettata)}</TableHead>
              <TableHead>{__(T.report_tbox.qt_scartata)}</TableHead>
              {exportPdf && <TableHead width={50}></TableHead>}
              {remove && <TableHead width={50}></TableHead>}
            </Box>
            {umgs.map((umg, index) => (
              <Box flex row key={'umg-' + index} bb={'1px solid #DCDCDC'}>
                <Label>{umg.code}</Label>
                <Label>{umg.externalSystemResponse ? umg.externalSystemResponse.Ddt : '-'}</Label>
                <Label>{format(new Date(umg.creationDate), 'MM/dd/yyyy')}</Label>
                <Label>{umg.externalSystemResponse ? umg.externalSystemResponse.CapiMdc : 0}</Label>
                <Label>{umg.externalSystemResponse ? parseInt(umg.externalSystemResponse.QtTotale, 10) : 0}</Label>
                <Label>{umg.externalSystemResponse ? parseInt(umg.externalSystemResponse.QtAccettata, 10) : 0}</Label>
                <Label>{umg.externalSystemResponse ? parseInt(umg.externalSystemResponse.QtScartata, 10) : 0}</Label>
                {exportPdf && (
                  <Label width={50}>
                    <Button
                      ml={0}
                      padding={'0px 10px'}
                      variant={'default'}
                      style={{ width: 46, height: 46 }}
                      onClick={() => exportPdf(umg.id)}
                      circle
                    >
                      <Icons.Print width={30} height={30} />
                    </Button>
                  </Label>
                )}
                {remove && (
                  <Label width={50}>
                    <Button
                      ml={0}
                      padding={'0px 10px'}
                      variant={'default'}
                      style={{ width: 46, height: 46 }}
                      onClick={() => remove(umg.id)}
                      circle
                    >
                      <Icons.DeleteBlack width={30} height={30} />
                    </Button>
                  </Label>
                )}
              </Box>
            ))}
          </>
        )}
        {loading && (
          <Box center>
            <Spinner />
            <Text>{__(T.misc.loading)}</Text>
          </Box>
        )}
      </Modal>
    )
  }
}

const TableHead = styled.label<{ width?: string | number }>`
  font-weight: normal;
  font-size: 12pt;
  text-align: center;
  line-height: 14pt;
  flex: 1;
  background-color: #dcdcdc;
  padding: 12px 5px;
  margin-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ width }) => width !== undefined && `width: ${typeof width === 'number' ? `${width}px` : width};`}
  ${({ width }) => width !== undefined && `max-width: ${typeof width === 'number' ? `${width}px` : width};`}
  ${({ width }) => width !== undefined && `min-width: ${typeof width === 'number' ? `${width}px` : width};`}
`

const Label = styled.label<{ width?: string | number }>`
  font-weight: normal;
  font-size: 12pt;
  text-align: center;
  line-height: 14pt;
  flex: 1;
  padding: 10px 5px;
  margin-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  ${({ width }) => width !== undefined && `width: ${typeof width === 'number' ? `${width}px` : width};`}
  ${({ width }) => width !== undefined && `max-width: ${typeof width === 'number' ? `${width}px` : width};`}
  ${({ width }) => width !== undefined && `min-width: ${typeof width === 'number' ? `${width}px` : width};`}
`

const Text = styled.label`
  font-weight: normal;
  font-size: 12pt;
  text-align: center;
  line-height: 14pt;
  margin-top: 15px;
`

const BoxRight = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const BoxRightLabel = styled.label<{ weight?: string; mr?: string; ml?: string }>`
  ${({ weight }) => `font-weight: ${weight ? weight : 'normal'};`}
  ${({ mr }) => `margin-right: ${mr ? mr : '0px'};`}
  ${({ ml }) => `margin-left: ${ml ? ml : '0px'};`}
  font-size: 12pt;
  text-align: center;
  line-height: 14pt;
`
