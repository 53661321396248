import { Component } from 'react'
import { TmrProduct, ProductInfoField, ErrorLogs } from 'stylewhere/api'
import {
  Box,
  Page,
  Text,
  ImbustatriceTagRow,
  ProductInfoBox,
  ImbustatriceRightHeader,
  ErrorLog,
  Button,
  Icons,
} from 'stylewhere/components'
import { OperationReadingProps, RemoteOperation, Router, FormSchemaData, AppStore } from 'stylewhere/shared'
import { T, __, __UP } from 'stylewhere/shared/i18n'
import { config } from 'stylewhere/config'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { EncodingExtensions } from 'stylewhere/extensions'
import { format } from 'date-fns'
import {
  getImbustatriceProductError,
  KEEP_ALIVE_CONTROL_TIME,
  RETRY_SSE_TIME,
  MAX_LOG_ERROR,
  ERROR_DATE_FORMAT,
  capitalize,
} from 'stylewhere/utils'

interface State {
  product?: TmrProduct
  associated: number
  formData: FormSchemaData
  errorLogs: ErrorLogs[]
  identifiers: any[]
  status_association: number
  lastEvent: any
  configuration: any[]
  sseStatus: boolean
  retrySseCount: number
}

const productFields: ProductInfoField[] = [
  { path: 'material.value', label: __(T.misc.material) },
  { path: 'size.value', label: __(T.misc.size) },
  { path: 'color.value', label: __(T.misc.color) },
]

const EMBEDDED_ROLE = 'EMBEDDED'
const PENDANT_ROLE = 'PENDANT'

export default class ImbustatriceEncode extends Component<OperationReadingProps<State>, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)
  formSchema = EncodingExtensions.formSchema(this.operation, {})
  keepAliveTimer: NodeJS.Timeout | undefined
  sse: any

  state: State = {
    product: undefined,
    associated: 0,
    errorLogs: [],
    formData: this.locationState.formData ?? {},
    identifiers: [],
    status_association: 0,
    lastEvent: undefined,
    configuration: [],
    sseStatus: false,
    retrySseCount: 0,
  }

  componentDidMount = () => {
    if (!this.locationState.formData || !this.locationState.formData.conveyor) {
      this.goBack()
    } else {
      this.attachSse()
    }
  }

  componentWillUnmount = () => {
    this.setState({
      product: undefined,
      associated: 0,
      errorLogs: [],
    })
    this.destroySse()
  }

  attachSse = async () => {
    const conveyorId = this.locationState.formData.conveyor.id || this.locationState.formData.conveyor
    const sseUrl =
      config.endpoint + '/api/v1/sse/getData/conveyor/' + conveyorId + '?access_token=' + AppStore.authToken
    this.sse = new EventSource(sseUrl, { withCredentials: false })
    this.sse.onerror = () => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }
    this.sse.onopen = (event) => {
      this.setState({ sseStatus: true, retrySseCount: 0 })
    }
    this.sse.onmessage = (event) => {
      this.sseEventMessage(event)
    }
    this.sse.addEventListener('keep-alive', (e) => {
      this.startKeepAliveTimer()
    })
  }

  retrySse = () => {
    this.destroySse()
    if (this.state.retrySseCount === 1) {
      this.attachSse()
    } else {
      setTimeout(() => {
        this.attachSse()
      }, RETRY_SSE_TIME)
    }
  }

  startKeepAliveTimer = () => {
    this.clearKeepAliveTimer()
    this.keepAliveTimer = setTimeout(() => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }, KEEP_ALIVE_CONTROL_TIME)
  }

  clearKeepAliveTimer = () => {
    if (this.keepAliveTimer) {
      clearTimeout(this.keepAliveTimer)
      this.keepAliveTimer = undefined
    }
  }

  sseEventMessage = (event) => {
    const eventData = event.data ? JSON.parse(event.data) : undefined
    if (eventData && eventData.data) {
      this.managerSseEvent(eventData.data)
    }
  }

  getIdentifierLabel = (role) => {
    if (role && role !== null) return __UP(__(T.imbustatrice.dynamic_tag, { role: role }))
    return __UP(T.imbustatrice.unkwon_tag)
  }

  checkIdentifier = (identifiers, type, role) => {
    let count = 0
    for (let e = 0; e < identifiers.length; e++) {
      if (identifiers[e].type === type && identifiers[e].role === role) count++
    }
    return count
  }

  checkIdentifierByCode = (identifiers, type, role, code) => {
    let e = 0
    let found = false
    while (e < identifiers.length && !found) {
      found = identifiers[e].type === type && identifiers[e].role === role && identifiers[e].code === code
      e++
    }
    return found
  }

  managerSseEvent = (event) => {
    if (event.tunnelEventType === 'ELABORATION_COMPLETED') {
      let configuration: any = []
      if (
        event.elaborationResult &&
        event.elaborationResult.payload &&
        event.elaborationResult.payload.configuration &&
        event.elaborationResult.payload.configuration.details &&
        event.elaborationResult.payload.configuration.details !== null
      ) {
        configuration = event.elaborationResult.payload.configuration.details
      } else {
        configuration = [
          {
            identifierType: 'UHF_TAG',
            role: EMBEDDED_ROLE,
            optional: false,
          },
          {
            identifierType: 'UHF_TAG',
            role: PENDANT_ROLE,
            optional: false,
          },
        ]
      }
      this.setState({ lastEvent: event, configuration: configuration }, this.processedSseEvent)
    }
  }

  getEventErrorLog = (event, product, defaultIsEmpty) => {
    const tmp: ErrorLogs[] = []
    if (event.elaborationResult && !event.elaborationResult.success) {
      const productStr = getImbustatriceProductError(product)
      /*if (event.elaborationResult.errors && event.elaborationResult.errors.length > 0) {
        //event.elaborationResult.errors.length
        tmp.push({
          date: format(new Date(event.eventCreationDate), ERROR_DATE_FORMAT),
          status: 'error',
          message: productStr + event.elaborationResult.errors[0],
        })
      } else if (defaultIsEmpty) {*/
      tmp.push({
        date: format(new Date(event.eventCreationDate), ERROR_DATE_FORMAT),
        status: 'error',
        message: productStr + defaultIsEmpty,
      })
      //}
    }
    return tmp
  }

  getItemProduct = (event) => {
    if (event.elaborationResult && event.elaborationResult.payload && event.elaborationResult.payload.product) {
      const product =
        event.elaborationResult && event.elaborationResult.payload && event.elaborationResult.payload.product
      if (event.elaborationResult.payload.attributes && event.elaborationResult.payload.attributes.wam) {
        if (!product.attributes) product.attributes = {}
        product.attributes.wam = {
          value: event.elaborationResult.payload.attributes.wam,
          description: '',
        }
      }
      return product
    }
    return undefined
  }

  getDefaultErrorLog = (event, identifiers) => {
    const { configuration } = this.state
    const success = event.elaborationResult && event.elaborationResult.success
    if (success) return ''

    /*const product = this.getItemProduct(event)
    if (!product) {
      return capitalize(__(T.imbustatrice.dynamic_tag_missing, { role: PENDANT_ROLE }))
    } else {*/
    let c = 0
    const roleErrors = []
    let str = ''
    let checkIdentifier
    for (c = 0; c < configuration.length; c++) {
      checkIdentifier = this.checkIdentifier(identifiers, configuration[c].identifierType, configuration[c].role)
      if (checkIdentifier === 0 && !configuration[c].optional) {
        roleErrors[configuration[c].identifierType + '_' + configuration[c].role] = capitalize(
          __(T.imbustatrice.dynamic_tag_missing, { role: configuration[c].role })
        )
      } else if (checkIdentifier > 1) {
        roleErrors[configuration[c].identifierType + '_' + configuration[c].role] = capitalize(
          __(T.imbustatrice.too_many_dynamic_tags, { role: configuration[c].role })
        )
      }
    }

    const errorKeys = Object.keys(roleErrors)
    if (errorKeys.length > 0) {
      for (c = 0; c < errorKeys.length; c++) {
        str += (str != '' ? __(T.imbustatrice.and) : '') + roleErrors[errorKeys[c]]
      }
      return str
    } else {
      const tagsRead: any = []
      let tagErrorCount = 0
      let tag
      configuration.map((conf) => {
        tag = identifiers.find((identifier) => identifier.type === conf.identifierType && identifier.role === conf.role)
        tagsRead.push({
          tag: tag,
          role: conf.role,
          status: tag && tag.status ? tag.status : '',
        })
        if (!tag && !conf.optional) tagErrorCount++
      })
      if (tagErrorCount > 0) {
        str = ''
        for (c = 0; c < tagsRead.length; c++) {
          if (!tagsRead[c].tag) {
            str +=
              (str != '' ? __(T.imbustatrice.and) : '') +
              capitalize(__(T.imbustatrice.dynamic_tag_missing, { role: tagsRead[c].role }))
          }
        }
        return str
      }

      str = ''
      for (c = 0; c < tagsRead.length; c++) {
        if (tagsRead[c].status === 'ASSOCIATED') {
          str +=
            (str != '' ? __(T.imbustatrice.and) : '') +
            capitalize(__(T.imbustatrice.dynamic_tag_already_associated, { role: tagsRead[c].role }))
        }
      }
      if (str !== '') {
        return str
      }
    }
    //}
    if (event.elaborationResult && event.elaborationResult.errors && event.elaborationResult.errors.length > 0) {
      const missingTagError = event.elaborationResult.errors.find((error) => error?.errorCode === "MISSING_TAG")
      if (missingTagError) {
        return capitalize(__(T.imbustatrice.missing_tag))
      }
    }
    return 'Unknown error'
  }

  processedSseEvent = () => {
    const { associated, lastEvent, configuration } = this.state
    if (lastEvent) {
      const success = lastEvent.elaborationResult && lastEvent.elaborationResult.success
      const product = this.getItemProduct(lastEvent)
      const tmpIdentifiers: any[] = []
      let c = 0
      if (
        lastEvent.elaborationResult &&
        lastEvent.elaborationResult.payload &&
        (lastEvent.elaborationResult.payload.identifiers.length > 0 ||
          lastEvent.elaborationResult.payload.extraTags.length > 0)
      ) {
        const identifiers = lastEvent.elaborationResult.payload.identifiers
        //si aggiungono gli extra tags
        const extraTags = lastEvent.elaborationResult.payload.extraTags
        let checkIdentifier
        if (extraTags.length > 0) {
          for (let e = 0; e < extraTags.length; e++) {
            //si aggiunge se non già presente negli identifiers
            checkIdentifier = this.checkIdentifierByCode(
              identifiers,
              extraTags[e].type,
              extraTags[e].role,
              extraTags[e].code
            )
            if (!checkIdentifier) identifiers.push(extraTags[e])
          }
        }

        const roleErrors = []
        for (c = 0; c < configuration.length; c++) {
          roleErrors[configuration[c].identifierType + '_' + configuration[c].role] = 'success'
        }

        for (c = 0; c < configuration.length; c++) {
          checkIdentifier = this.checkIdentifier(identifiers, configuration[c].identifierType, configuration[c].role)
          if (checkIdentifier === 0 && !configuration[c].optional) {
            tmpIdentifiers.push({
              status: 2,
              label: this.getIdentifierLabel(configuration[c].role),
              subtitle: '',
              type: configuration[c].identifierType,
              role: configuration[c].role,
            })
            roleErrors[configuration[c].identifierType + '_' + configuration[c].role] = 'error'
          } else if (checkIdentifier > 1) {
            roleErrors[configuration[c].identifierType + '_' + configuration[c].role] = 'error'
          }
        }

        let status = 0
        for (let e = 0; e < identifiers.length; e++) {
          status = 2
          if (roleErrors[identifiers[e].type + '_' + identifiers[e].role]) {
            status = roleErrors[identifiers[e].type + '_' + identifiers[e].role] === 'error' ? 2 : 1
          }
          if (!success && identifiers[e].status && identifiers[e].status === 'ASSOCIATED') status = 2
          tmpIdentifiers.push({
            status: status,
            label: this.getIdentifierLabel(identifiers[e].role),
            subtitle:
              !success && identifiers[e].status && identifiers[e].status === 'ASSOCIATED'
                ? __(T.misc.associated)
                : this.getIdentifierMessage(status),
            type: identifiers[e].type,
            role: identifiers[e].role,
          })
        }

        this.setState({
          product: product,
          identifiers: tmpIdentifiers,
          status_association: success ? 1 : 2,
          associated: associated + (success ? 1 : 0),
          errorLogs: this.getErrorLogs(
            this.getEventErrorLog(lastEvent, product, this.getDefaultErrorLog(lastEvent, identifiers))
          ),
        })
      } else {
        for (c = 0; c < configuration.length; c++) {
          tmpIdentifiers.push({
            status: success ? 1 : 2,
            label: this.getIdentifierLabel(configuration[c].role),
            subtitle: '',
            type: configuration[c].identifierType,
            role: configuration[c].role,
          })
        }

        this.setState({
          product: product,
          identifiers: tmpIdentifiers,
          status_association: success ? 1 : 2,
          associated: associated + (success ? 1 : 0),
          errorLogs: this.getErrorLogs(
            this.getEventErrorLog(lastEvent, product, this.getDefaultErrorLog(lastEvent, tmpIdentifiers))
          ),
        })
      }
    }
  }

  getIdentifierMessage = (status) => {
    if (status === 2) {
      return __(T.misc.error_tag)
    } else if (status === 1) {
      return __(T.misc.confirmed)
    }
    return __(T.misc.reading)
  }

  getErrorLogs = (logs) => {
    const { errorLogs } = this.state
    const tmp: ErrorLogs[] = errorLogs
    if (logs && logs.length > 0) {
      for (let l = 0; l < logs.length; l++) tmp.splice(0, 0, logs[l])
      if (tmp.length > MAX_LOG_ERROR) {
        tmp.pop()
      }
    }
    return tmp
  }

  clearProductInfo = () => {
    this.setState({
      product: undefined,
    })
  }

  clearErrorLogs = () => {
    this.setState({ errorLogs: [] })
  }

  destroySse = () => {
    if (this.sse) {
      this.sse.close()
      this.sse = undefined
    }
  }

  goBack = () => {
    this.destroySse()
    if (this.formSchema.length) {
      Router.navigate('/encoding/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  render() {
    const { product, errorLogs, associated, identifiers, status_association, sseStatus } = this.state
    return (
      <Page
        headerRight={<ImbustatriceRightHeader counter={associated} sseStatus={sseStatus} />}
        title={this.operation.description}
        enableEmulation
      >
        <Page.Sidebar hideBar width={'55%'} pl={0} pb={0} height={'100%'}>
          {!product && identifiers.length === 0 && (
            <Box borderRadius={0} ph={0} flex bg="white" center>
              <Text center style={{ width: 500 }} fontSize={30}>
                {__(T.messages.waiting_for_product)}
              </Text>
            </Box>
          )}
          {identifiers.length > 0 && (
            <ProductInfoBox
              disabledImage
              marginCard={'0px 15px'}
              disabledDescription
              product={product}
              fields={productFields}
            />
          )}
          {identifiers.length > 0 && (
            <Box pl={15} pt={15} pr={0} pb={0} flex>
              <Box flex row={false}>
                <Box flex row flexWrap>
                  {identifiers.map((element, index) => (
                    <ImbustatriceTagRow
                      key={'identifiers_' + index}
                      type={1}
                      status={element.status}
                      title={element.label}
                      subtitle={element.subtitle}
                      minHeight={100}
                    />
                  ))}
                </Box>
                <ImbustatriceTagRow
                  type={2}
                  status={status_association}
                  title={__UP(__(T.misc.association))}
                  subtitle={''}
                  minHeight={100}
                />
              </Box>
            </Box>
          )}
        </Page.Sidebar>
        <Page.Content notBoxed style={{ margin: 0, marginLeft: 0 }}>
          <Box flex mt={15}>
            <Box row vcenter>
              <Text style={{ marginRight: 10, marginLeft: 10 }} bold fontSize={20}>
                {__(T.misc.error_logs)}
              </Text>
              <Button
                disabled={errorLogs.length === 0}
                padding={'0px'}
                variant="default"
                circle
                onClick={this.clearErrorLogs}
                size="small"
              >
                <Icons.Clear width={20} height={20} />
              </Button>
            </Box>
            <Box flex hideBar style={{ overflowY: 'auto' }}>
              <Box p={10} pb={0} flex bg="white" mt={10} mr={5} ml={5}>
                {errorLogs.map((log) => (
                  <ErrorLog disabledStatus log={log} />
                ))}
              </Box>
            </Box>
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
