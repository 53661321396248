import { api, TmrBaseResource, responseErrorCheck } from 'stylewhere/api'

export class ApplicationSetting extends TmrBaseResource {
  static endpoint = '/api/v1/applicationSettings'

  static async client() {
    let settings: any[] = []
    const params = {}
    try {
      settings = await api.get<any>(`${this.endpoint}/client`, params).then(responseErrorCheck)
    } catch (e) {}
    return settings
  }
}
