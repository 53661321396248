import { Component } from 'react'
import { Icons, Box } from 'stylewhere/components'

interface Props {
  started: boolean
  ml?: number
}

export class AntennaStatus extends Component<Props> {
  render() {
    const { started, ml } = this.props
    return (
      <Box vcenter ml={15}>
        {!started ? (
          <Icons.Antenna style={{ height: 35, width: 60, marginLeft: ml ? ml : 0 }} />
        ) : (
          <Icons.AntennaOn style={{ height: 40, width: 60, marginLeft: ml ? ml : 0 }} />
        )}
      </Box>
    )
  }
}
