import { FormSchemaData, OperationConfig } from 'stylewhere/shared'
import { Extensions } from './Extensions'

export class SimpleItemSessionListExtensions extends Extensions {
  static async beforeConfirm<O extends any[]>(
    operation: OperationConfig,
    data: FormSchemaData,
    // Possono essere una lista di items, di parcels o altro a seconda del tipo di operazione
    objects: O
  ): Promise<boolean> {
    return true
  }
}
