import { api, responseErrorCheck } from 'stylewhere/api'
import { AppStore } from 'stylewhere/shared'
import { TmrBaseResource as Base } from 'core/api/TmrBaseResource'

export class TmrBaseResource extends Base {
  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  static save<T = any>(data, requestConfig = undefined): Promise<T> {
    if (data.attributes) {
      data.attributes.workstationCode = AppStore.defaultWorkstation?.code
    } else {
      data.attributes = { workstationCode: AppStore.defaultWorkstation?.code }
    }
    return api.post<T>(`${this.endpoint}`, data, requestConfig).then(responseErrorCheck)
  }
}
