import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, EncodingInboundSapViewSession } from 'stylewhere/components'
import { T, __, __UP } from 'stylewhere/i18n'

interface Props {
  sessionList: any[]
  listId: string
}

export class EncodingInboundSapViewSessionList extends Component<Props> {
  render() {
    const { sessionList, listId } = this.props
    return (
      <Box ml={15} mr={15}>
        {sessionList.map((session, index) => {
          return <EncodingInboundSapViewSession key={'session-list-' + index} session={session} listId={listId} />
        })}
      </Box>
    )
  }
}

const SessionRow = styled(Box)`
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 15px 20px;
  margin-bottom: 10px;
`

const Session = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #454545;
  margin-left: 7px;
`
