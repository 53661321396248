import { Spinner } from '@chakra-ui/react'
import { Component } from 'react'
import { Icons } from 'stylewhere/components'
import { __ } from 'stylewhere/shared/i18n'

export interface ParcelRowStatusProps {
  loading: boolean
  expanded: boolean
  expandable: boolean
}

export class ParcelRowStatus extends Component<ParcelRowStatusProps> {
  
  render() {
    const { loading, expanded, expandable } = this.props

    if (loading) return (
      <Spinner thickness="4px" speed="0.65s" color="#e0e0e0" size="md" />
    )

    if (expanded) return (<Icons.ArrowDownBold />)

    if (expandable) return (<Icons.ArrowRight />)

    return ("")
  }
}
