import { useState } from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'
import { isNumber } from 'stylewhere/shared/utils'

export type ButtonVariant = 'primary' | 'secondary' | 'default' | 'error' | 'warning' | 'ignore' | 'plain' | 'white'
const colors = {
  primary: '#ceff00',
  secondary: '#333333',
  default: '#DCDCDC',
  ignore: '#EDEDED',
  warning: '#FFEFC6',
  error: '#FFD4CB',
  white: '#FFF',
}

interface Props {
  title?: string
  variant?: ButtonVariant
  size?: 'default' | 'small' | 'medium' | 'refresh'
  onClick?: () => void
  type?: string
  style?: React.CSSProperties
  loading?: boolean
  disabled?: boolean
  circle?: boolean
  children?: any
  flex?: number | boolean | string
  ml?: number
  padding?: string
  radius?: number
}

export function Button({ title, onClick, children, loading, disabled = false, ...restProps }: Props) {
  const [internalLoading, setinternalLoading] = useState(false)
  const onPrePress = async () => {
    if (internalLoading) return
    if (!onClick) return

    try {
      setinternalLoading(true)
      await onClick()
      setinternalLoading(false)
    } catch (error) {
      //ignore
    }
  }

  const load = internalLoading || loading
  return (
    <ButtonStyle disabled={disabled || load} onClick={onPrePress} {...(restProps as any)}>
      {!load && <>{title ?? children}</>}
      {load && <Spinner />}
    </ButtonStyle>
  )
}

function flexIfDefined(flex: number | boolean | string | undefined) {
  if (flex === undefined) return undefined
  if (isNumber(flex)) return flex as number
  if (typeof flex === 'string') return flex
  return 1
}

const ButtonStyle = styled.button<Props>`
  flex: ${({ flex }) => flexIfDefined(flex)};
  position: relative;
  ${({ size, circle }) => {
    switch (size) {
      case 'small':
        return `padding: ${circle ? '0' : '0 11px'}; font-size: 17px; min-height: 36px; width: ${
          circle ? '36px' : 'auto'
        };`
      case 'medium':
        return `padding: ${circle ? '0' : '12px 20px'}; font-size: 18px; min-height: 45px; width: ${
          circle ? '45px' : 'auto'
        };`
      case 'refresh':
        return `padding: 0px; font-size: 18px; min-height: 80px; width: 60px;display: flex; justify-content: center; align-items: center;margin-left:10px`
      default:
        return `padding: ${circle ? '0' : '0 30px'}; font-size: 24px; min-height: 65px; width: ${
          circle ? '65px' : 'auto'
        };`
    }
  }};
  ${({ style }) => {
    if (style?.height) return `min-height: unset;`
    return ''
  }}
  ${({ circle }) => (circle ? `display: flex; justify-content: center; align-items: center;` : '')}
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  background: ${({ variant, theme }) => {
    const bgs = {
      primary: theme.primaryBg,
      secondary: theme.secondaryBg,
      ignore: `linear-gradient(180deg, ${colors.ignore} 60.77%, ${colors.ignore} 60.79%, ${colors.ignore} 229.23%)`,
      warning: `linear-gradient(180deg, ${colors.warning} 60.77%, ${colors.warning} 60.79%, ${colors.warning} 229.23%)`,
      error: `linear-gradient(180deg, ${colors.error} 60.77%, ${colors.error} 60.79%, ${colors.error} 229.23%)`,
      plain: 'none',
      white: '#FFF',
      default: `${colors.default}`,
    }
    return bgs[variant ?? 'primary']
  }};
  color: ${(props) => (props.variant === 'secondary' ? 'white' : 'rgba(51,51,51,1)')};
  ${({ variant = 'primary' }) => {
    if (variant === 'primary') return `box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 -1px 4px rgba(0, 0, 0, 0.1);`
    return `box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15), inset 0 -1px 4px rgba(0, 0, 0, 0.1);`
  }};
  border-color: ${(props) => (props.variant === 'secondary' ? 'transparent' : 'transparent')};
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  border-radius: ${(props) => (props.circle ? '100%' : props.radius ? props.radius + 'px' : '10px')};
  margin-left: ${({ ml }) => (ml ? ml + 'px' : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px 15px')};
  line-height: 1;
  :disabled {
    opacity: 0.5;
  }
  :focus {
    outline: ${(props) => (props.variant === 'secondary' ? 'transparent' : 'transparent')};
  }
`
