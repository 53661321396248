import { Component } from 'react'
import { Box, Text, Stoplight, Input, Button } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props {
    shipmentCode?: string
    onConfirmShpment: () => void
    canShowConfirmShipment: boolean
    totalReadings: number
    totalExpecteds: number
}

export class ParcelRightHeader extends Component<Props> {
    render() {
        const { shipmentCode, onConfirmShpment, canShowConfirmShipment, totalExpecteds, totalReadings } = this.props
        return (
            <Box row>
                <Box vcenter mr={15}>
                    <Text fontSize={20}>{__(T.misc.items)}</Text>
                    <Text style={{ textAlign: 'left' }} bold fontSize={20}>
                        {totalReadings}{totalExpecteds ? `/${totalExpecteds}` : ''}
                    </Text>
                </Box>

                <Box vcenter mr={15}>
                    <Text fontSize={20}>{__(T.misc.shipment)}</Text>
                    <Text style={{ textAlign: 'left' }} bold fontSize={20}>
                        {shipmentCode}
                    </Text>
                </Box>

                <Button
                    variant={"secondary"}
                    disabled={!canShowConfirmShipment}
                    title={`${__(T.misc.confirm_shipment)}`}
                    onClick={onConfirmShpment}
                />
            </Box>
        )
    }
}
