import { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Box, GroupedListRow, Icons, RoundedLabel } from 'stylewhere/components'
import { T, __, __UP } from 'stylewhere/i18n'
import { getProductCode } from 'stylewhere/shared/utils'

interface Props {
  parcel: any
  onPrint: (parcelCode: string) => void
}

export class ParcelInfoRow extends PureComponent<Props> {
  render() {
    const { parcel, onPrint } = this.props
    return (
      <>
        <GroupedListRow>
          <Box flex ph={16} style={{ alignSelf: 'center' }}>
            <Box mb={10}>
              <ProductCode>{getProductCode(parcel)}</ProductCode>
              {parcel.description && <ProductDescription>{parcel.description}</ProductDescription>}
            </Box>
            <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
              <RoundedLabel label={__(T.misc.status)} value={parcel.status || 'n/a'} />
            </Box>
          </Box>
          <Box center mr={15} ml={15} mt={10}>
            <Box
              center
              pv={5}
              mt={5}
              ph={5}
              mr={0}
              style={{ fontWeight: 'bold', fontSize: 14, width: '100%', borderRadius: 5 }}
              onClick={() => this.props.onPrint(parcel.code)}
            >
              <Icons.Print />
            </Box>
          </Box>
        </GroupedListRow>
      </>
    )
  }
}

const ProductDescription = styled.label`
  font-size: 15px;
  word-break: break-word;
`
const ProductCode = styled.label`
  font-weight: bold;
  font-size: 20pt;
  word-break: break-word;
`
