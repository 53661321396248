import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Icons } from 'stylewhere/components'

interface Props {
  label?: string
  onChange: () => void
  checked: boolean
  mt?: number
  mr?: number
  deleteIcon?: boolean
}

export class ToggleField extends Component<Props> {
  render() {
    const { onChange, checked, label, mt, mr, deleteIcon } = this.props
    return (
      <Box row vcenter mt={mt || 0} mr={mr || 0} onClick={() => onChange()}>
        {label && <Text>{label}</Text>}
        {deleteIcon && <Icons.DeleteBlack width={30} height={30} />}
        <ToggleBox checked={checked}>
          <ToggleBoxBall checked={checked} />
        </ToggleBox>
      </Box>
    )
  }
}

const Text = styled.label`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-align: right;
  flex: 1;
`

const ToggleBox = styled.div<{ checked }>`
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: ${({ checked }) => (checked ? '#3182CE' : '#dcdcdc')};
  margin-left: 7px;
  cursor: pointer;
  padding: 2px;
  position: relative;
  transition: all 0.5s ease;
`

const ToggleBoxBall = styled.div<{ checked }>`
  position: absolute;
  top: 2px;
  left: ${({ checked }) => (checked ? '32px' : '2px')};
  width: 26px;
  height: 26px;
  border-radius: 15px;
  background-color: #ffffff;
  alignself: flex-end;
  transition: all 0.5s ease;
`
