import { Component } from 'react'
import { T, __ } from 'stylewhere/i18n'
import { InventoryCounters, Inventories } from 'stylewhere/api'
import { TagCounter, Button, Icons } from 'stylewhere/components'
import { config } from 'stylewhere/config'

interface Props {
  inventoryId?: string
  operationId?: string
  operationInstanceId?: string
  externalNumberRefresh: number
  timeToRefresh: number
}

interface State {
  counters?: InventoryCounters
  loading: boolean
  externalNumberRefresh?: number
  init: boolean
}

export class InventoryDetailCounters extends Component<Props, State> {
  timerRefresh

  state: State = {
    counters: undefined,
    loading: false,
    init: true,
  }

  componentDidMount = async () => {
    this.setState({ externalNumberRefresh: this.props.externalNumberRefresh }, this.reloadCounter)
  }

  shouldComponentUpdate = (nextProps) => {
    const { externalNumberRefresh } = this.state
    if (nextProps.externalNumberRefresh !== externalNumberRefresh) {
      this.setState({ externalNumberRefresh: this.props.externalNumberRefresh }, this.reloadCounter)
    }
    return true
  }

  reloadCounter = () => {
    this.clearTimer()
    this.setState({ loading: true }, this.reloadCounterCallback)
  }

  reloadCounterCallback = () => {
    setTimeout(() => {
      this.refreshCounter()
    }, 300)
  }

  refreshCounter = async () => {
    const { inventoryId } = this.props
    if (inventoryId) {
      const counters = await Inventories.getCounters(inventoryId!)
      this.setState({ counters, loading: false, init: false }, this.checkTimerRefresh)
    }
  }

  clearTimer = () => {
    if (this.timerRefresh) {
      clearTimeout(this.timerRefresh)
    }
  }

  checkTimerRefresh = () => {
    const { timeToRefresh } = this.props
    if (timeToRefresh > 0) {
      this.timerRefresh = setTimeout(() => {
        this.reloadCounter()
      }, timeToRefresh)
    }
  }

  getRead = () => {
    const { counters } = this.state
    if (!counters) return 0
    return (counters.rfidDetected || 0) + (counters.barcodeDetected || 0)
  }

  getExpected = () => {
    const { counters } = this.state
    if (!counters) return { detected: 0, total: 0 }
    return {
      detected:
        (counters.rfidDetected || 0) +
        (counters.barcodeDetected || 0) -
        (counters.rfidUnexpected || 0) -
        (counters.barcodeUnexpected || 0),
      total: (counters.rfidExpected || 0) + (counters.barcodeExpected || 0),
    }
  }

  getUnexpected = () => {
    const { counters } = this.state
    if (!counters) return 0
    return (counters.rfidUnexpected || 0) + (counters.barcodeUnexpected || 0)
  }

  getMissing = () => {
    const { counters } = this.state
    if (!counters) return 0
    return (counters.rfidMissing || 0) + (counters.barcodeMissing || 0)
  }

  getRfidIgnoreWithReason = () => {
    const { counters } = this.state
    if (!counters) return 0
    return counters.rfidIgnoreWithReason || 0
  }

  getControlPanelURL = () => {
    let url
    if (window.location && window.location.origin) { // && window.location.hostname !== 'localhost') {
      url = window.location.origin
      url = url.replace('station', 'controlpanel')
    }
    return url
  }

  openControlPanel = () => {
    const url = this.getControlPanelURL()
    if (url) {
      const { operationId, operationInstanceId } = this.props
      //window.open(url + '/operationlogs/INVENTORY/' + operationId + '/' + operationInstanceId, '_blank')
      window.open(url + '/operationlogs/INVENTORY/' + operationId, '_blank')
    }
  }

  render() {
    const { loading, init } = this.state
    const param = {
      size: '22px',
      interline: '30px',
      sizeText: '15px',
      lineHeightText: '28px',
      minHeight: '80px',
      padding: '0px 10px',
      spacerWidth: 10,
    }
    const expected = this.getExpected()
    return (
      <>
        {!init && (
          <>
            <TagCounter
              layout={{ ...param, textColor: 'rgb(112, 205, 238)', bgColor: '#F8F8F8' }}
              detected={this.getRead()}
              title={__(T.misc.read)}
            />
            <TagCounter
              layout={{ ...param, textColor: 'rgb(117, 235, 168)', bgColor: '#F8F8F8' }}
              detected={expected.detected}
              expected={expected.total}
              title={__(T.misc.expected)}
            />
            <TagCounter
              layout={{ ...param, textColor: 'rgb(255, 132, 116)', bgColor: '#F8F8F8' }}
              detected={this.getUnexpected()}
              title={__(T.misc.unexpected)}
            />
            <TagCounter
              layout={{
                ...param,
                textColor: 'rgb(255, 132, 116)',
                bgColor: '#F8F8F8',
                sizeText: '14px',
                lineHeightText: '14px',
                minWidth: '100px',
              }}
              detected={this.getRfidIgnoreWithReason()}
              title={__(T.misc.ignored_with_reason)}
            />
            <TagCounter
              layout={{ ...param, textColor: 'rgb(255, 198, 101)', bgColor: '#F8F8F8', disableSpacer: true }}
              detected={this.getMissing()}
              title={__(T.misc.missing)}
            />
          </>
        )}
        <Button ml={10} loading={loading} size="refresh" onClick={() => this.reloadCounter()}>
          <Icons.Refresh style={{ width: 28, height: 28 }} />
        </Button>
        {this.getControlPanelURL() && (
          <Button padding={'0px'} ml={10} variant="white" size="refresh" onClick={() => this.openControlPanel()}>
            <Icons.ControlPanelDetail style={{ width: 50, height: 50 }} />
          </Button>
        )}
      </>
    )
  }
}
