import { Component } from 'react'
import { Box, Icons } from 'stylewhere/components'
import styled from '@emotion/styled'
import { Skeleton, SkeletonCircle } from '@chakra-ui/react'
import { TmrFullItemEvent } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { format, parseISO } from 'date-fns'

interface Props {
  event: TmrFullItemEvent
  first?: boolean
  last?: boolean
}

export class ItemEventRow extends Component<Props> {
  getType = () => {
    const { event } = this.props
    return event ? event.eventType : '---'
  }

  getOperationType = () => {
    const { event } = this.props
    return event && event.operationLabel ? event.operationLabel : undefined
  }

  /* 'ITEM_ATTRIBUTE_DELETE'
    | 'ITEM_ATTRIBUTE_UPDATE'
    | 'ITEM_CONFIGURATION_REWRITED'
    | 'ITEM_CREATED'
    | 'ITEM_PRODUCT_UPDATED'
    | 'ITEM_TRANSFERRED'
    | 'ITEM_OPERATION_COMPLETED' */
  getIcon = () => {
    const type = this.getType()
    if (type === 'ITEM_CREATED') return <Icons.Encoding />
    //if (type === 'Inbound') return <Icons.Inbound />
    //if (type === 'Outbound') return <Icons.Outbound />
    if (type === 'ITEM_TRANSFERRED') return <Icons.TransferToZone />
    return <Icons.Encoding />
  }

  render() {
    const { event, first, last } = this.props
    const operationType = this.getOperationType()
    return (
      <>
        <Box row>
          <Box hcenter>
            {event && (
              <IconContainer p={6} first={first} center height={51} width={51}>
                {this.getIcon()}
              </IconContainer>
            )}
            {!event && <SkCircle startColor="#E6E6E6" endColor="#c8c8c8" size="50px" />}
            {!last && <VerticalSeparator last={last} />}
          </Box>
          <Box flex ml={20} mb={last ? 0 : 20}>
            {event && (
              <>
                <DescriptionLabel>{this.getType()}</DescriptionLabel>
                {operationType && (
                  <DateLabel>
                    {__(T.misc.operation_type)}: {operationType}
                  </DateLabel>
                )}
                {event && event.attributes && event.attributes.operationPlace && (
                  <DateLabel>
                    {__(T.misc.operation_place)}: {event.attributes.operationPlace}
                  </DateLabel>
                )}
                <DateLabel>
                  {__(T.misc.user)}: {event && event.user ? event.user.username : '---'}
                </DateLabel>
                {event && event.creationDate && (
                  <DateLabel>
                    {__(T.misc.creation_date)}: {format(parseISO(event.creationDate), 'dd/MM/yyyy')}
                  </DateLabel>
                )}
              </>
            )}
            {!event && (
              <>
                <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" height={19} width={91} mb="10px" />
                <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" height={19} width={91} mb="10px" />
              </>
            )}
          </Box>
        </Box>
      </>
    )
  }
}

const IconContainer = styled((props) => <Box {...props} />)`
  border-radius: 51px;
  background-color: ${({ first }) => (first ? '#c6eaff' : 'white')};
`

const VerticalSeparator = styled((props) => <Box {...props} />)`
  margin: 6px 0;
  height: calc(100% - 57px);
  width: 1px;
  ${({ last }) => !last && 'border-right: 1px dashed #898989;'};
`

const DescriptionLabel = styled((props) => <Box {...props} />)`
  font-weight: 900;
  font-size: 16px;
`

const DateLabel = styled((props) => <Box {...props} />)`
  font-weight: 400;
  font-size: 14px;
`

const SkCircle = styled(SkeletonCircle)`
  border-radius: 9999px !important;
`
