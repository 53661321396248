import { api, responseErrorCheck, TmrBaseResource } from 'stylewhere/api'
import { AppStore } from 'stylewhere/shared'

export class SimpleItemList extends TmrBaseResource {
  static endpoint = 'api/v1/operations/simpleItemList' //tracking/

  static batchValidateEndpoint() {
    return `${this.endpoint}/batchValidate`
  }

  static async executeAction(listId: string, operationId: string, actionCode: string, payload: any) {
    const decodePayload = {
      operationId: operationId,
      actionCode: actionCode,
      ...payload,
    }
    decodePayload.attributes.workstationCode = AppStore.defaultWorkstation?.code
    const res = await api.post(`${this.endpoint}/${listId}/executeAction `, decodePayload)
    return responseErrorCheck(res)
  }
}
