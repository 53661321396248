import { TmrBaseResource, api, responseErrorCheck } from 'stylewhere/api'

export class SimpleItemSessions extends TmrBaseResource {
  static endpoint = 'api/v1/simpleItemSessions' //logistics

  static async getById(id: string, requestConfig?) {
    const result = await api.get(`${this.endpoint}/${id}`, requestConfig)
    return responseErrorCheck(result)
  }
}
