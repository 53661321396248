import { Component } from 'react'
import styled from '@emotion/styled'
import { T, __, __UP } from 'stylewhere/i18n'
import { ErrorLogs } from 'stylewhere/api'
import { Router, RemoteOperation, OperationReadingState, OperationReadingProps, AppStore } from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { ShippingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { Box, Button, Text, Icons, Page, TagCounter, ErrorLog, GroupedItemList, Stoplight } from 'stylewhere/components'
import {
  getCustomTranslation,
  getConveyorItemConfiguration,
  KEEP_ALIVE_CONTROL_TIME,
  RETRY_SSE_TIME,
  MAX_LOG_ERROR,
  CONVEYOR_MIN_WIDTH_SCREEN,
  ERROR_DATE_FORMAT,
} from 'stylewhere/shared/utils'
import { config } from 'stylewhere/config'
import { format } from 'date-fns'

interface State extends OperationReadingState {
  status: number
  errorLogs: ErrorLogs[]
  isMobileVersion: boolean
  sseStatus: boolean
  retrySseCount: number
  detected: number
  unknown: number
  barcode: string
}

export default class ConveyorReading extends Component<OperationReadingProps<State>, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.matchParams.opCode)
  formSchema = ShippingExtensions.formSchema(this.operation, {})
  keepAliveTimer: NodeJS.Timeout | undefined
  sse: any

  state: State = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    status: 0,
    errorLogs: [],
    isMobileVersion: window.innerWidth < CONVEYOR_MIN_WIDTH_SCREEN,
    sseStatus: false,
    retrySseCount: 0,
    detected: 0,
    unknown: 0,
    barcode: '---',
  }

  componentDidMount = () => {
    if (!this.locationState.formData || !this.locationState.formData.conveyor) {
      this.goBack()
    } else {
      this.attachSse()
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    }
  }

  handleResize = () => {
    const { innerWidth: width } = window
    this.setState({ isMobileVersion: width < CONVEYOR_MIN_WIDTH_SCREEN })
  }

  componentWillUnmount = () => {
    this.setState({
      items: [],
      status: 0,
      errorLogs: [],
    })
    this.destroySse()
  }

  attachSse = async () => {
    const conveyorId = this.locationState.formData.conveyor.id || this.locationState.formData.conveyor
    const sseUrl =
      config.endpoint + '/api/v1/sse/getData/conveyor/' + conveyorId + '?access_token=' + AppStore.authToken
    this.sse = new EventSource(sseUrl, { withCredentials: false })
    this.sse.onerror = () => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }
    this.sse.onopen = (event) => {
      this.setState({ sseStatus: true, retrySseCount: 0 })
    }
    this.sse.onmessage = (event) => {
      this.sseEventMessage(event)
    }
    this.sse.addEventListener('keep-alive', (e) => {
      this.startKeepAliveTimer()
    })
  }

  retrySse = () => {
    this.destroySse()
    if (this.state.retrySseCount === 1) {
      this.attachSse()
    } else {
      setTimeout(() => {
        this.attachSse()
      }, RETRY_SSE_TIME)
    }
  }

  startKeepAliveTimer = () => {
    this.clearKeepAliveTimer()
    this.keepAliveTimer = setTimeout(() => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }, KEEP_ALIVE_CONTROL_TIME)
  }

  clearKeepAliveTimer = () => {
    if (this.keepAliveTimer) {
      clearTimeout(this.keepAliveTimer)
      this.keepAliveTimer = undefined
    }
  }

  getBoxStatus = (tunnelEventType) => {
    let status = 2 // RFID_START_READING
    if (tunnelEventType === 'RFID_STOP_READING') {
      status = 3
    } else if (tunnelEventType === 'BARCODE_READ') {
      status = 1
    } else if (tunnelEventType === 'RESULT_EVENT') {
      status = 4
    }
    return status
  }

  /* 
    logLevel: TRACE, INFO, WARNING, ERROR
    tunnelEventType: BARCODE_READ, RFID_START_READING, RFID_STOP_READING, RESULT_EVENT
  */
  sseEventMessage = (event) => {
    const tmp = this.state.errorLogs
    const { detected, unknown, barcode } = this.state
    const eventData = event.data ? JSON.parse(event.data) : undefined
    if (eventData && eventData.data) {
      if (eventData.data.tunnelEventType !== 'ELABORATION_COMPLETED') {
        let countDetected = detected
        let countUnknown = unknown
        let tmpBarcode

        const resetData =
          eventData.data.tunnelEventType === 'BARCODE_READ' || eventData.data.tunnelEventType === 'RFID_START_READING'
        //reset item se tunnelEventType è BARCODE_READ
        const tmpItems = resetData ? [] : this.state.items
        if (resetData) {
          tmpBarcode = eventData.data.barcode
          countDetected = 0
          countUnknown = 0
        }

        if (eventData.data.identifiers) {
          let addItem = true
          let checkItem
          for (let e = 0; e < eventData.data.identifiers.length; e++) {
            if (!eventData.data.identifiers[e].item) {
              addItem = true
              countUnknown++
            } else {
              if (
                eventData.data.identifiers[e].item.product &&
                (!eventData.data.identifiers[e].item.id || eventData.data.identifiers[e].item.id === null)
              ) {
                eventData.data.identifiers[e].item.id = Date.now()
                eventData.data.identifiers[e].item.fakeItemID = true
                addItem = true
                countDetected++
              } else {
                checkItem = tmpItems.find((p) => p.item && p.item.id === eventData.data.identifiers[e].item.id)
                addItem = !checkItem
                if (!checkItem) {
                  countDetected++
                }
              }
            }
            if (addItem) {
              tmpItems.splice(0, 0, eventData.data.identifiers[e])
            }
          }
        }
        const tmpStatus = this.getBoxStatus(eventData.data.tunnelEventType || '')
        const log: ErrorLogs = {
          date: eventData.data.eventCreationDate
            ? format(new Date(eventData.data.eventCreationDate), ERROR_DATE_FORMAT)
            : format(new Date(), ERROR_DATE_FORMAT),
          status: eventData.data.logLevel ? eventData.data.logLevel : 'TRACE',
          message: eventData.data.tunnelEventType ? getCustomTranslation(eventData.data.tunnelEventType) : 'n/a',
        }
        tmp.splice(0, 0, log)
        if (tmp.length > MAX_LOG_ERROR) {
          tmp.pop()
        }
        this.setState({
          errorLogs: tmp,
          detected: countDetected,
          unknown: countUnknown,
          items: tmpItems,
          status: tmpStatus,
          barcode: tmpBarcode || barcode,
        })
      }
    }
  }

  getStatusData = () => {
    const { status } = this.state
    if (status === 1) {
      // BARCODE READ
      return {
        background: '#FFF', //#75EBA8',
        label: __UP(__(T.misc.reading)),
      }
    } else if (status === 2) {
      // RFID_START_READING
      return {
        background: '#FFF', //'#F27D7D',
        label: __UP(__(T.misc.reading)),
      }
    } else if (status === 3) {
      // RFID_STOP_READING
      return {
        background: '#FFF', //'#F27D7D',
        label: __UP(__(T.misc.box_closed)),
      }
    } else if (status === 4) {
      // RESULT_EVENT
      return {
        background: '#FFF', //'#F27D7D',
        label: __UP(__(T.misc.awaiting)),
      }
    }
    // DEFAULT
    return {
      background: '#FFF', //'#EBB575',
      label: __UP(__(T.misc.awaiting)),
    }
  }

  destroySse = () => {
    if (this.sse) {
      this.sse.close()
      this.sse = undefined
    }
  }

  goBack = () => {
    this.destroySse()
    if (this.formSchema.length) {
      if (this.operation.templatePath && this.operation.templatePath === 'conveyor') {
        Router.navigate('/transfer-to-zone/conveyor/:opCode', {
          opCode: this.operation.code,
        })
      } else {
        Router.navigate('/transfer-to-zone/:opCode', { opCode: this.operation.code })
      }
    } else {
      Router.navigate('/')
    }
  }

  clearErrorLogs = () => {
    this.setState({ errorLogs: [] })
  }

  render() {
    const { items, formData, loading, errorLogs, isMobileVersion, sseStatus, barcode, detected, unknown } = this.state
    const statusData = this.getStatusData()
    const counterLayout = {
      disableSpacer: true,
      height: 180,
      size: '90px',
      interline: '100px',
      sizeText: '18px',
      lineHeightText: '22px',
    }
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        headerRight={<Stoplight active={sseStatus} />}
        enableEmulation
        contentWrapperColumn={isMobileVersion}
      >
        <Page.Sidebar
          width={isMobileVersion ? '100%' : 500}
          pl={0}
          pb={0}
          height={'100%'}
          style={{ overflowY: 'hidden' }}
        >
          <Box flex style={{ overflowY: 'hidden' }}>
            <Box row ml={15} mr={15}>
              <TagCounter
                title={__UP(__(T.misc.total_ok))}
                layout={counterLayout}
                margin={'0px 3px 0px 0px'}
                detected={detected}
              />
              <TagCounter
                title={__UP(__(T.misc.unknown))}
                layout={{ ...counterLayout, bgColor: '#FBBF66' }}
                margin={'0px 0px 0px 3px'}
                detected={unknown}
              />
            </Box>
            <Box height={40} row bg="white" borderRadius={10} ml={15} mr={15} vcenter center mt={6} mb={0}>
              <Text style={{ flex: 1, textAlign: 'right' }}>{__UP(__(T.misc.status))}</Text>
              <Status bg={statusData.background}>{statusData.label}</Status>
            </Box>
            <Box height={40} row bg="white" borderRadius={10} ml={15} mr={15} vcenter center mt={6} mb={10}>
              <Text style={{ flex: 1, textAlign: 'right' }}>{__UP(__(T.custom.BARCODE_READ))}</Text>
              <Status bg={statusData.background}>{barcode}</Status>
            </Box>
            {!isMobileVersion && (
              <Box flex mt={0} height={'calc(100% - 286px)'}>
                <Box row vcenter>
                  <Text style={{ marginRight: 15, marginLeft: 15 }} bold fontSize={20}>
                    {__(T.misc.event_logs)}
                  </Text>
                  <Button
                    disabled={errorLogs.length === 0}
                    padding={'0px'}
                    variant="default"
                    circle
                    onClick={this.clearErrorLogs}
                    size="small"
                  >
                    <Icons.Clear width={20} height={20} />
                  </Button>
                </Box>

                <Box flex hideBar height={'calc(100% - 36px)'} style={{ overflowY: 'auto' }}>
                  <Box p={10} pb={0} flex bg="white" mt={10} mr={5} ml={5}>
                    {errorLogs.map((log, index) => (
                      <ErrorLog onlyMessage key={'error-log-' + index} log={log} />
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Page.Sidebar>
        <Page.Content notBoxed style={{ margin: 0, marginLeft: 0 }}>
          {items.length === 0 ? (
            <Box borderRadius={0} ph={0} flex bg="white" center>
              <Text center style={{ width: 500 }} fontSize={30}>
                {__(T.messages.waiting_for_product)}
              </Text>
            </Box>
          ) : (
            <Box flex mr={isMobileVersion ? 0 : 15} mt={isMobileVersion ? 0 : 15} mb={isMobileVersion ? 0 : 15}>
              <GroupedItemList configuration={getConveyorItemConfiguration()} operation={this.operation} rows={items} />
            </Box>
          )}
        </Page.Content>
      </Page>
    )
  }
}

const Status = styled.div<{ bg: string }>`
  background: ${({ bg }) => bg || '#F27D7D;'};
  margin-left: 10px;
  font-weight: bold;
  border-radius: 5px;
  padding: 0px 5px;
  flex: 1;
`
