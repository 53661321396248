import styled from '@emotion/styled'
import { Box, Icons, Switch } from 'stylewhere/components'

export interface RemoveModeProps {
  onRemoveChange: (checked: boolean) => void
  disabled?: boolean
}

export function RemoveMode(props: RemoveModeProps) {
  const { onRemoveChange, disabled = false } = props
  return (
    <StyledRemoveBox disabled={disabled} borderRadius={5} bgGrey p={5}>
      <Switch disabled={disabled} name={'removeMode'} labelPosition={'left'} label={<Icons.Trash />} onChange={onRemoveChange} />
    </StyledRemoveBox>
  )
}

const StyledRemoveBox = styled(Box)<{ disabled: boolean }>`
  border-width: 2px;
  border-style: solid;
  border-color: #dcdcdc;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  ${({ disabled }) =>
    disabled &&
    `pointer-events: none;
    opacity: 0.5;
   `}
`
