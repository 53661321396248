import { api, ResourcePage, responseErrorCheck } from 'stylewhere/api'

export class TmrBaseResource {
  /**
   * resource endpoint eg. /products
   */
  static endpoint

  /**
   * Pagination config (global for all resources)
   */
  static defaultPaginationConfig = {
    offset: 0,
    size: 100,
    orderBy: 'CREATION_DATE',
    asc: true,
  }

  /**
   * Get one Object based on the search params (eg. id: 435)
   *
   * @param {string} resourceType entity identifier
   * @returns {Object}
   */
  static async get<T>(resourceType: string, requestConfig = undefined || {}) {
    const result = await api.get<T>(`${this.endpoint}/${resourceType}`, requestConfig)
    return responseErrorCheck(result)
  }

  /**
   * Search resources
   *
   * @param {Object} params query params
   * @returns {Array}
   */
  static search<T>(params = {}, requestConfig = undefined): Promise<ResourcePage<T>> {
    return api.get<ResourcePage<T>>(`${this.endpoint}`, params, requestConfig).then(responseErrorCheck)
  }

  /**
   * Search Resources passing params with pagination config.
   *
   * eg. const parcels = await Parcels.paginate({ received: false });
   *
   * By default the pagination config is:
   * {
   *   offset: 0,
   *   size: 50,
   *   orderBy: 'CREATION_DATE',
   *   asc: true,
   * }
   *
   * @param {Object} searchParams query params
   * @param {Object} paginationConfig pagination config { offset, size, groupBy, asc }
   * @returns {Array} for the current page
   */
  static async paginate<T = any>(
    searchParams: {},
    paginationConfig: typeof TmrBaseResource.defaultPaginationConfig | null = null,
    requestConfig = undefined
  ) {
    const paginationParams = paginationConfig || TmrBaseResource.defaultPaginationConfig

    const result = await api.get<ResourcePage<T>>(
      `${this.endpoint}`,
      { ...paginationParams, ...searchParams },
      requestConfig
    )

    return result?.data?.content ?? []
  }

  /**
   * Total Count for resource
   *
   * @param {Object} params query params
   * @returns {number} total value
   */
  static count(params, requestConfig = undefined): Promise<number> {
    return api.get<any>(`${this.endpoint}/count`, params, requestConfig).then(responseErrorCheck)
  }

  /**
   * Configure pagination for all entities
   *
   * defaultConfig = {
   *   offset: 0,
   *   size: 25,
   *   orderBy: 'CREATION_DATE',
   *   asc: true,
   * }
   *
   * @param {Object} cfg
   */
  static configureDefaultPagination(cfg) {
    if (cfg.offset !== undefined) TmrBaseResource.defaultPaginationConfig.offset = cfg.offset
    if (cfg.size !== undefined) TmrBaseResource.defaultPaginationConfig.size = cfg.size
    if (cfg.orderBy !== undefined) TmrBaseResource.defaultPaginationConfig.orderBy = cfg.orderBy
    if (cfg.asc !== undefined) TmrBaseResource.defaultPaginationConfig.asc = cfg.asc
  }

  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  static save(data, requestConfig = undefined): Promise<any> {
    return api.post(`${this.endpoint}`, data, requestConfig).then(responseErrorCheck)
  }

  /**
   * Delete a specific entity
   *
   * @param {String} id
   */
  static delete(id, requestConfig = undefined) {
    return api.delete(`${this.endpoint}/${id}`, requestConfig).then(responseErrorCheck)
  }

  /**
   * Creates many entities
   *
   * @param {Array} data array of entities
   * @returns {Array}
   */
  static bulkCreate(data, requestConfig = undefined) {
    return api.post(`${this.endpoint}/bulkCreate`, data, requestConfig).then(responseErrorCheck)
  }

  /**
   * Updates many entities
   *
   * @param {Array} data array of entities to update
   * @returns {Array}
   */
  static bulkUpdate(data, requestConfig = undefined) {
    return api.post(`${this.endpoint}/bulkUpdate`, data, requestConfig).then(responseErrorCheck)
  }

  static getURL<T>(param, requestConfig = undefined): Promise<T> {
    return api.get<T>(`${this.endpoint}?${param}`, requestConfig).then(responseErrorCheck)
  }
}
