import React from 'react'
import ReactDOM from 'react-dom'
import { App, Box } from 'stylewhere/components'
import { Spinner } from '@chakra-ui/react'
import * as serviceWorker from './serviceWorker'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'

/*
ReactDOM.render(
  <React.StrictMode>
    <ClearBrowserCacheBoundary
      debug={(params) => {
        //console.log(params)
      }}
      auto={false}
      fallback={
        <Box flex center vcenter>
          <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
        </Box>
      }
      //duration={60000}
    >
      <App />
    </ClearBrowserCacheBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
