import { Component } from 'react'
import { Box, Text, Stoplight } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props {
  counter: number
  sseStatus: boolean
}

export class ImbustatriceRightHeader extends Component<Props> {
  render() {
    const { counter, sseStatus } = this.props
    return (
      <Box row>
        <Box vcenter mr={15}>
          <Text fontSize={20}>{__(T.misc.associations)}</Text>
          <Text style={{ textAlign: 'left' }} bold fontSize={20}>
            {counter}
          </Text>
        </Box>
        <Stoplight active={sseStatus} />
      </Box>
    )
  }
}
