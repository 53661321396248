import { api, Product, responseErrorCheck, TmrBaseResource, TmrProduct } from 'stylewhere/api'
import { config } from 'stylewhere/config'
import { AppStore } from 'stylewhere/shared'
import Images from './Images'

export class Products extends TmrBaseResource {
  static endpoint = 'api/v1/products' //tracking

  static searchCodes(codes: string[]) {
    return this.search<TmrProduct>({ equalCodes: codes })
  }

  static async getProductsByCodes(upcs: string[]): Promise<Product[]> {
    return api
      .post<Product | any>(`${this.endpoint}/advancedSearch`, {
        codes: upcs,
        limit: 50000,
      })
      .then(responseErrorCheck)
  }

  static getImageID = (record: any) => {
    let imageId = ''
    if (record) {
      if (record.attributes) {
        if (record.attributes.pictureId) {
          imageId = record.attributes.pictureId.value || ''
        } else if (record.attributes.sketchId) {
          imageId = record.attributes.sketchId.value || ''
        }
      } else if (record._ImageId) {
        imageId = record._ImageId
      } else if (record._SkatchId) {
        imageId = record._SkatchId
      }
      if (imageId === '') {
        if (record.code) {
          imageId = `IMG-${record.code}`
        } else if (record.productCode) {
          imageId = `IMG-${record.productCode}`
        }
      }
    }
    return imageId
  }

  static getImageUrl(product?: any, type = 'medium') {
    if (!product || !AppStore.authToken) {
      return ''
    }
    const settings = AppStore.getShowProductImageSettings()
    let url = ''
    if (settings === 'StylewherePrivateUrl' || settings.toLowerCase() === 'yes') {
      const imageId = this.getImageID(product)
      if (imageId && imageId !== '') {
        const baseURL =
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? config.endpoint : config.endpoint
        url = `${baseURL}${Images.endpoint}?imageId=${imageId}&access_token=${AppStore.authToken}`
      }
    } else {
      let publicUrl = ''
      if (product.attributes && product.attributes.imageId && product.attributes.imageId.value) {
        publicUrl = product.attributes.imageId.value
      } else if (product._ImageId) {
        publicUrl = product._ImageId.value || product._ImageId
      }
      if (publicUrl !== '') {
        try {
          const decode = JSON.parse(publicUrl)
          if (decode && decode.imageUrls && decode.imageUrls[type]) {
            url = decode.imageUrls[type]
          }
        } catch (e) {
          //console.log(e)
        }
      }
    }
    return url
  }
}
