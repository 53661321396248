import styled from '@emotion/styled'
import { Asn } from 'stylewhere/api'
import { Box, Button, ButtonVariant, Icons, KeyValueRow, PageFormDetail } from 'stylewhere/components'
import { FormSchema, FormSchemaData } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/i18n'

export type HeaderDetail = {
  asn?: Asn
  data: FormSchemaData
  formSchema: FormSchema
  setFormData?: (data: FormSchemaData) => void
  resetFormData?: (data: FormSchemaData) => void
  operationId?: string
}
export type HeaderAction = { text: string; onPress: () => void; variant?: ButtonVariant }

type TempHeaderProps = {
  title: string
  details?: HeaderDetail
  actions?: HeaderAction[]
  children?: any
  onBackPress: (() => void) | false
}

export function Header({ title, details, actions, onBackPress, children }: TempHeaderProps) {
  return (
    <Wrapper>
      <Container>
        <Box flex row vcenter>
          {onBackPress && (
            <BackButtonContainer onClick={onBackPress}>
              <Icons.LeftArrow />
            </BackButtonContainer>
          )}
          <TitleStyle>{title}</TitleStyle>
        </Box>
        <RightSide vcenter row>
          {details && details.asn?.code && (
            <KeyValueRow
              variant="raw"
              label={__(T.misc.shipment)}
              value={details.asn?.code}
              style={{ paddingLeft: 0, paddingRight: 15 }}
            />
          )}

          {details && (
            <PageFormDetail
              changeButtonStyle={{ marginLeft: 10 }}
              detailStyle={{ maxWidth: 300 }}
              initData={details.data}
              formSchema={details.formSchema}
              setFormData={details.setFormData}
              resetFormData={details.resetFormData}
              operationId={details.operationId}
            />
          )}

          {actions?.map((action) => (
            <Button
              key={action.text}
              onClick={action.onPress}
              variant={action.variant}
              style={{ marginLeft: 10 }}
              size="medium"
            >
              {action.text}
            </Button>
          ))}

          {children}
        </RightSide>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.headerHeight};
`

const Container = styled.div`
  flex: 1;
  height: ${({ theme }) => theme.headerHeight};
  background-color: #eaeaea;
  padding: 0 ${({ theme }) => theme.padding};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
`

const BackButtonContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  box-shadow: -1px 1px 6px #00000024;
`

const TitleStyle = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  padding-left: 20px;
  line-height: 32px;
`

const RightSide = styled(Box)`
  justify-content: right;
  height: -webkit-fill-available;
`
