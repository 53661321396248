import React, { Component } from 'react'
import { Spinner } from '@chakra-ui/react'
import {
  Page,
  Box,
  Button,
  EncodingInboundSapViewHeader,
  EncodingInboundSapViewItemList,
  EncodingInboundSapViewSessionList,
  FullLoadingLayer,
} from 'stylewhere/components'
import { Router, RemoteOperation, OperationReadingProps, OperationReadingState } from 'stylewhere/shared'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { SimpleItemSessionListExtensions } from 'stylewhere/extensions'
import { T, __, __UP } from 'stylewhere/i18n'
import { SimpleItemSessionList, SimpleItemSessions } from 'stylewhere/api'
import { showToast, showToastError, getProductCode, enabledWamGroupedProduct } from 'stylewhere/shared/utils'

interface State extends OperationReadingState {
  listId: string
  viewType: number
  itemList: any[]
  loading: boolean
  umgId: string
  processing: boolean
  detected: number
}

export default class EncodingInboundSapView extends Component<OperationReadingProps, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<OperationReadingState>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleItemSessionListExtensions.formSchema(this.operation)

  state = {
    listId: '',
    formData: this.locationState.formData ?? {},
    viewType: 1,
    itemList: [],
    loading: true,
    umgId: '',
    processing: false,
    detected: 0,
  } as any

  async componentDidMount() {
    if (!this.locationState.formData || !this.locationState.formData.sessionListId) {
      this.goBack()
      return
    }
    this.setState(
      {
        loading: true,
        listId: this.locationState.formData.sessionListId,
        detected: this.locationState.detected || 0,
      },
      this.loadItemList
    )
  }

  findProduct = (products, code) => {
    if (enabledWamGroupedProduct()) {
      return products.find((p) => p.product.attributes?.wam === code)
    }
    return products.find((prod) => prod.code === code)
  }

  getItems = async () => {
    const { listId, umgId } = this.state
    const umgIds = umgId !== '' ? [umgId] : []
    const params = { size: 10000, sessions: umgIds }
    const items: any = await SimpleItemSessionList.getItemsList(listId, params)
    const products: any[] = []
    if (items && items.content && items.content.length > 0) {
      let code
      let findProduct
      for (let i = 0; i < items.content.length; i++) {
        if (items.content[i].product) {
          code = getProductCode(items.content[i])
          if (enabledWamGroupedProduct()) {
            if (!items.content[i].product.attributes) {
              items.content[i].product.attributes = {}
            }
            items.content[i].product.attributes.wam = code
          }
          findProduct = this.findProduct(products, code)
          if (findProduct) {
            findProduct.quantity++
          } else {
            products.push({
              ...items.content[i],
              quantity: 1,
            })
          }
        }
      }
    }
    return products
  }

  getSessionList = async () => {
    const { listId } = this.state
    const sessions = await SimpleItemSessions.search({
      listIds: [listId],
      statuses: ['OPEN', 'CONFIRMED'],
      size: 10000,
    })
    return sessions && sessions.content ? sessions.content : []
  }

  _changeViewType = (type) => {
    if (this.state.viewType === type) {
      return
    }
    this.setState({ viewType: type, umgId: '', loading: true }, this.loadItemList)
  }

  _resetUmg = () => {
    this.setState({ umgId: '', loading: true }, this.loadItemList)
  }

  loadItemList = async () => {
    const { viewType, umgId } = this.state
    if (viewType === 1) {
      this.getItemSession()
    } else {
      if (umgId !== '') {
        this.getItemSession()
      } else {
        const sessions = await this.getSessionList()
        this.setState({
          itemList: sessions,
          loading: false,
        })
      }
    }
  }

  sessionDetail = (id) => {
    this.setState({ loading: true, umgId: id }, this.getItemSession)
  }

  getItemSession = async () => {
    const items = await this.getItems()
    this.setState({
      itemList: items,
      loading: false,
    })
  }

  goBack = () => {
    const { formData, detected } = this.state
    Router.navigate(
      '/simple-item-session-list/encodingInboundSap/:opCode/encode',
      { opCode: this.operation.code },
      { state: { formData: formData, detected: detected } }
    )
  }

  restart = () => {
    const { formData } = this.state
    Router.navigate(
      '/simple-item-session-list/encodingInboundSap/:opCode',
      { opCode: this.operation.code },
      { state: { formData: formData } }
    )
  }

  getHeaderRight = () => {
    return (
      <Button variant="default" onClick={() => this._confirmShipment()}>
        {__(T.misc.confirm_mission)}
      </Button>
    )
  }

  _confirmShipment = () => {
    this.setState({ processing: true }, this._doConfirmShipment)
  }

  _doConfirmShipment = async () => {
    const { listId } = this.state
    try {
      const result = await SimpleItemSessionList.confirm_list(this.operation.id, listId)
      if (result && result.id) {
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.item_session_list_confirm),
          status: 'success',
        })
        this.setState({ processing: false })
        this.restart()
      } else {
        showToastError(__(T.error.item_session_list_confirm), __(T.error.error), true)
        this.setState({ processing: false })
      }
    } catch (error) {
      showToastError(error, __(T.error.error), true)
      this.setState({ processing: false })
    }
  }

  render() {
    const { formData, loading, viewType, itemList, umgId, processing, listId } = this.state
    const viewItem = viewType === 1 || umgId != ''
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        forceDetails="top"
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        headerRight={this.getHeaderRight()}
        enableEmulation
      >
        <Box flex bg={'#FFF'}>
          <EncodingInboundSapViewHeader
            onChangeViewType={this._changeViewType}
            onNewParcel={() => this.goBack()}
            viewType={viewType}
            umgId={umgId}
            resetUmg={() => this._resetUmg()}
          />
          <Page.Content notBoxed style={{ margin: 0 }}>
            {loading && (
              <Box flex center>
                <Spinner thickness="5px" speed="0.65s" color="#454545" size="xl" />
              </Box>
            )}
            {!loading && viewItem && <EncodingInboundSapViewItemList viewHeader itemList={itemList} />}
            {!loading && !viewItem && <EncodingInboundSapViewSessionList sessionList={itemList} listId={listId} />}
          </Page.Content>
        </Box>
        {processing && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}
      </Page>
    )
  }
}
