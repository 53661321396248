import { Component } from 'react'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/i18n'
import { Box, Input, Button, Icons, ToggleTabLabel } from 'stylewhere/components'

interface Props {
  onChangeViewType: (view) => void
  onNewParcel: () => void
  viewType: number
  umgId: string
  resetUmg: () => void
}

export class EncodingInboundSapViewHeader extends Component<Props> {
  render() {
    const { onNewParcel, onChangeViewType, viewType, umgId, resetUmg } = this.props
    return (
      <Box vcenter row mb={0} p={'15px'}>
        <Box flex row mr={15}>
          {umgId !== '' && (
            <BackButtonContainer onClick={resetUmg}>
              <Icons.LeftArrow />
            </BackButtonContainer>
          )}
          <ToggleTabLabel onChange={onChangeViewType} selected={viewType} />
        </Box>
        <Box center>
          <Button onClick={onNewParcel}>{__(T.misc.new_parcel)}</Button>
        </Box>
      </Box>
    )
  }
}

const BackButtonContainer = styled.div`
  width: 65px;
  height: 65px;
  background-color: white;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  box-shadow: -1px 1px 6px #00000024;
  margin-right: 15px;
`
