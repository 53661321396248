class Storage {
  static prefix = 'rfid-station::'

  async save(key: string, data: any): Promise<void> {
    return localStorage.setItem(Storage.prefix + key, JSON.stringify(data))
  }

  async load(key: string, defaultValue?: any): Promise<any | undefined> {
    const data = await localStorage.getItem(Storage.prefix + key)
    if (data && data !== 'undefined') {
      try {
        return JSON.parse(data)
      } catch (e) {
        localStorage.removeItem(Storage.prefix + key)
        return defaultValue
      }
    }
    return defaultValue
  }

  async remove(key: string): Promise<void> {
    return localStorage.removeItem(Storage.prefix + key)
  }

  async purgeAllData(): Promise<void> {
    return localStorage.clear()
  }
}

export const StorageKeyName = (key: string): string => {
  return Storage.prefix + key
}

export default new Storage()
