import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Button, Modal } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onClose: () => void
  style?: any
  btnCloseModal?: string
}

export class FeedbackModal extends Component<Props> {
  render() {
    const { isOpen, title, onClose, subtitle, btnCloseModal } = this.props
    const btn1 = { flex: 1, minWidth: 'auto', height: 60 }
    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="xl">
        <Box>
          <Title>{title}</Title>
          <Description>{subtitle}</Description>
        </Box>
        <Box row mt={10}>
          <Button title={btnCloseModal || __(T.misc.confirm)} onClick={onClose} style={btn1} />
        </Box>
      </Modal>
    )
  }
}

const Title = styled.span`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
`

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 21px;
  text-align: center;
  opacity: 0.5;
`
