import { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { Inventories, Inventory } from 'stylewhere/api'
import { InventoryRow, OperationList } from 'stylewhere/components'
import { InventoryExtensions } from 'stylewhere/extensions'
import { AppStore, RemoteOperation, Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { InventoryOperationConfig } from 'stylewhere/shared/RemoteOperation'

interface State {
  inventories?: Inventory[]
}

export default class InventoryList extends Component<RouteComponentProps<{ opCode: string }>, State> {
  state: State = {
    inventories: undefined,
  }
  opCode = Router.getMatchParams(this.props).opCode
  operation = RemoteOperation.getOperationConfig<InventoryOperationConfig>(this.opCode)

  onRowClick = (inventory: Inventory) =>
    Router.navigate('/inventory/:opCode/start/:id', { opCode: this.opCode, id: inventory.id })

  updateInventories = async (search?: string) => {
    let inventories: Inventory[] = []
    try {
      inventories = (
        await Inventories.search<Inventory>({
          operationId: this.operation.id,
          search,
          placeId: AppStore.defaultWorkstation?.placeId ?? '',
          statuses: ['OPEN', 'POPULATING'],
        })
      ).content
    } catch (error) {}

    this.setState({ inventories })
  }

  async componentDidMount() {
    await this.updateInventories()
  }

  inventoryRender = (inventory: Inventory) => {
    return <InventoryRow inventory={inventory} onClick={() => this.onRowClick(inventory)} />
  }

  render() {
    const { inventories } = this.state
    return (
      <OperationList
        // onChangeSearch={this.updateInventories}
        title={__(T.titles.inventories)}
        data={inventories}
        customRowRender={this.inventoryRender}
        operation={this.operation}
        extensions={InventoryExtensions}
      />
    )
  }
}
