import styled from '@emotion/styled'
import { Image, Box, Icons, RowLine } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { TmrItem } from 'stylewhere/api'

export function ImageInfoCard({
  title,
  subtitle,
  image_url,
  product,
  item,
  onClose,
}: {
  title?: string
  subtitle?: string
  image_url: string
  product?: any
  item?: TmrItem
  onClose?: (e: any) => void
}) {
  //default values
  const fields = {
    [__(T.misc.description)]: product?.description,
    [__(T.misc.color)]: product?.color?.value,
    [__(T.misc.size)]: product?.size?.value,
  }

  const identifiers = {}
  if (item && item.identifiers.length) {
    item.identifiers.forEach((identifier) => (identifiers[identifier.type] = identifier.code))
  }

  return (
    <CardContainer>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Box mt={20} row>
        <ImageContainer>
          <Image style={{ objectFit: 'contain', width: '100%' }} src={image_url} alt="item" />
        </ImageContainer>
        <InfoContainer>
          {fields &&
            Object.keys(fields).map((key) => (
              <KeyValue key={`imageinfo-${key}`}>
                <Box
                  row
                  style={{
                    justifyContent: 'space-between',
                    marginBottom: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  <KeyText>{key}</KeyText>
                  <ValueText>{!!fields[key] && fields[key] !== '' ? fields[key] : '---'}</ValueText>
                </Box>
                <RowLine />
              </KeyValue>
            ))}
        </InfoContainer>
      </Box>
      {identifiers && (
        <InfoContainer>
          {Object.keys(identifiers).map((key) => (
            <KeyValue key={`imageinfo-${key}`}>
              <Box
                row
                style={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <KeyText>{key}</KeyText>
                <ValueText>{!!identifiers[key] && identifiers[key] !== '' ? identifiers[key] : '---'}</ValueText>
              </Box>
              <RowLine />
            </KeyValue>
          ))}
        </InfoContainer>
      )}

      {onClose && <Icons.Close style={{ position: 'absolute', right: 15, cursor: 'pointer' }} onClick={onClose} />}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  padding: 15px;
  padding-bottom: 30px;
  flex-direction: column;
  position: relative;
`
const KeyValue = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
  margin-bottom: 14px;
`
const KeyText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
`
const ValueText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  opacity: 0.4;
`

const SubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
`

const InfoContainer = styled.div`
  flex: 2;
`

const ImageContainer = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  padding: 2px;
  margin-right: 10px;
  flex: 1;
`
